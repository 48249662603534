import { isFunction } from 'lodash';

import useAuthorizedRequest from '../http/useAuthorizedRequest';

export interface IUploadedFile {
  error: boolean;
  url: string;
}

interface IFileUploadProps {
  onError?: (error: Error) => void;
  onSuccess?: (fileURL: string) => void;
}

interface IFileUpload {
  isLoading: boolean;
  upload: (files: File[]) => Promise<IUploadedFile[]>;
}

const useFileUpload = (props?: IFileUploadProps): IFileUpload => {
  const {
    onError,
    onSuccess,
  } = props || {};

  const {
    performRequest,
    isPerforming,
  } = useAuthorizedRequest({
    endpoint: '/user/file',
    method: 'POST',
    onError,
  });

  const upload = async (files: File[]): Promise<IUploadedFile[]> => {
    const httpResponse = await performRequest({
      files,
    });

    const data = httpResponse.result;

    if (isFunction(onSuccess)) {
      onSuccess(data);
    }

    return data;
  };

  return {
    isLoading: isPerforming,
    upload,
  };
};

export default useFileUpload;
