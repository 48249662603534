import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, InputNumber, Tooltip } from 'antd';
import { valueType } from 'antd/lib/statistic/utils';
import cx from 'classnames';
import numeral from 'numeral';

import {
  CheckCircleOutlined, DollarOutlined, PercentageOutlined, QuestionCircleOutlined,
} from '@ant-design/icons';

import getMarkupedValue from '../../common/markup';
import styles from './markupCell.styles.module.scss';

const { useEffect, useMemo, useState } = React;

interface IMarkupCell {
  initialValue: number;
  markupSchema: string;
  implementedValue: number;
  onSave: (markupValue: number) => void;
}

const MarkupCell: React.FC<IMarkupCell> = (props) => {
  const {
    initialValue,
    markupSchema,
    implementedValue,
    onSave,
  } = props;

  const { t } = useTranslation();

  const [value, setValue] = useState(0);

  const isPercentage = markupSchema === 'RELATIVE';

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const addonBefore = (
    isPercentage
      ? <PercentageOutlined />
      : <DollarOutlined />
  );

  const hasChanged = initialValue !== value;

  const renderActions = () => (
    <div className={styles.markupCellAfter}>
      <Tooltip title={t(`markup.help.${markupSchema}`)}>
        <div className={styles.markupCellHelp}>
          <QuestionCircleOutlined />
        </div>
      </Tooltip>
      <div className={styles.saveButton}>
        <Button
          className={styles.saveButtonBtn}
          type="primary"
          disabled={!hasChanged}
          onClick={() => onSave(value)}
          icon={<CheckCircleOutlined />}
          size="small"
          shape="circle"
        />
      </div>
    </div>
  );

  const markupedValue = useMemo(() => (
    getMarkupedValue(implementedValue, value, markupSchema)
  ), [value, implementedValue, markupSchema]);

  const isNegative = (() => {
    const testValue = numeral(implementedValue).value() as number;
    const testMarkedUp = numeral(markupedValue).value() as number;

    if (isPercentage) {
      return testMarkedUp < testValue;
    }
    return testMarkedUp < 0;
  })();

  return (
    <div className={styles.markupCell}>
      <div className={styles.markupCellRow}>
        <div className={styles.markupCellBefore}>
          {addonBefore}
        </div>
        <InputNumber
          className={styles.markupCellInput}
          min={-Infinity}
          max={Infinity}
          defaultValue={initialValue}
          onChange={(changedV) => setValue(changedV as number)}
          value={value as valueType}
        />
        {renderActions()}
      </div>
      <div className={styles.markupCellRow}>
        {
          isPercentage ? (
            <div className={styles.markupValue}>
              <span>{t('markup.markupedValue')}</span>
              <strong
                className={cx({
                  [styles.isNegative]: isNegative,
                })}
              >
                {markupedValue}
              </strong>
            </div>
          ) : (
            <div className={styles.markupValue}>
              <span>{t('markup.markupedPercentage')}</span>
              <strong
                className={cx({
                  [styles.isNegative]: isNegative,
                })}
              >
                {markupedValue}
              </strong>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default MarkupCell;
