import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button, message, Modal, Switch,
} from 'antd';
import cx from 'classnames';
import {
  filter, includes, isEmpty, map, size, uniq,
} from 'lodash';

import useAllReportColumns, { IColumnField } from '../../common/hooks/useReportColumns';
import { authorizedHttp } from '../../common/http';
import styles from './styles.module.scss';

const { useMemo, useState, useEffect } = React;

interface IClientColumnsModalProps {
  client: any;
  onClose: () => void;
  onSave?: () => void;
}

const ClientColumnsModalComponent: React.FC<IClientColumnsModalProps> = (props) => {
  const {
    client,
    onClose,
    onSave,
  } = props;

  const { t } = useTranslation();

  const [isSaving, setIsSaving] = useState(false);
  const [newColumnsKeys, setNewColumnsKeys] = useState<string[]>([]);

  const allColumns = useAllReportColumns();
  const allColumnsKeys = useMemo(() => map(allColumns, 'field'), [allColumns]);

  useEffect(() => {
    const clientColumns = (() => {
      let cols = allColumnsKeys;
      if (client?.columns) {
        try {
          cols = JSON.parse(client.columns);
        } catch {
          cols = allColumnsKeys;
        }
      }

      return cols;
    })();
    setNewColumnsKeys(clientColumns);
    setIsSaving(false);
  }, [client, allColumnsKeys]);

  const handleColumnVisibilityToggle = (fieldKey: string, isChecked: boolean) => {
    let newState = [...newColumnsKeys];

    if (isChecked) {
      newState.push(fieldKey);
    } else {
      newState = filter(newState, (fk) => fk !== fieldKey);
    }

    newState = uniq(newState);

    setNewColumnsKeys(newState);
  };

  const renderColumnRow = (column: IColumnField) => {
    const isVisible = includes(newColumnsKeys, column.field);

    return (
      <div className={styles.row}>
        <div className={styles.rowLabel}>
          {column.label}
        </div>
        <div className={styles.rowToggle}>
          <Switch
            checked={isVisible}
            onChange={(checked) => handleColumnVisibilityToggle(column.field, checked)}
          />
        </div>
      </div>
    );
  };

  const content = useMemo(() => (
    <div className={styles.wrapper}>
      <div className={styles.headerActions}>
        <Button
          danger
          size="small"
          className={styles.headerBtn}
          disabled={isEmpty(newColumnsKeys)}
          onClick={() => setNewColumnsKeys([])}
        >
          {t('report.disableAllColumns')}
        </Button>
        <Button
          size="small"
          className={styles.headerBtn}
          disabled={size(newColumnsKeys) === size(allColumnsKeys)}
          onClick={() => setNewColumnsKeys(allColumnsKeys)}
        >
          {t('report.enableAllColumns')}
        </Button>
      </div>
      <div className={styles.content}>
        {map(allColumns, renderColumnRow)}
      </div>
    </div>
  ), [newColumnsKeys, allColumns]);

  const handleSave = async () => {
    setIsSaving(true);

    try {
      await authorizedHttp.patch(`/client/${client.id}`, {
        columns: JSON.stringify(newColumnsKeys),
      });

      message.success(t('feedback.clientColumnsSuccess'));
      onSave?.();
    } catch {
      message.error(t('feedback.clientColumnsSuccess'));
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      visible={!!client}
      centered
      maskClosable
      closable
      onCancel={onClose}
      destroyOnClose
      onOk={handleSave}
      okButtonProps={{
        loading: isSaving,
      }}
      title={t('modules.clients.editColumns')}
      className={cx(
        styles.modal,
      )}
    >
      {content}
    </Modal>
  );
};

const ClientColumnsModal = React.memo(ClientColumnsModalComponent);

export default ClientColumnsModal;
