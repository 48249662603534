import * as React from 'react';

import { Auth0Provider } from '@auth0/auth0-react';

import AuthProvider from './Auth';

interface IAuthProvider {
  children: React.ReactNode;
}

const Auth2WProvider = (props: IAuthProvider) => {
  const {
    children,
  } = props;

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
      authorizeTimeoutInSeconds={15}
      useRefreshToken
    >
      <AuthProvider>
        { children }
      </AuthProvider>
    </Auth0Provider>
  );
};

export default Auth2WProvider;
