import * as React from 'react';

import PlatformWrapper from '../../components/PlatformWrapper';
import { useAuth } from '../Global/Auth';
import AntdCustomProvider from './Antd';
import FBProvider from './FB';
import GoogleProvider from './Google';
import SocketProvider from './Socket';

interface IPlatformProvider {
  children: React.ReactElement;
}

const PlatformProviderComponent: React.FC<IPlatformProvider> = (props) => {
  const { children } = props;

  const { isAuthorized } = useAuth();

  const shouldWrap = isAuthorized;

  if (!shouldWrap) {
    return children;
  }

  return (
    <SocketProvider>
      <AntdCustomProvider>
        <FBProvider>
          <GoogleProvider>
            <PlatformWrapper>
              {children}
            </PlatformWrapper>
          </GoogleProvider>
        </FBProvider>
      </AntdCustomProvider>
    </SocketProvider>
  );
};

const PlatformProvider = PlatformProviderComponent;

export default PlatformProvider;
