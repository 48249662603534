import * as React from 'react';

import lottieAssets from '../../../assets/lottie';
import LottieCommon from '../Lottie/Common';
import styles from './styles.module.scss';

const DEFAULT_SPINNER_SIZE = 20;

interface ISpinner {
  size?: number;
  speed?: number;
}

const Spinner: React.FC<ISpinner> = (props) => {
  const {
    size = DEFAULT_SPINNER_SIZE,
    speed = 1,
  } = props;

  return (
    <div className={styles.spinnerComponent}>
      <LottieCommon
        width={size}
        height={size}
        speed={speed}
        animationData={lottieAssets.spinner}
      />
    </div>
  );
};

export default Spinner;
