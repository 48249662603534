import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button, Form, FormInstance, Input, InputNumber, Radio,
} from 'antd';
import { isFunction, map, some } from 'lodash';

import { IField } from '../../common/fields';
import AccountPicker from './AccountPicker/accountPickerComponent';
import ChooseAdAccount from './AdAccountsPicker/Choose';
import ImagePicker from './imagePickerComponent';
import NewPassword from './newPasswordComponent';
import OrganizationPicker from './OrganizationPicker/organizationPickerComponent';
import styles from './styles.module.scss';
import UserPicker from './userPickerComponent';

const {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} = React;

interface IParsedForm {
  fields: IField[],
  initialValues?: Record<string, unknown>,
  submitLabel?: string,
  shouldUpdate?: boolean,
  onlyControl?: boolean,
  onSubmit?: (values: Record<string, unknown>) => Promise<unknown> | unknown;
  onValuesChange?: (values: Record<string, unknown>) => void;
}

interface IParsedFormRef {
  submit: () => void;
}

// eslint-disable-next-line max-len
const ParsedFormComponent = forwardRef<IParsedFormRef, IParsedForm>((props, ref) => {
  const {
    fields,
    initialValues,
    submitLabel = '',
    shouldUpdate = true,
    onSubmit,
    onValuesChange,
    onlyControl,
  } = props;

  const formRef = useRef<FormInstance>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const fieldKeys = map(fields, 'name');

  const getFormDisabled = () => {
    const isTouched = form.isFieldsTouched();
    const isValidating = form.isFieldsValidating(fieldKeys);

    if (!isTouched || isValidating) {
      return true;
    }

    const hasErrors = some(form.getFieldsError(fieldKeys), ({ errors }) => errors.length);

    return hasErrors;
  };

  const handleFormChange = () => {
    const isDisabled = getFormDisabled();

    setIsFormDisabled(isDisabled);

    if (isFunction(onValuesChange)) {
      onValuesChange(form.getFieldsValue());
    }
  };

  useEffect(() => {
    const isDisabled = getFormDisabled();
    setIsFormDisabled(isDisabled);
  }, []);

  const renderRules = (field: IField): Record<string, unknown>[] => {
    const rules = [];

    if (field.required) {
      rules.push({
        required: true,
      });
    }

    if (field.type === 'email') {
      rules.push({
        type: 'email',
      });
    }

    if (field.validateFunction && isFunction(field.validateFunction)) {
      rules.push({
        validateTrigger: 'onBlur',
        validator: async (_: any, value: string) => {
          const isValid = await field.validateFunction?.(value);

          if (isValid) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(t(field.validateFunctionMessage || '')));
        },
      });
    }

    return rules;
  };

  const formatFieldValue = (value: string, field?: IField) => {
    if (isFunction(field?.formatter)) {
      return field?.formatter(value);
    }
    return value;
  };

  const renderDefaultFieldInput = () => (
    <Input disabled={isSubmitting} />
  );

  const renderNumberFieldInput = (field: IField) => (
    <InputNumber
      disabled={isSubmitting}
      min={field.min}
      max={field.max}
      precision={0}
      style={{ width: '92%' }}
    />
  );

  const renderComboFieldInput = (field: IField) => {
    const { comboOptions } = field;

    return (
      <Radio.Group buttonStyle="solid" disabled={isSubmitting}>
        {
          map(comboOptions, (opt: string) => (
            <Radio.Button key={opt} value={opt}>
              {formatFieldValue(opt, field)}
            </Radio.Button>
          ))
        }
      </Radio.Group>
    );
  };

  const renderFieldInput = (field: IField) => {
    const { type } = field;

    switch (type) {
      case 'combo':
        return renderComboFieldInput(field);
      case 'number':
        return renderNumberFieldInput(field);
      case 'user-picker':
        return (
          <UserPicker
            multiple={field.multiple}
            hideSelfAssign={field.hideSelfAssign}
            excludeIds={field.excludeIds || []}
          />
        );
      case 'new-password':
        return <NewPassword />;
      case 'account-picker':
        return <AccountPicker />;
      case 'organization-picker':
        return <OrganizationPicker />;
      case 'ad-account-picker':
        return <ChooseAdAccount />;
      case 'image-picker':
        return (
          <ImagePicker
            className={field.className}
            cropperProps={field.imagePickerProps?.cropper}
            aspect={field.imagePickerProps?.aspect}
          />
        );
      default:
        return renderDefaultFieldInput();
    }
  };

  const onFinish = async (values: Record<string, unknown>) => {
    if (isSubmitting && !onlyControl) {
      return;
    }

    setIsSubmitting(true);
    await onSubmit?.(values);
    setIsSubmitting(false);
  };

  useImperativeHandle(ref, () => ({
    submit: form.submit,
  }));

  return (
    <div>
      <Form
        ref={formRef}
        form={form}
        layout="vertical"
        onFieldsChange={handleFormChange}
        onChange={handleFormChange}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        {
          map(fields, (field) => (
            <Form.Item
              label={t(field.label)}
              required={!!field.required}
              name={field.name}
              key={field.name}
              validateFirst
              hasFeedback
              className={styles.formItem}
              shouldUpdate={shouldUpdate}
              rules={renderRules(field)}
            >
              {renderFieldInput(field)}
            </Form.Item>
          ))
        }
        {
          !onlyControl && (
            <Form.Item className={styles.formItemSubmit}>
              <Button
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
                disabled={isFormDisabled || isSubmitting}
              >
                {t(submitLabel)}
              </Button>
            </Form.Item>
          )
        }
      </Form>
    </div>
  );
});

const ParsedForm = React.memo(ParsedFormComponent);

export default ParsedForm;
