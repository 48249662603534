import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { notification } from 'antd';
import {
  includes, map, pickBy, toUpper,
} from 'lodash';

import { IField } from '../../common/fields';
import { authorizedHttp } from '../../common/http';
import { validateEmail } from '../../common/validators';
import { DEFAULT_LOCALE, SUPPORTED_LOCALES_KEYS } from '../../providers/Platform/Antd';
import ParsedForm from '../ParsedForm';

const { useEffect, useMemo, useState } = React;

interface IProfileDetails {
  entity: Record<string, unknown>,
  isMe: boolean;
  onUpdate?: () => void;
}

const ProfileDetailsComponent: React.FC<IProfileDetails> = (props) => {
  const {
    entity: entityProp,
    isMe,
  } = props;

  const [entity, setEntity] = useState(entityProp);

  useEffect(() => {
    setEntity(entityProp);
  }, [entityProp]);

  const { t } = useTranslation();

  const EditableFields: IField[] = [
    {
      name: 'email',
      label: 'fields.email',
      type: 'email',
      validateFunction: (email) => validateEmail(email, entity.user_id as string),
      validateFunctionMessage: 'fields.errors.emailOcuppied',
      required: true,
    },
    {
      name: 'name',
      label: 'fields.name',
      type: 'text',
      required: true,
    },
    {
      name: 'locale',
      label: 'fields.language',
      type: 'combo',
      default: DEFAULT_LOCALE,
      comboOptions: SUPPORTED_LOCALES_KEYS,
      formatter: toUpper,
    },
  ];

  const inputKeys = useMemo(() => map(EditableFields, 'name'), []);

  const initialValues = useMemo(
    () => pickBy(entity, (_value, key) => includes(inputKeys, key)), [entity],
  );

  const handleSubmit = async (values: Record<string, unknown>) => {
    try {
      const req = await authorizedHttp.patch(`user/${entity.user_id}`, values);

      setEntity(req.data?.payload?.user);

      const notificationLang = isMe ? req.data?.payload?.user.locale : '';

      notification.success({
        message: t('modules.user.alerts.updated.success.title', {
          lng: notificationLang,
        }),
      });
    } catch (err) {
      notification.error({
        message: t('modules.user.alerts.updated.error.title'),
        description: t('modules.user.alerts.updated.error.description'),
      });
    }
  };

  return (
    <div>
      <ParsedForm
        onSubmit={handleSubmit}
        fields={EditableFields}
        initialValues={initialValues}
        submitLabel="fields.submit.updateUser"
      />
    </div>
  );
};

const ProfileDetails = React.memo(ProfileDetailsComponent);

export default ProfileDetails;
