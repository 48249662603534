import { reduce } from 'lodash';

export const KPIS_ACTIONS_FIELDS_MAP = {
  post_engagement: {
    type: 'numeric',
    label: 'Post Engagements',
  },
  link_click: {
    type: 'numeric',
    label: 'Link Clicks',
  },
  comment: {
    type: 'numeric',
    label: 'Post Comments',
  },
  conversions: {
    type: 'numeric',
    label: 'Conversions',
  },
  cpa: {
    type: 'currency',
    label: 'CPA',
  },
  cpl: {
    type: 'currency',
    label: 'CPL',
  },
  landing_page_view: {
    type: 'numeric',
    label: 'Landing Page Views',
  },
  lead: {
    type: 'numeric',
    label: 'Leads',
  },
  like: {
    type: 'numeric',
    label: 'Page Likes',
  },
  post: {
    type: 'numeric',
    label: 'Post Shares',
  },
  post_reaction: {
    type: 'numeric',
    label: 'Post Reactions',
  },
  omni_app_install: {
    type: 'numeric',
    label: 'App Installs',
  },
  omni_purchase: {
    type: 'numeric',
    label: 'Purchases',
  },
  omni_add_to_cart: {
    type: 'numeric',
    label: 'Add to cart',
  },
  omni_complete_registration: {
    type: 'numeric',
    label: 'Complete Registration',
  },
  'offsite_conversion.fb_pixel_complete_registration': {
    type: 'numeric',
    label: 'FB Pixel Complete Registration',
  },
  'offsite_conversion.fb_pixel_custom': {
    type: 'numeric',
    label: 'FB Pixel Custom Conversion',
  },
  'offsite_conversion.fb_pixel_lead': {
    type: 'numeric',
    label: 'FB Pixel Leads',
  },
  'offsite_conversion.fb_pixel_purchase': {
    type: 'numeric',
    label: 'FB Pixel Purchases',
  },
  'offsite_conversion.fb_pixel_view_content': {
    type: 'numeric',
    label: 'FB Views Content',
  },
  'onsite_conversion.messaging_conversation_started_7d': {
    type: 'numeric',
    label: 'Messages',
  },
  rsvp: {
    type: 'numeric',
    label: 'RSVPs',
  },
  video_view: {
    type: 'numeric',
    label: 'Views',
  },
};

const kpisActionsMap = () => {
  const output = reduce(KPIS_ACTIONS_FIELDS_MAP, (prev: any, curr: any, key) => {
    const ins = {
      field: key,
      ...curr,
    };

    prev.push(ins);

    return prev;
  }, []);

  return output;
};

export default kpisActionsMap;
