// eslint-disable-next-line @typescript-eslint/no-var-requires
import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';

import './i18n';

import App from './App';
import Suspense from './components/Common/Suspense';
import ErrorPage from './pages/Error';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';

const Rollbar = require('@rollbar/react');

const rollbarConfig = {
  accessToken: 'b9a3cf12c4ab47478e12ddca4d8e2b96',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.NODE_ENV,
  },
  autoInstrument: true,
  enabled: process.env.NODE_ENV === 'production',
};

ReactDOM.render(
  <Rollbar.Provider config={rollbarConfig}>
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <React.StrictMode>
        <React.Suspense fallback={<Suspense />}>
          <App />
        </React.Suspense>
      </React.StrictMode>
    </ErrorBoundary>
  </Rollbar.Provider>,
  document.getElementById('root'),
);

unregisterServiceWorker();
