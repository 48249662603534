import * as React from 'react';

import PropTypes from 'prop-types';

import { useAuth0 } from '@auth0/auth0-react';
import { User } from '@auth0/auth0-spa-js';
import * as FullStory from '@fullstory/browser';

import useAuthorizedRequest from '../../common/hooks/http/useAuthorizedRequest';
import usePublicAuthorizedRequest from '../../common/hooks/http/usePublicAuthorizedRequest';
import { DEFAULT_LOCALE } from '../Platform/Antd';

const {
  createContext, useCallback, useContext, useMemo, useState, useEffect,
} = React;

interface IAuthContext {
  token: string;
  auth0IsAuthenticated: boolean;
  isAuthorized: boolean;
  isLoading: boolean;
  isFatal: boolean;
  locale: string;
  permissions: string[];
  logout: () => void;
  forceAuthorization: () => void;
  clearStorage: () => void;
  silentlyRefetchUser: () => void;
  auth0Error: Error | undefined;
  auth0Id: string;
  user: User | undefined;
}

export const LS_KEY_TOKEN = 'jhcGQEDaBAZiCNPrNy8n';

const AuthContext = createContext({} as IAuthContext);

interface IAuthProps {
  children: React.ReactNode;
}

const AuthProvider: React.FC<IAuthProps> = (props) => {
  const {
    children,
  } = props;

  const shouldSkip = window.location.pathname.includes('reports');

  const [token, setToken] = useState('');
  const [permissions, setPermissions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFatal, setIsFatal] = useState(false);
  const [user, setUser] = useState<User>();
  const [isUserLoading, setIsUserLoading] = useState(false);

  const {
    user: {
      sub: auth0Id,
    } = {},
    logout: auth0Logout,
    loginWithRedirect,
    isAuthenticated: auth0IsAuthenticated,
    isLoading: isAuth0Loading,
    error: auth0Error,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    setIsFatal(false);
  }, []);

  useEffect(() => {
    if (user && process.env.NODE_ENV === 'production') {
      FullStory.setUserVars({
        displayName: user.name,
        email: user.email,
      });
    }
  }, [user]);

  const lsToken = useMemo(
    () => localStorage.getItem(LS_KEY_TOKEN),
    [auth0Id, isAuth0Loading, token],
  );

  const {
    performRequest: getAPIToken,
  } = usePublicAuthorizedRequest({
    endpoint: '/public/auth',
    method: 'POST',
    onError: () => setIsFatal(true),
    skip: shouldSkip,
  });

  const {
    performRequest: verifyAPIToken,
  } = usePublicAuthorizedRequest({
    endpoint: '/public/auth/verify',
    method: 'POST',
    onError: () => setIsFatal(true),
    data: {
      token: lsToken,
    },
    skip: shouldSkip,
  });

  const {
    performRequest: APILogout,
  } = useAuthorizedRequest({
    endpoint: '/user/me/logout',
    method: 'POST',
    accessToken: token,
    skip: shouldSkip,
  });

  const clearStorage = async () => {
    await APILogout();
    localStorage.removeItem(LS_KEY_TOKEN);
    setToken('');
  };

  const logout = useCallback(async () => {
    setIsLoading(true);
    await clearStorage();

    await auth0Logout({
      returnTo: `${window.location.origin}/callback/logout`,
    });

    setIsLoading(false);
  }, [token, auth0Id]);

  const {
    performRequest: APIUser,
  } = useAuthorizedRequest({
    endpoint: '/user/me',
    skip: shouldSkip,
  });

  const {
    performRequest: APIUserPermissions,
  } = useAuthorizedRequest({
    endpoint: '/user/me/permissions',
    query: {
      t: +Date.now(),
    },
    skip: shouldSkip,
  });

  const createPermissions = async (tokenArg: string) => {
    const permissionsRes = await APIUserPermissions({
      accessToken: tokenArg,
    });
    setPermissions(permissionsRes.permissions);
    setIsLoading(false);
  };

  const getInitialUser = async (tokenArg: string) => {
    setIsUserLoading(true);

    const req = await APIUser({
      disableCache: true,
      accessToken: tokenArg,
    });

    setUser(req.user);

    setIsUserLoading(false);

    createPermissions(tokenArg);
  };

  const silentlyRefetchUser = async () => {
    const req = await APIUser({
      accessToken: token,
      disableCache: true,
    });

    setUser(req.user);
  };

  const getSetToken = async () => {
    setIsLoading(true);

    if (lsToken && auth0Id) {
      try {
        const isValidTokenData = await verifyAPIToken();
        if (isValidTokenData && isValidTokenData.isValid) {
          setToken(lsToken);
          getInitialUser(lsToken);
          return;
        }
      } catch (err) {
        localStorage.removeItem(LS_KEY_TOKEN);
      }
    }

    if (auth0Id) {
      const auth0Token = await getAccessTokenSilently();
      const tokenPayload = await getAPIToken({
        data: {
          token: auth0Token,
          auth0Id,
        },
      });
      if (tokenPayload && tokenPayload.token) {
        setToken(tokenPayload.token);
        getInitialUser(tokenPayload.token);
        localStorage.setItem(LS_KEY_TOKEN, tokenPayload.token);
      }
    } else {
      setToken('');
      setIsLoading(false);
    }
  };

  const auth0Login = () => {
    if (!isAuth0Loading && !auth0Id) {
      if (window.location.pathname.includes('legal')) {
        return;
      }

      loginWithRedirect({
        redirectUri: `${window.location.origin}/callback/login?returnTo=${window.location.pathname}`,
      });
    }
  };

  const forceAuthorization = () => {
    auth0Login();
  };

  useEffect(() => {
    if (!shouldSkip) {
      if (!token) {
        if (!auth0Id && !isAuth0Loading) {
          clearStorage();
          forceAuthorization();
          return;
        }
        getSetToken();
      }
    }
  }, [auth0Id, token, isAuth0Loading, shouldSkip, window]);

  const isAllLoading = isLoading || isAuth0Loading || isUserLoading;

  return (
    <AuthContext.Provider
      value={{
        token,
        auth0Id: auth0Id || '',
        auth0Error,
        user,
        locale: user?.locale || DEFAULT_LOCALE,
        auth0IsAuthenticated,
        isLoading: isAllLoading,
        isAuthorized: !!token,
        isFatal,
        permissions,
        clearStorage,
        logout,
        forceAuthorization,
        silentlyRefetchUser,
      }}
    >
      { children }
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};

export default AuthProvider;
