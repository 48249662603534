import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { message } from 'antd';

import { User } from '@auth0/auth0-spa-js';

import { authorizedHttp } from '../../common/http';
import withPlatformLayout from '../../components/HOC/withPlatformLayout';
import ModuleWrapper from '../../components/ModuleWrapper';
import ProfileSettings from '../../components/ProfileSettings';
import { useAuth } from '../../providers/Global/Auth';
import styles from './styles.module.scss';

const { useEffect, useState } = React;

interface IUser {
  isMe?: boolean;
}

const UserPage: React.FC<IUser> = (props) => {
  const {
    isMe,
  } = props;

  const {
    user: meUser,
  } = useAuth();

  const { t } = useTranslation();

  const { userId } = useParams() as any;

  const [user, setUser] = useState<User>();

  const setInitialUser = async () => {
    if (isMe && meUser) {
      setUser(meUser);
      return;
    }

    const res = await authorizedHttp.get(`/user/${userId}`);

    setUser(res.data.payload.user);
  };

  useEffect(() => {
    setInitialUser();
  }, [isMe, meUser, userId]);

  const titleKeySuffix = isMe
    ? 'user.pageTitle.me'
    : 'user.pageTitle.general';

  const handleChangeTop = () => {
    message.success(t('feedback.userSuccess'));
  };

  return (
    <ModuleWrapper
      titleKey={titleKeySuffix}
      translateVariables={{
        userName: user?.name || '',
      }}
      goBackBreadCrumbTitle="users.pageTitle"
    >
      <div className={styles.userPage}>
        <div className={styles.userPageContainer}>
          {user && (
            <ProfileSettings user={user} isMe={isMe} onChangeTop={handleChangeTop} />
          )}
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default withPlatformLayout(UserPage);
