/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Input, PaginationProps, Table, TableProps,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import cx from 'classnames';
import {
  filter, isEmpty, isFunction, size, trim,
} from 'lodash';
import moment from 'moment';

import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';

import styles from './styles.module.scss';

const { useMemo } = React;

interface ITableProps {
  tableProps: TableProps<any>;
  data: any[];
  columns: ColumnType<any>[];
  isLoading: boolean;
  total: number;
  currentPage: number;
  pageSize: number;
  hidePagination?: boolean;
  showSearch?: boolean;
  onSearch?: (value: string) => void;
  selectedRowsTotal?: number;
  tableHeaderActions?: React.ReactElement;
  lastUpdateAt?: number;
  isSearching?: boolean;
  renderEmptyContent?: () => React.ReactElement;
  breadcrumbs?: React.ReactElement;
}

const VeloxTableComponent: React.FC<ITableProps> = (props) => {
  const {
    tableProps,
    data,
    columns,
    isLoading,
    total,
    currentPage,
    pageSize,
    showSearch,
    onSearch,
    hidePagination,
    tableHeaderActions,
    lastUpdateAt,
    isSearching,
    renderEmptyContent,
    breadcrumbs,
  } = props;

  const { t } = useTranslation();

  const paginationProps = useMemo((): PaginationProps => ({
    total: size(data),
    pageSize: pageSize || 30,
  }), [total, currentPage, pageSize, data]);

  const filteredColumns = useMemo(() => filter(columns, (column) => !!column.key), [columns]);

  const renderSearch = () => (
    <div className={styles.search}>
      <div className={styles.searchSpinner}>
        {
          isSearching && (
            <LoadingOutlined />
          )
        }
      </div>
      <Input
        type="search"
        className={styles.searchInput}
        prefix={<SearchOutlined />}
        onChange={(e) => onSearch?.(trim(e.target.value))}
      />
    </div>
  );

  const renderHeaderLeft = () => {
    const headerLeftElements = [];

    if (breadcrumbs) {
      headerLeftElements.push(breadcrumbs);
    }

    if (lastUpdateAt) {
      headerLeftElements.push(
        <div
          className={cx(
            styles.lastUpdatedAt,
            {
              [styles.hasBreadcrumbs]: !!breadcrumbs,
            },
          )}
        >
          {`${t('feedback.lastUpdated')} ${moment(lastUpdateAt).fromNow()}`}
        </div>,
      );
    }

    if (tableHeaderActions) {
      headerLeftElements.push(tableHeaderActions);
    }

    return headerLeftElements;
  };

  const renderHeaderRight = () => {
    if (showSearch) {
      return renderSearch();
    }
    return null;
  };

  const showEmptyContent = !!renderEmptyContent && isEmpty(data) && !isLoading;

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableHeader}>
        <div className={styles.tableHeaderLeft}>
          { renderHeaderLeft() }
        </div>
        <div className={styles.tableHeaderRight}>
          {renderHeaderRight()}
        </div>
      </div>
      {
        showEmptyContent
          ? (
            <div className={styles.emptyContent}>
              {renderEmptyContent()}
            </div>
          )
          : (
            <Table
              {...tableProps}
              className={styles.table}
              loading={isLoading}
              dataSource={[...data]}
              sticky
              rowClassName={(record) => {
                const defaultClass = (isEmpty(record) ? styles.emptyRow : styles.row);
                const classProp = tableProps?.rowClassName as any;

                let classPropParsed = null;

                if (classProp) {
                  if (isFunction(classProp)) {
                    classPropParsed = classProp(record);
                  } else {
                    classPropParsed = classProp;
                  }
                }

                const classNames = cx(
                  defaultClass,
                  classPropParsed,
                );
                return classNames;
              }}
              columns={filteredColumns}
              pagination={!hidePagination && paginationProps}
            />
          )
      }
    </div>
  );
};

const VeloxTable = React.memo(VeloxTableComponent);

export default VeloxTable;
