import logoHiRes from './logo/logo-hires.png';
import monogram from './logo/monogram.png';
import monogramLoRes from './logo/monogram-lores.png';
import LogoTyped from './logo/typed-dark.png';
import Auth0HiRes from './third-party/auth0-hires.png';
import FBColorMedium from './third-party/fb-medium.png';
import FBWhiteMedium from './third-party/fb-white.png';
import GoogleLogo from './third-party/google.png';
import MailgunHiRes from './third-party/mailgun.png';
import TwilioMedium from './third-party/twilio-medium.png';
import GenericAvatar from './utils/fox-avatar.png';

const assets = {
  logo: {
    hiRes: logoHiRes,
    default: logoHiRes,
    monogram,
    monogramLoRes,
    typed: LogoTyped,
  },
  thirdParty: {
    fb: {
      color: {
        medium: FBColorMedium,
      },
      white: {
        medium: FBWhiteMedium,
      },
    },
    google: {
      color: GoogleLogo,
    },
    twilio: {
      medium: TwilioMedium,
    },
    mailgun: {
      hiRes: MailgunHiRes,
    },
    auth0: {
      hiRes: Auth0HiRes,
    },
  },
  user: {
    avatar: {
      generic: GenericAvatar,
    },
  },
};

export default assets;
