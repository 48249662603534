import * as React from 'react';

import { isFunction } from 'lodash';

import { useAuth } from '../../../providers/Global/Auth';
import logger from '../../logger';
import useRequest, { IUseRequest, IUseRequestOpts } from './useRequest';

const { useCallback } = React;

const useAuthorizedRequest = (opts: IUseRequestOpts): IUseRequest => {
  const {
    onError,
    accessToken,
  } = opts;

  const {
    token: currentToken,
  } = useAuth();

  const { performRequest, isPerforming } = useRequest(opts);

  const performAuthorizedRequest = useCallback(async (performOpts?: IUseRequestOpts) => {
    const requestAccessToken = accessToken || performOpts?.accessToken || currentToken;

    if (!requestAccessToken) {
      logger.error('Invalid token!');
      if (isFunction(onError)) {
        onError(new Error('Invalid token.'));
      }
    }

    return performRequest({
      ...opts,
      ...performOpts,
      accessToken: requestAccessToken,
    });
  }, [opts]);

  return {
    isPerforming,
    performRequest: performAuthorizedRequest,
  };
};

export default useAuthorizedRequest;
