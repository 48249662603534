/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import {
  Button, Modal, notification, TableProps, Tag, Tooltip,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import {
  filter, includes, isEmpty, isFunction, map, some, upperFirst,
} from 'lodash';
import moment from 'moment';

import {
  DeleteOutlined, EyeOutlined, StarFilled, SyncOutlined,
} from '@ant-design/icons';
import { User } from '@auth0/auth0-spa-js';

import assets from '../../assets';
import { authorizedHttp } from '../../common/http';
import { IRole, roleMap, rolesScore } from '../../common/permissions';
import routesPaths from '../../common/routesPaths';
import { useAuth } from '../../providers/Global/Auth';
import ChangePassword from '../ChangePasswordButton';
import VeloxTable from '../Table';
import styles from './styles.module.scss';

const { useMemo, useState } = React;

interface IUserTable {
  users: User[];
  tableProps?: TableProps<any>;
  total: number;
  currentPage: number;
  pageSize: number;
  isLoading?: boolean;
  selectedIds: string[];
  onRefresh: () => void;
  onSearch: (value: string) => void;
  onSelect: (userIds: any[]) => void;
  deleteAction?: (userIds: string[]) => Promise<boolean>;
  lastUpdatedAt: number;
  deleteIcon?: React.ReactElement;
  deleteHint?: string;
}

const UsersTableComponent: React.FC<IUserTable> = (props) => {
  const {
    users,
    tableProps: tablePropsProp,
    isLoading,
    total,
    currentPage,
    pageSize,
    onSearch,
    onSelect,
    deleteAction,
    selectedIds,
    onRefresh,
    lastUpdatedAt,
    deleteIcon,
    deleteHint,
  } = props;

  const {
    auth0Id,
    permissions,
    user: currentUser,
  } = useAuth();

  const [isDeleting, setIsDeleting] = useState(false);

  const { t } = useTranslation();

  const history = useHistory();

  const tableProps = useMemo(() => {
    const finalProps = {
      ...tablePropsProp,
    };

    return finalProps;
  }, [tablePropsProp]);

  const isEditingDisabled = (userRow: User) => {
    const currentUserScore = rolesScore[currentUser?.role];
    const userScore = rolesScore[userRow?.role];

    const response = userScore < currentUserScore;

    return response;
  };

  const data = useMemo(() => map(users, (user) => ({
    key: user.user_id,
    name: user.name,
    email: user.email,
    locale: user.locale,
    picture: user.picture,
    role: user.role,
    lastLogin: user.last_login,
    id: user.sub || user.user_id,
  })), [users]);

  const checkIsMe = (user: User) => user.id === auth0Id;

  const renderNameCell = (value: User) => {
    const isMe = checkIsMe(value);

    const url = `${routesPaths.userBase}/${isMe ? 'me' : value.id}`;

    return (
      <div className={styles.nameCell}>
        <Link to={url}>
          <div
            className={styles.nameCellAvatar}
            style={{ backgroundImage: `url('${assets.user.avatar.generic}')` }}
          >
            <div
              className={styles.nameCellAvatarImg}
              style={{ backgroundImage: `url('${value.picture}')` }}
            />
          </div>
        </Link>
        <div className={styles.nameCellContent}>
          <Link to={url}>
            <div className={styles.nameCellContentName}>
              {value.name}
              {
            isMe && (
              <div className={styles.nameCellStar}>
                <Tooltip title={t('modules.users.utils.isMeTooltip')}>
                  <StarFilled />
                </Tooltip>
              </div>
            )
          }
            </div>
          </Link>
          <div className={styles.nameCellContentEmail}>
            <Button
              type="link"
              className={styles.nameCellContentEmailBtn}
              href={`mailto:${value.email}`}
            >
              {value.email}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderRoleCell = (value: User) => {
    const { role } = value;

    const roleObject: IRole = (() => {
      if (!role || !roleMap[role]) {
        return roleMap.public;
      }
      return roleMap[role];
    })();

    return (
      <Tooltip title={t(roleObject.descriptionPath)}>
        <Tag color={roleObject.color} className={styles.roleTag}>
          {t(roleObject.labelPath)}
        </Tag>
      </Tooltip>
    );
  };

  const filteredRoleMap = useMemo(() => {
    const filtered = filter(roleMap, (role) => {
      const { minPermissions } = role;
      return includes(permissions, minPermissions) || minPermissions === '*';
    });
    return filtered;
  }, [permissions, roleMap]);

  const rolesColumn = () => {
    if (some(data, (row) => !!row.role)) {
      const filters = map(filteredRoleMap, (role: IRole) => ({
        text: t(role.labelPath),
        value: role.name,
      }));

      return {
        title: t('modules.users.table.columns.role.title'),
        key: 'role',
        render: renderRoleCell,
        filters,
        onFilter: (value: any, record: User) => {
          if (value === record.role) {
            return true;
          } if (value === 'public' && (!record.role || record.role === 'none')) {
            return true;
          }
          return false;
        },
      };
    }
    return null;
  };

  const proceedDelete = async (ids: string[]) => {
    setIsDeleting(true);

    if (isFunction(deleteAction)) {
      await deleteAction(ids);
    } else {
      await authorizedHttp.delete('/users', {
        data: {
          userIds: ids,
        },
      });
    }

    notification.success({
      message: t('feedback.usersDeleted'),
    });

    setIsDeleting(false);
    onRefresh();
  };

  const handleDelete = (ids: string[]) => {
    Modal.confirm({
      title: t('feedback.areYouSure'),
      content: t('feedback.irreversibleAction'),
      centered: true,
      closable: true,
      onCancel: () => false,
      onOk: () => {
        proceedDelete(ids);
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  const renderActions = (user: User) => {
    const isMeRow = checkIsMe(user);

    return (
      <div className={styles.tableActions}>
        <ChangePassword
          user={user}
          disabled={isEditingDisabled(user)}
        />
        <Button
          className={styles.tableActionsBtn}
          type="primary"
          disabled={isEditingDisabled(user)}
          onClick={() => history.push(`${routesPaths.userBase}/${isMeRow ? 'me' : user.id}`)}
          icon={<EyeOutlined />}
        />
        <Tooltip title={t(deleteHint || 'feedback.delete')}>
          <Button
            className={styles.tableActionsBtn}
            type="primary"
            danger
            disabled={isMeRow || isEditingDisabled(user)}
            onClick={() => handleDelete([user.id])}
            icon={deleteIcon || <DeleteOutlined />}
          />
        </Tooltip>
      </div>
    );
  };

  const actionsColumn = useMemo((): ColumnType<any> => {
    const column: ColumnType<any> = {
      title: t('modules.users.table.columns.actions.title'),
      key: 'actions',
      render: renderActions,
    };

    return column;
  }, [data]);

  const columns = useMemo((): ColumnType<any>[] => [
    {
      title: t('modules.users.table.columns.name.title'),
      width: '25%',
      key: 'name',
      render: renderNameCell,
      sorter: (a: User, b: User) => {
        const aName = a.name || '';
        const bName = b.name || '';

        if (aName > bName) {
          return -1;
        } if (bName > aName) {
          return 1;
        }
        return 0;
      },
    },
    rolesColumn() || { },
    {
      title: t('modules.users.table.columns.lastLogin.title'),
      key: 'lastLogin',
      render: (value) => {
        if (!value.lastLogin) {
          return t('feedback.never');
        }
        return upperFirst(moment(value.lastLogin).fromNow());
      },
      sorter: (a: User, b: User) => {
        const aCmp = a.lastLogin ? +(new Date(a.lastLogin)) : 0;
        const bCmp = b.lastLogin ? +(new Date(b.lastLogin)) : 0;

        if (aCmp > bCmp) {
          return -1;
        } if (bCmp > aCmp) {
          return 1;
        }
        return 0;
      },
    },
    actionsColumn,
  ], [data]);

  const handleSearch = (value: any) => {
    onSearch(value as string);
  };

  const customConfig = useMemo((): TableProps<any> => {
    const fromProps = tableProps || {};

    const custom: TableProps<any> = {
      rowSelection: {
        type: 'checkbox',
        fixed: true,
        preserveSelectedRowKeys: true,
        onChange: onSelect,
        getCheckboxProps: (record: User) => ({
          disabled: checkIsMe(record) || isEditingDisabled(record),
          name: record.id,
        }),
      },
    };

    return ({
      ...fromProps,
      ...custom,
    } as TableProps<any>);
  }, [tableProps]);

  const renderTableHeaderActions = () => (
    <>
      <Button
        size="middle"
        type="ghost"
        onClick={() => onRefresh()}
        disabled={isLoading}
        icon={<SyncOutlined />}
        style={{ marginRight: 10 }}
      />
      <Tooltip title={t(deleteHint || 'feedback.delete')}>
        <Button
          size="middle"
          danger
          type="primary"
          onClick={() => handleDelete(selectedIds)}
          disabled={isEmpty(selectedIds)}
          icon={deleteIcon || <DeleteOutlined />}
        />
      </Tooltip>
    </>
  );

  return (
    <div className={styles.table}>
      <VeloxTable
        data={data}
        tableProps={customConfig}
        columns={columns}
        isLoading={!!isLoading || isDeleting}
        total={total}
        showSearch
        tableHeaderActions={renderTableHeaderActions()}
        onSearch={handleSearch}
        currentPage={currentPage}
        pageSize={pageSize}
        lastUpdateAt={lastUpdatedAt}
      />
    </div>
  );
};

const UsersTable = React.memo(UsersTableComponent);

export default UsersTable;
