/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  InputNumber, message, Modal, Select, Tooltip,
} from 'antd';
import axios from 'axios';
import * as cc from 'currency-codes';
import { isUndefined, map } from 'lodash';

import { EditOutlined } from '@ant-design/icons';

import styles from './currencyCell.styles.module.scss';

interface IProps {
  campaign: any;
  onSave: (
    campaigId: string,
    newCustomCurrency: string | null,
    newConversionValue: number | null
  ) => void;
}

const CurrencyCell: React.FC<IProps> = (props) => {
  const {
    campaign,
    onSave,
  } = props;

  const [isAutoConverting, setIsAutoConverting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCustomCurrency, setNewCustomCurrency] = useState<string | null>(null);
  const [newConversionValue, setNewConversionValue] = useState<number | null>(null);

  const { t } = useTranslation();

  const hasCustomCurrency = !!campaign.bl?.customCurrency
      && !!campaign.bl?.currencyConversionValue;

  const label = (() => {
    if (!hasCustomCurrency) {
      return campaign.bl?.currency;
    }

    return (
      <span>
        {`1 ${campaign.raw?.currency} = ${campaign.bl?.currencyConversionValue} ${campaign.bl?.customCurrency}`}
      </span>
    );
  })();

  const tooltipTitle = useMemo(() => t('utils.customCurrency'), [t]);

  const currencyCodes = useMemo(() => cc.codes().filter((c) => !['XXX', 'XTS'].includes(c)), []);

  const autoConvertCurrency = async () => {
    setIsAutoConverting(true);

    try {
      const response = await axios.get('https://data.fixer.io/api/convert', {
        params: {
          access_key: '5d01bd17bfb1f6d578781e264d589c77',
          to: newCustomCurrency,
          from: campaign.raw?.currency,
          amount: 1,
        },
      });

      const value = Math.round(Number(response.data.result) * 100) / 100;

      setNewConversionValue(value);
    } catch {
      message.error(t('unexpectedCurrencyConversion'));
    } finally {
      setIsAutoConverting(false);
    }
  };

  const saveData = () => {
    onSave(campaign.id, newCustomCurrency || campaign.raw?.currency, newConversionValue);
    setIsModalOpen(false);
  };

  const modal = useMemo(() => (
    <Modal
      visible={isModalOpen}
      destroyOnClose
      title={t('utils.customCurrency')}
      onCancel={() => setIsModalOpen(false)}
      onOk={saveData}
    >
      <div className={styles.modalInputContainer}>
        <div className={styles.modalInputLabel}>
          {t('utils.selectNewCurrency')}
        </div>
        <Select
          className={styles.modalInput}
          options={map(currencyCodes, (code) => ({
            label: `${cc.code(code)?.currency} (${code})`,
            value: code,
            disabled: code === campaign.raw?.currency,
          }))}
          showSearch
          value={newCustomCurrency || ''}
          onSelect={(value) => setNewCustomCurrency(value || null)}
          placeholder={t('utils.selectNewCurrency')}
        />
      </div>
      {
        newCustomCurrency && (
          <div className={styles.modalInputContainer}>
            <div className={styles.modalInputLabel}>
              {t('utils.currencyConversion')}
            </div>
            <div className={styles.modalTwoInput}>
              {`1 ${campaign.raw?.currency} = `}
              <InputNumber
                addonAfter={newCustomCurrency}
                value={newConversionValue || 0}
                className={styles.smallInput}
                onChange={(v) => setNewConversionValue(v)}
              />
            </div>
            <Button
              size="small"
              onClick={autoConvertCurrency}
              loading={isAutoConverting}
            >
              {t('utils.currencyAutoAssign')}
            </Button>
          </div>
        )
      }
      {
        (newCustomCurrency && newConversionValue)
          && (
            <Button
              size="small"
              style={{ marginTop: 20 }}
              onClick={() => {
                setNewConversionValue(null);
                setNewCustomCurrency(null);
              }}
              danger
            >
              {t('utils.clearAll')}
            </Button>
          )
      }
    </Modal>
  ), [
    campaign,
    currencyCodes,
    newCustomCurrency,
    newConversionValue,
    isModalOpen,
    isAutoConverting,
  ]);

  useEffect(() => {
    if (campaign.bl?.customCurrency) {
      setNewCustomCurrency(campaign.bl.customCurrency);
    }

    if (!isUndefined(campaign.bl?.currencyConversionValue) && campaign.bl?.customCurrency) {
      setNewConversionValue(campaign.bl.currencyConversionValue);
    }
  }, [campaign]);

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <div
          className={styles.currencyCell}
          onClick={() => setIsModalOpen(true)}
        >
          <div className={styles.label}>
            {label}
          </div>
          <div className={styles.icon}>
            <EditOutlined />
          </div>
        </div>
      </Tooltip>
      {modal}
    </>
  );
};

export default CurrencyCell;
