import * as React from 'react';

import assets from '../../assets';
import lottieAssets from '../../assets/lottie';
import LottieCommon from '../../components/Common/Lottie/Common';
import StatusChecks from '../../components/Common/StatusChecks';
import styles from './styles.module.scss';

const StatusPageComponent: React.FC = () => (
  <div className={styles.errorPage}>
    <div className={styles.errorContainer}>
      <div className={styles.errorLogo}>
        <a href="/">
          <img src={assets.logo.hiRes} alt="Velox Vision Logo" />
        </a>
      </div>
      <div className={styles.statusChecksWrapper}>
        <StatusChecks />
      </div>
    </div>
    <div className={styles.footerAnimation}>
      <LottieCommon
        animationData={lottieAssets.footerAnimation}
        fullWidth
        height={300}
      />
    </div>
  </div>
);

const StatusPage = React.memo(StatusPageComponent);

export default StatusPage;
