import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { delay, isUndefined } from 'lodash';

import { WarningOutlined } from '@ant-design/icons';

import lottieAssets from '../../../assets/lottie';
import useRequest from '../../../common/hooks/http/useRequest';
import logger from '../../../common/logger';
import { IStatusCheck, statusTransaltionKey } from '../../../common/statusChecksDef';
import LottieCommon from '../Lottie/Common';
import styles from './statusStyle.module.scss';

const { useCallback, useEffect, useState } = React;

interface IStatusCheckComponent {
  definition: IStatusCheck,
  onPerformCheck: (isHealthy: boolean | undefined) => void;
}

const StatusCheckComponent: React.FC<IStatusCheckComponent> = (props) => {
  const {
    definition,
    onPerformCheck,
  } = props;

  const { t } = useTranslation();

  const definitionTranslationKey = statusTransaltionKey?.replaceAll('{{key}}', definition.key);
  const definitionTitle = t(`${definitionTranslationKey}.title`);
  const definitionDescription = t(`${definitionTranslationKey}.description`);
  const definitionEffect = t(`${definitionTranslationKey}.effect`);

  const [isHealthy, setIsHealthy] = useState<boolean | undefined>();
  const [isPerforming, setIsPerforming] = useState(false);

  let breakExecution = false;

  const {
    performRequest,
  } = useRequest({
    url: definition.pingURL,
    method: definition.preferredMethod || 'OPTIONS',
    throwErrors: true,
  });

  useEffect(() => {
    onPerformCheck(isHealthy);
  }, [isHealthy]);

  const performCheck = useCallback(async () => {
    try {
      await performRequest();
      setIsHealthy(true);
      return true;
    } catch {
      logger.error(`Service ${definition.key} @ ${definition.pingURL} is not available.`);
      setIsHealthy(false);
      return false;
    }
  }, []);

  const runPerformCheck = useCallback(async () => {
    if (breakExecution) {
      return;
    }

    setIsPerforming(true);
    setIsHealthy(undefined);

    await new Promise((resolve) => {
      setTimeout(() => {
        performCheck().then(resolve);
      }, 4000);
    });

    setIsPerforming(false);

    /** Seconds */
    const buffer = isHealthy
      ? (60)
      : 20;

    delay(() => runPerformCheck(), buffer * 1000);
  }, [breakExecution]);

  const isFetching = isPerforming
    || isUndefined(isHealthy);

  useEffect(() => {
    runPerformCheck();

    return () => {
      breakExecution = true;
    };
  }, []);

  const renderStatusIcon = useCallback(() => {
    let animationData = lottieAssets.spinner;
    let size = 40;

    if (isFetching) {
      animationData = lottieAssets.spinner;
      size = 30;
    } else if (isHealthy) {
      animationData = lottieAssets.check;
    } else {
      animationData = lottieAssets.error;
    }

    return (
      <div className={styles.statusCheckIcon}>
        <LottieCommon
          animationData={animationData}
          width={size}
          height={size}
        />
      </div>
    );
  }, [isFetching, isHealthy]);

  return (
    <div className={styles.statusCheck}>
      <div className={styles.statusCheckContainer}>
        <div className={styles.definitionContainer}>
          <div className={styles.definitionLogo}>
            <a href={definition.statusPage} target="_blank" rel="noreferrer">
              <img src={definition.iconURL} alt={`Logo of ${definitionTitle}`} />
            </a>
          </div>
          <div className={styles.definitionDes}>
            <div className={styles.definitionTitle}>
              { definitionTitle }
            </div>
            <div className={styles.definitionDescription}>
              { definitionDescription }
            </div>
            {
              (!isPerforming && !isHealthy) && (
                <div className={styles.definitionEffect}>
                  <WarningOutlined
                    className={styles.definitionEffectIcon}
                  />
                  <div className={styles.definitionEffectLabel}>
                    { definitionEffect }
                  </div>
                </div>
              )
            }
          </div>
        </div>
        { renderStatusIcon() }
      </div>

    </div>
  );
};

const StatusCheck = React.memo(StatusCheckComponent);

export default StatusCheck;
