const colors = {
  primary: '#45354d',
  primaryLight: '#7a568b',
  secondary: '#fff7cc',
  secondaryAlt: '#fdee9d',
  dark: '#231425',
  dark700: '#352d36',
  light: '#fdfcf6',
  almostWhite: '#ddd',
  orange: '#f2c43a',
  danger: '#ff0e0e',
  success: '#4bb543',
  neutral: '#a5a5a5',
  info: '#6a7ad6',
  warning: '#cccc4f',
  white: '#fffff',
};

export default colors;
