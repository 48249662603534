import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import { AppstoreAddOutlined } from '@ant-design/icons';

import { IField } from '../../common/fields';
import { authorizedHttp } from '../../common/http';
import ModuleModal from '../ModuleModal';
import styles from './styles.module.scss';

const { useMemo, useState } = React;

interface IAddAccountButton {
  onCreate: (data: any) => void;
}

const AddAccountButtonComponent: React.FC<IAddAccountButton> = (props) => {
  const {
    onCreate,
  } = props;

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formContents = useMemo((): IField[] => [
    {
      type: 'image-picker',
      name: 'logo',
      label: t('fields.logo'),
      imagePickerProps: {
        aspect: 0.3,
      },
    },
    {
      type: 'text',
      name: 'name',
      required: true,
      label: t('fields.accountName'),
    },
    {
      type: 'number',
      name: 'organizationLimits',
      default: 2,
      required: true,
      label: t('fields.accountOrganizationLimit'),
      min: 1,
      max: 20,
    },
    {
      type: 'user-picker',
      name: 'guardian',
      required: true,
      label: t('fields.guardian'),
    },
  ], []);

  const handleSubmit = async (values: any) => {
    const request = await authorizedHttp.post('/accounts', values);
    onCreate(request.data.payload.account);
    return null;
  };

  return (
    <>
      <Button
        icon={<AppstoreAddOutlined />}
        className={styles.AddAccountBtn}
        type="primary"
        onClick={() => setIsModalOpen(true)}
      >
        {t('actions.addAccount.label')}
      </Button>
      <ModuleModal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formFields={formContents}
        onSubmit={handleSubmit}
        title="actions.addAccount.label"
        submitLabel="modules.accounts.add.submitLabel"
      />
    </>
  );
};

const AddAccountButton = React.memo(AddAccountButtonComponent);

export default AddAccountButton;
