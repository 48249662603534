import numeral from 'numeral';

const formatCurrency = (value: unknown, currency: string = 'USD') => {
  const cleanValue: number = value ? (numeral(value).value() || 0) : 0;

  return new Intl.NumberFormat(
    'en-US',
    {
      style: 'currency',
      currency,
      maximumFractionDigits: 3,
    },
  ).format(cleanValue);
};

export default formatCurrency;
