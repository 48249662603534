import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { notification } from 'antd';
import { map } from 'lodash';

import { UserDeleteOutlined } from '@ant-design/icons';

import fuzzySearch from '../../common/fuzzySearch';
import { authorizedHttp } from '../../common/http';
import logger from '../../common/logger';
import AddEntiityUserButton from '../../components/AddEntityUserButton';
import withPlatformLayout from '../../components/HOC/withPlatformLayout';
import ModuleWrapper from '../../components/ModuleWrapper';
import UsersTable from '../../components/UsersTable';

const { useEffect, useMemo, useState } = React;

const Account = () => {
  const [payload, setPayload] = useState<Record<string, any>>({});
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState();

  const { accountId } = useParams() as any;

  const { t } = useTranslation();

  const fetchSetAccount = async (forceCache?: boolean) => {
    setIsLoading(true);
    setSelectedIds([]);

    try {
      const params: any = {
        includeUsers: 1,
      };

      if (forceCache) {
        params.t = +Date.now();
      }
      const response = await authorizedHttp.get(`/account/${accountId}`, {
        params,
      });

      setLastUpdatedAt(response.data.timestamp);
      setData(response.data.payload.account.users);
      setPayload(response.data.payload);
    } catch (err) {
      logger.error(`Error while fetching account: ${JSON.stringify(err)}`);
      notification.error({
        message: t('modules.account.alerts.fetched.error.title'),
        description: t('modules.account.alerts.fetched.error.description'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSetAccount();
  }, [accountId]);

  const currentPage = useMemo(() => (payload.start % payload.limit) + 1, [payload]);

  const handleAssign = async (userIds: any) => {
    await authorizedHttp.put(`/account/${accountId}/users`, {
      userIds,
    });

    fetchSetAccount(true);
  };

  const handleSearch = (searchQuery: string) => {
    const collection = payload.account.users;

    if (!searchQuery) {
      setData(collection);
      return;
    }

    const filteredDataSearch = fuzzySearch(searchQuery, collection);
    const filteredData = map(filteredDataSearch, 'item');

    setData(filteredData);
  };

  const handleRefresh = () => {
    fetchSetAccount(true);
  };

  const handleSelect = (userIds: any[]) => {
    setSelectedIds(userIds as string[]);
  };

  const deleteAction = async (userIds: string[]): Promise<boolean> => {
    const response = await authorizedHttp.delete(`/account/${accountId}/users`, {
      data: {
        userIds,
      },
    });

    return !!response;
  };

  return (
    <ModuleWrapper
      titleKey="account.pageTitle"
      goBackBreadCrumbTitle="accounts.pageTitle"
      translateVariables={{
        accountName: payload?.account?.name,
      }}
      headerLogo={payload?.account?.logo}
      headerRender={() => (
        <AddEntiityUserButton
          onSubmit={handleAssign}
          excludeIds={map(data, 'user_id')}
        />
      )}
    >
      <UsersTable
        isLoading={isLoading}
        users={data}
        lastUpdatedAt={lastUpdatedAt || 0}
        selectedIds={selectedIds}
        total={payload.total}
        currentPage={currentPage}
        pageSize={payload.limit}
        deleteAction={deleteAction}
        onSearch={handleSearch}
        onSelect={handleSelect}
        onRefresh={handleRefresh}
        deleteIcon={<UserDeleteOutlined />}
        deleteHint="feedback.unassign"
      />
    </ModuleWrapper>
  );
};

export default withPlatformLayout(Account);
