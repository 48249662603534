import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { isFunction } from 'lodash';
import { io, Socket } from 'socket.io-client';

import logger from '../../common/logger';
import Suspense from '../../components/Common/Suspense';
import ErrorPage from '../../pages/Error';
import { useAuth } from '../Global/Auth';

const {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} = React;

interface ISocketContext {
  isAvailable: boolean;
  isDisconnected: boolean;
  socket: Socket | undefined;
  subscribeToClientStream: (clientId: string, callbackOnProgress?: (pct: number) => void) => void;
  unsubscribeFromClientStream: (clientId: string) => void;
}

const SocketContext = createContext<ISocketContext>({} as ISocketContext);

interface ISocketProvider {
  children: React.ReactNode;
}

// Seconds
const BROADCAST_FATAL_TIMEOUT = 10;
const BROADCAST_DISCONNECTED_TIMEOUT = 5;

const SocketProvider: React.FC<ISocketProvider> = (props) => {
  const {
    children,
  } = props;

  const { t } = useTranslation();

  const [isSubscribedToUserStream, setIsSubscribedToUserStream] = useState(false);
  const [socket, setSocket] = useState<Socket>();
  const [isDisconnected, setIsDisconnected] = useState(false);
  const [isFatal, setIsFatal] = useState(false);

  let fatalTimeout: number = 0;
  let disconnectedTimeout: number = 0;

  const {
    isAuthorized,
    auth0Id,
    logout,
    silentlyRefetchUser,
  } = useAuth();

  const startFatalCount = () => {
    window.clearTimeout(fatalTimeout);
    setIsFatal(false);
    fatalTimeout = window.setTimeout(() => {
      setIsFatal(true);
    }, BROADCAST_FATAL_TIMEOUT * 1000);
  };

  const startDisconectedCount = () => {
    window.clearTimeout(disconnectedTimeout);
    setIsDisconnected(false);
    disconnectedTimeout = window.setTimeout(() => {
      setIsDisconnected(true);
    }, BROADCAST_DISCONNECTED_TIMEOUT * 1000);
  };

  const subscribeToClientStream = useCallback(
    (clientId: string, callbackOnProgress?: (pct: number) => void) => {
      if (socket) {
        logger.debug(`Subscribed to client broadcast: ${clientId}`);

        /** User to client report information */
        socket.on(`client/${clientId}/report`, (args?: Record<string, unknown>) => {
          logger.debug('New loading ping.');
          const {
            pct,
          } = args || {};

          if (isFunction(callbackOnProgress)) {
            callbackOnProgress(pct as number);
          }
        });
      }
    }, [socket],
  );

  const unsubscribeFromClientStream = useCallback(
    (clientId: string) => {
      if (socket) {
        logger.debug(`Unsuscribed from client broadcast: ${clientId}`);
        socket.off(`client/${clientId}/report`);
      }
    }, [socket],
  );

  const subscribeToUserStream = useCallback(() => {
    if (!isSubscribedToUserStream && socket) {
      logger.debug('Subscribed to users broadcast.');

      /** User specific broadcast */
      socket.on(`user/${auth0Id}`, (args?: Record<string, unknown>) => {
        logger.debug('New specific users broadcast.');
        const {
          type,
        } = args || {};

        if (type === 'LOGOUT') {
          logout();
        }

        if (type === 'UPDATE') {
          logger.debug('Updating profile from broadcast...');
          silentlyRefetchUser();
        }
      });

      /** General broadcast for users */
      socket.on('users', () => '');

      socket.emit('users', { type: 'CONNECTED' });

      setIsSubscribedToUserStream(true);
    }
  }, [auth0Id, isAuthorized, socket]);

  useEffect(() => {
    if (socket && auth0Id && isAuthorized) {
      socket.on('connect', () => {
        subscribeToUserStream();
      });
    }
  }, [auth0Id, isAuthorized, socket]);

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_BROADCAST_URL || '');
    setSocket(newSocket);

    newSocket.on('connect', () => {
      logger.debug('Broadcast ready!');
      setIsDisconnected(false);
      setIsFatal(false);
      window.clearTimeout(fatalTimeout);
      window.clearTimeout(disconnectedTimeout);
    });

    newSocket.on('disconnect', () => {
      logger.error('Broadcast disconnected');
      startFatalCount();
      startDisconectedCount();
    });

    return () => {
      newSocket.close();
    };
  }, []);

  const renderContent = () => {
    if (isDisconnected) {
      return <Suspense message={t('errors.reconnect')} />;
    } if (isFatal) {
      return <ErrorPage />;
    }
    return children;
  };

  return (
    <SocketContext.Provider
      value={{
        isDisconnected,
        isAvailable: !!(socket && socket.connected),
        socket,
        subscribeToClientStream,
        unsubscribeFromClientStream,
      }}
    >
      { renderContent() }
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);

export default SocketProvider;
