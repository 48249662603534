/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from 'antd';

import { UserAddOutlined } from '@ant-design/icons';

import { IField } from '../../common/fields';
import { authorizedHttp } from '../../common/http';
import { validateEmail } from '../../common/validators';
import ModuleModal from '../ModuleModal';
import NewUserAlert from '../NewUserAlert';
import styles from './styles.module.scss';

const { useMemo, useState } = React;

interface IAddUserButton {
  buttonProps?: ButtonProps;
  onSubmit: (auth0Id: string) => void;
}

const AddUserButtonComponent: React.FC<IAddUserButton> = (props) => {
  const {
    buttonProps,
    onSubmit,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();

  const formContents = useMemo((): IField[] => [
    {
      type: 'image-picker',
      name: 'picture',
      label: t('fields.picture'),
      className: styles.avatarContainer,
    },
    {
      type: 'text',
      name: 'name',
      required: true,
      label: t('fields.name'),
    },
    {
      type: 'text',
      name: 'email',
      required: true,
      label: t('fields.email'),
      validateFunction: (email: string) => validateEmail(email, undefined, true),
      validateFunctionMessage: 'fields.errors.emailOcuppied',
    },
  ], []);

  const handleSubmit = async (values: Record<string, unknown>) => {
    const response = await authorizedHttp.post('/users', values);
    const password = response.data?.payload?.password;
    onSubmit(response.data.payload.auth0Id);
    return (
      <NewUserAlert
        name={values.name as string}
        password={password}
        email={values.email as string}
      />
    );
  };

  return (
    <>
      <Button
        icon={<UserAddOutlined />}
        className={styles.addUserBtn}
        type="primary"
        onClick={() => setIsModalOpen(true)}
        {...buttonProps}
      >
        {t('actions.addUser.label')}
      </Button>
      <ModuleModal
        title="actions.addUser.label"
        formFields={formContents}
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleSubmit}
        submitLabel="actions.addUser.label"
      />
    </>
  );
};

const AddUserButton = React.memo(AddUserButtonComponent);

export default AddUserButton;
