import * as React from 'react';

import qs from 'query-string';

import PlatformPreferences from '../../common/platformPreferences';
import { usePlatformPreferences } from '../Global/Preferences';

const {
  createContext, useContext, useEffect, useMemo, useState,
} = React;

interface IRequestFB {
  endpoint: string;
  args?: Record<string, unknown>;
}

interface IFBContext {
  isLoading: boolean;
  isAvailable: boolean;
  FB: any;
  requestFB: (args: IRequestFB) => Promise<unknown>
}

const FBContext = createContext<IFBContext>({} as IFBContext);

interface IFBProvider {
  children: React.ReactNode
}

declare global {
    interface Window {
      FB: any;
      fbAsyncInit: Function;
    }
}

const FBProvider: React.FC<IFBProvider> = (props) => {
  const {
    children,
  } = props;

  const {
    isLoading: isLoadingPreferences,
    getPreference,
  } = usePlatformPreferences();

  const [isLoadingSdk, setIsLoadingSdk] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);

  const FBAppId = useMemo(() => getPreference(PlatformPreferences.fb.appId), [getPreference]);
  const FB = useMemo(() => window.FB, [window]);

  const initFBSdk = () => {
    const FBPromise: Promise<boolean> = new Promise((resolve) => {
      if (!window.FB) {
        resolve(false);
      }

      window.FB.init({
        appId: FBAppId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v20.0',
      });

      resolve(true);
    });
    return FBPromise;
  };

  const initFB = async () => {
    setIsLoadingSdk(true);
    const response = await initFBSdk();
    setIsAvailable(response);
    setIsLoadingSdk(false);
  };

  useEffect(() => {
    initFB();
  }, [FBAppId, window]);

  const requestFB = async (args: IRequestFB): Promise<unknown> => {
    if (!FB) {
      throw new Error('FB is not intialized.');
    }

    let finalEndpoint = args.endpoint;

    if (args.args) {
      const queryString = `?${qs.stringify(args.args)}`;
      finalEndpoint += queryString;
    }

    const res = await new Promise((resolve, reject) => {
      FB.api(finalEndpoint, (response: unknown) => {
        resolve(response);
      }, (err: Error) => {
        reject(err);
      });
    });

    return res;
  };

  const isLoading = useMemo(() => (
    isLoadingPreferences
        || isLoadingSdk
  ), [isLoadingPreferences, isLoadingSdk]);

  return (
    <FBContext.Provider
      value={{
        isLoading,
        isAvailable,
        requestFB,
        FB,
      }}
    >
      { children }
    </FBContext.Provider>
  );
};

export const useFB = () => useContext(FBContext);

export default FBProvider;
