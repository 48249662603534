import * as React from 'react';

import { ImgCropProps } from 'antd-img-crop';
import cx from 'classnames';
import { first } from 'lodash';

import { PictureOutlined } from '@ant-design/icons';

import { IUploadedFile } from '../../common/hooks/utils/useFileUpload';
import ImageUpload from '../ImageUpload';
import styles from './imagePicker.module.scss';

const { useRef, useMemo, useState } = React;

interface IImagePicker {
  aspect?: number;
  className?: string | string[];
  cropperProps?: ImgCropProps;
  value?: string;
  onChange?: (value: string) => void;
}

const ImagePicker: React.FC<IImagePicker> = (props) => {
  const {
    className,
    cropperProps,
    aspect,
    value,
    onChange,
  } = props;

  const parentRef = useRef<HTMLDivElement>(null);
  const [preview, setPreview] = useState('');

  const parentWidth = useMemo((): number => {
    if (!parentRef) {
      return 0;
    }

    return (
      parentRef.current?.clientWidth
        || 0
    );
  }, [parentRef, parentRef?.current]);

  const handleUpload = (result: IUploadedFile[]) => {
    const firstResult = first(result);
    onChange?.(firstResult?.url || '');
  };

  return (
    <div
      ref={parentRef}
      className={cx(styles.imagePicker, className)}
    >
      <div
        className={styles.imagePickerContainer}
        style={{
          backgroundImage: `url('${preview || value}')`,
          height: `${(aspect || 1) * parentWidth}px`,
        }}
      >
        {
          !(preview || value) && (
            <div className={styles.placeholder}>
              <PictureOutlined />
            </div>
          )
        }
        <ImageUpload
          onUpload={handleUpload}
          onPreview={setPreview}
          aspect={aspect}
          cropperProps={cropperProps}
        />
      </div>
    </div>
  );
};

export default ImagePicker;
