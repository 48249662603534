import * as React from 'react';
import { Helmet } from 'react-helmet';

import { customizedClientData, getCustomClientByHost } from '../../utils';
import Auth2WProvider from './Auth2W';
import LoadingProvider from './LoadingProvider';
import PlatformPrefencesProvider from './Preferences';

interface IGlobalProvider {
  children: React.ReactNode;
}

const GlobalProvider = (props: IGlobalProvider) => {
  const {
    children,
  } = props;

  const currentCustomClient = getCustomClientByHost();
  const clientData = customizedClientData[currentCustomClient];

  return (
    <Auth2WProvider>
      <Helmet>
        <title>{clientData.clientName}</title>
        <link href={clientData.monogram} rel="icon" type="image/png" />
      </Helmet>
      <PlatformPrefencesProvider>
        <LoadingProvider>
          { children }
        </LoadingProvider>
      </PlatformPrefencesProvider>
    </Auth2WProvider>
  );
};

export default GlobalProvider;
