/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';

import { Button } from 'antd';
import cx from 'classnames';

import { CaretDownOutlined, CaretUpOutlined, PlusCircleFilled } from '@ant-design/icons';

import styles from './styles.module.scss';

interface IDataProviderWrapper {
  icon: React.ReactElement;
  label: 'Facebook' | 'Google Ads';
  provider: 'facebook' | 'google';
  onToggleOpen: () => void;
  onClickAdd: () => void;
  isOpen: boolean;
  isAddLoading?: boolean;
  counts?: number;
}

const DataProviderWrapper: FC<IDataProviderWrapper> = (props) => {
  const {
    icon,
    label,
    provider,
    onClickAdd,
    onToggleOpen,
    isOpen,
    children,
    isAddLoading,
    counts = 0,
  } = props;

  return (
    <div
      className={cx(
        styles.dataProviderWrapper,
        `provider-${provider}`,
      )}
    >
      <div
        className={styles.dataProviderHead}
        onClick={onToggleOpen}
      >
        <div className={styles.dataProviderHeadChevron}>
          {
            isOpen
              ? <CaretUpOutlined />
              : <CaretDownOutlined />
          }
        </div>
        <div className={styles.dataProviderHeadLabel}>
          <div className={styles.dataProviderHeadLabelIcon}>
            {icon}
          </div>
          <div className={styles.dataProviderHeadLabelName}>
            {label}
            <span className={styles.dataProviderHeadLabelCounts}>
              {`(${counts})`}
            </span>
          </div>
        </div>
        <div className={styles.dataProviderHeadActions}>
          <Button
            shape="circle"
            type="primary"
            size="small"
            loading={isAddLoading}
            icon={<PlusCircleFilled />}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClickAdd();
            }}
          />
        </div>
      </div>
      {
        isOpen && (
          <div
            className={styles.dataProviderBody}
          >
            {children}
          </div>
        )
      }
    </div>
  );
};

export default DataProviderWrapper;
