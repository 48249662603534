import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { notification } from 'antd';
import { map } from 'lodash';

import { User } from '@auth0/auth0-spa-js';

import fuzzySearch from '../../common/fuzzySearch';
import { authorizedHttp } from '../../common/http';
import logger from '../../common/logger';
import AddUserButton from '../../components/AddUserButton';
import withPlatformLayout from '../../components/HOC/withPlatformLayout';
import ModuleWrapper from '../../components/ModuleWrapper';
import UsersTable from '../../components/UsersTable';

const { useEffect, useMemo, useState } = React;

const Users = () => {
  const [payload, setPayload] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<User[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState();

  const { t } = useTranslation();

  const fetchSetUsers = async (forceCache?: boolean) => {
    setIsLoading(true);
    setSelectedIds([]);

    try {
      const query: any = {};

      if (forceCache) {
        query.t = +Date.now();
      }

      const response = await authorizedHttp.get('/users', {
        params: query,
      });

      setLastUpdatedAt(response.data.timestamp);
      setPayload(response.data.payload);
      setData(response.data.payload.users as User[]);
    } catch (err) {
      logger.error(`Error while fetching users: ${JSON.stringify(err)}`);
      notification.error({
        message: t('modules.users.alerts.fetched.error.title'),
        description: t('modules.users.alerts.fetched.error.description'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSetUsers();
  }, []);

  const currentPage = useMemo(() => (payload.start % payload.limit) + 1, [payload]);

  const handleSearch = (searchQuery: string) => {
    const collection: User[] = payload.users as User[];

    if (!searchQuery) {
      setData(collection);
      return;
    }

    const filteredDataSearch = fuzzySearch(searchQuery, collection);
    const filteredData = map(filteredDataSearch, 'item');

    setData(filteredData as User[]);
  };

  const handleSelect = (userIds: any[]) => {
    setSelectedIds(userIds as string[]);
  };

  const handleRefresh = () => {
    fetchSetUsers(true);
  };

  return (
    <ModuleWrapper
      titleKey="users.pageTitle"
      headerRender={() => <AddUserButton onSubmit={() => fetchSetUsers(true)} />}
    >
      <UsersTable
        isLoading={isLoading}
        users={data}
        lastUpdatedAt={lastUpdatedAt || 0}
        selectedIds={selectedIds}
        total={payload.total}
        currentPage={currentPage}
        pageSize={payload.limit}
        onSearch={handleSearch}
        onSelect={handleSelect}
        onRefresh={handleRefresh}
      />
    </ModuleWrapper>
  );
};

export default withPlatformLayout(Users);
