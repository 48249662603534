import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IField } from '../../../common/fields';
import ParsedForm from '../../../components/ParsedForm';
import { useOrganizationSave } from '../../../providers/OrganizationSave';
import commonStyles from '../styles.module.scss';

const OrganizationBasicInformation: FC = () => {
  const {
    setDataValues,
    organizationData,
  } = useOrganizationSave();

  const { t } = useTranslation();

  const formContents = useMemo((): IField[] => [
    {
      type: 'image-picker',
      name: 'logo',
      label: t('fields.logo'),
      imagePickerProps: {
        aspect: 0.3,
      },
    },
    {
      type: 'text',
      name: 'name',
      required: true,
      label: t('fields.organizationName'),
    },
    {
      type: 'account-picker',
      name: 'accountId',
      required: true,
      label: t('fields.account'),
    },
    {
      type: 'user-picker',
      name: 'guardian',
      required: true,
      label: t('fields.guardian'),
    },
  ], []);

  return (
    <div className={commonStyles.organizationSaveForm}>
      <ParsedForm
        fields={formContents}
        initialValues={organizationData as unknown as Record<string, unknown>}
        onValuesChange={setDataValues}
        onlyControl
      />
    </div>
  );
};

export default OrganizationBasicInformation;
