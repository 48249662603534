import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Button } from 'antd';

import {
  HomeOutlined, LoginOutlined, MailOutlined, RollbackOutlined,
} from '@ant-design/icons';

import assets from '../../assets';
import lottieAssets from '../../assets/lottie';
import LottieCommon from '../../components/Common/Lottie/Common';
import { useAuth } from '../../providers/Global/Auth';
import styles from './styles.module.scss';

interface IErrorPage {
  error?: Error;
}

const Error404PageComponent: React.FC<IErrorPage> = () => {
  const history = useHistory();
  const tPrefix = 'errors.errorBoundary';

  const {
    forceAuthorization,
    isAuthorized,
  } = useAuth();

  const { t } = useTranslation();

  const errorKeysPrefix = `${tPrefix}.404`;

  const renderCallToActions = () => (
    <div className={styles.errorPageCallToActions}>
      <Button
        className={styles.errorPageButton}
        type="primary"
        href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
        icon={<MailOutlined />}
      >
        {t(`${tPrefix}.ctas.contactUs`)}
      </Button>
      <Button
        className={styles.errorPageButton}
        ghost
        type="primary"
        onClick={() => history.goBack()}
        icon={<RollbackOutlined />}
      >
        {t(`${tPrefix}.ctas.goBack`)}
      </Button>
      {
        isAuthorized
          ? (
            <Button
              className={styles.errorPageButton}
              ghost
              type="primary"
              onClick={() => history.push('/')}
              icon={<HomeOutlined />}
            >
              {t(`${tPrefix}.ctas.home`)}
            </Button>
          ) : (
            <Button
              className={styles.errorPageButton}
              ghost
              type="primary"
              onClick={() => forceAuthorization()}
              icon={<LoginOutlined />}
            >
              {t(`${tPrefix}.ctas.login`)}
            </Button>
          )
      }

    </div>
  );

  return (
    <div className={styles.errorPage}>
      <div className={styles.errorContainer}>
        <div className={styles.errorLogo}>
          <a href="/">
            <img src={assets.logo.hiRes} alt="Velox Vision Logo" />
          </a>
        </div>
        <div className={styles.errorTitle}>
          {t(`${errorKeysPrefix}.title`)}
        </div>
        <div className={styles.errorDescription}>
          {t(`${errorKeysPrefix}.description`)}
        </div>
        <div className={styles.errorMoreDetails}>
          {t(`${errorKeysPrefix}.moreDetails`)}
        </div>
        { renderCallToActions() }
      </div>
      <div className={styles.error404Animation}>
        <LottieCommon
          animationData={lottieAssets.error404}
          height={330}
          width={600}
        />
      </div>
      <div className={styles.footerAnimation}>
        <LottieCommon
          animationData={lottieAssets.footerAnimation}
          fullWidth
          height={300}
        />
      </div>
    </div>
  );
};

const Error404Page = React.memo(Error404PageComponent);

export default Error404Page;
