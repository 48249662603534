/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import cx from 'classnames';
import {
  find, isEmpty, map,
} from 'lodash';

import { CloseOutlined } from '@ant-design/icons';

import lottieAssets from '../../../assets/lottie';
import useCampaignBreakdowns from '../../../common/hooks/useCampaignBreakdowns';
import { useReport } from '../../../providers/Platform/Reports/Report';
import LottieCommon from '../../Common/Lottie/Common';
import EmptyContent from '../../EmptyContent';
import Metric from './Metric';
import MetricsPicker from './MetricsPicker';
import styles from './styles.module.scss';

const RightPanelComponent: React.FC = () => {
  const {
    selectedCampaignId,
    selectedNodeId,
    clientId,
    dateRange,
    hideBreakdowns,
  } = useReport();

  const {
    isLoading,
    destroy,
    metricsKeys,
    setMetricsKeys,
    getMetricData,
    availableMetrics,
  } = useCampaignBreakdowns({
    campaignId: selectedCampaignId,
    nodeId: selectedNodeId,
    clientId: (clientId as string),
    dateRange,
  });

  const { t } = useTranslation();

  const isVisible = !!selectedCampaignId;

  const handleClose = () => {
    hideBreakdowns();
    destroy();
  };

  const renderActions = () => (
    <div className={styles.topActions}>
      <div className={styles.closeAction} onClick={handleClose}>
        <CloseOutlined />
      </div>
    </div>
  );

  const renderLoading = () => (
    <div className={
      cx(
        styles.suspense,
      )
    }
    >
      <div className={styles.reportSpinner}>
        <LottieCommon
          width={60}
          height={60}
          animationData={lottieAssets.loaderReport}
        />
      </div>
    </div>
  );

  const renderMetricsContent = () => (
    map(metricsKeys.reverse(), (metricKey) => {
      const metricInformation = find(availableMetrics, ['field', metricKey]);
      const metricData = getMetricData(metricKey);

      if (!metricInformation || !metricData) {
        return null;
      }

      return (
        <Metric
          metricInformation={metricInformation}
          data={metricData}
        />
      );
    })
  );

  const renderContent = () => (
    <div className={styles.content}>
      <div className={styles.header}>
        <MetricsPicker
          selected={metricsKeys}
          onChange={setMetricsKeys}
          availableMetrics={availableMetrics}
        />
      </div>
      <div className={styles.metricsContent}>
        {
          isEmpty(metricsKeys)
            ? (
              <EmptyContent
                className={styles.emptyMetrics}
                label={t('report.emptyMetrics')}
              />
            )
            : renderMetricsContent()
        }
      </div>
    </div>
  );

  return (
    <div
      className={cx(
        styles.rightPanel,
        {
          [styles.loading]: isLoading,
          [styles.visible]: isVisible,
        },
      )}
    >
      {renderActions()}
      {
        isLoading
          ? renderLoading()
          : renderContent()
      }
    </div>
  );
};

const RightPanel = React.memo(RightPanelComponent);

export default RightPanel;
