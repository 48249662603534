import * as React from 'react';

import { publicHttp } from '../../common/http';

const {
  createContext,
  useContext,
} = React;

interface IGoogleContext {
  signIn: () => Promise<unknown>;
}

const GoogleContext = createContext<IGoogleContext>({} as IGoogleContext);

interface IGoogleProvider {
  children: React.ReactNode
}

const GoogleProvider: React.FC<IGoogleProvider> = (props) => {
  const {
    children,
  } = props;

  const signIn = async () => {
    const response = await publicHttp.get('public/auth/google/authURL', {
      params: {
        originURL: window.location.href,
      },
    });
    window.location.replace(response.data.payload.url);
  };

  return (
    <GoogleContext.Provider
      value={{
        signIn,
      }}
    >
      { children }
    </GoogleContext.Provider>
  );
};

export const useGoogle = () => useContext(GoogleContext);

export default GoogleProvider;
