import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import qs from 'query-string';

import Suspense from '../../components/Common/Suspense';
import { useAuth } from '../../providers/Global/Auth';

const CallbackComponent: React.FC = () => {
  const { isLoading, logout } = useAuth();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const type = (params as Record<string, string>).callbackType;

  if (type === 'logout') {
    history.push('/?logout=1');
    return null;
  }

  if (type === 'login') {
    if (isLoading) {
      return <Suspense />;
    }

    const parsedSearch = qs.parse(location.search);
    const hasError = !!parsedSearch?.error;

    if (hasError) {
      logout();
      return null;
    }

    const returnTo = (parsedSearch?.returnTo
      || '/') as string;

    history.push(returnTo);
    return null;
  }

  return null;
};

export default CallbackComponent;
