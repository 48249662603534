import * as React from 'react';

import assets from '../../../assets';
import Spinner from '../Spinner';
import styles from './styles.module.scss';

interface ISuspense {
  message?: string;
}

const Suspense: React.FC<ISuspense> = (props) => {
  const {
    message,
  } = props;

  return (
    <div className={styles.suspense}>
      <div className={styles.suspenseLogo}>
        <img src={assets.logo.monogramLoRes} alt="Loading content" />
      </div>
      <div className={styles.suspenseContainer}>
        <div className={styles.suspenseSpinner}>
          <Spinner size={50} />
        </div>
        {
          message && (
            <div className={styles.suspenseMessage}>
              { message }
            </div>
          )
        }
      </div>
    </div>
  );
};

export default Suspense;
