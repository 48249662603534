/* eslint-disable camelcase */
export interface IAdAccount {
  currency: string;
  id: string;
  accountId: string;
  account_id?: string;
  name: string;
}
export interface IOrganization {
  logo?: string,
  name: string,
  accountId: string,
  guardian: any,
  account: any,
  fbAccounts?: IAdAccount[];
  googleAccounts?: IAdAccount[];
}

export const OrganizationRequiredFields = [
  'name',
  'accountId',
  'guardian',
];
