/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button, Checkbox, Empty, message, Modal,
} from 'antd';
import Search from 'antd/lib/input/Search';
import cx from 'classnames';
import {
  filter, get, includes, isEmpty, map, size, some,
} from 'lodash';

import { DeleteOutlined } from '@ant-design/icons';

import assets from '../../../../assets';
import fuzzySearch from '../../../../common/fuzzySearch';
import useSearchQuery from '../../../../common/hooks/utils/useSearchQuery';
import { publicHttp } from '../../../../common/http';
import { useOrganizationSave } from '../../../../providers/OrganizationSave';
import { IAdAccount } from '../../../../providers/OrganizationSave/model';
import { useGoogle } from '../../../../providers/Platform/Google';
import DataProviderWrapper from '../../DataProviderWrapper';
import commonStyles from '../../styles.module.scss';

const GoogleProviderComponent: FC = () => {
  const {
    signIn,
  } = useGoogle();

  const {
    organizationData,
    organization,
    addGoogleAdAccounts,
    removeGoogleAdAccount,
  } = useOrganizationSave();

  const {
    current: searchQuery,
    setValue,
  } = useSearchQuery();

  const [searchValue, setSearchValue] = useState('');

  const [isProviderOpen, setIsProviderOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>([]);
  const [availableAdAccounts, setAvailableAdAccounts] = useState([] as any[]);

  const originalAccounts = useMemo(() => get(organization, 'googleAccounts', []), [organization]);
  const googleExistingAccounts = useMemo(() => get(organizationData, 'googleAccounts', []), [organizationData]);
  const googleExisitingIds = useMemo(() => map(
    googleExistingAccounts,
    'accountId',
  ), [googleExistingAccounts]);

  const { t } = useTranslation();

  useEffect(() => {
    if (!isEmpty(googleExistingAccounts)) {
      setIsProviderOpen(true);
    }
  }, [googleExistingAccounts]);

  const renderAccount = (account: IAdAccount) => {
    const isNew = !some(originalAccounts, (acc: any) => {
      const accId = acc.accountId || '';
      const accountId = account.accountId || '';

      return accId === accountId;
    });

    return (
      <div
        className={cx(
          commonStyles.adAccount,
          {
            [commonStyles.newAdAccount]: isNew,
          },
        )}
      >
        <div className={commonStyles.adAccountInformation}>
          <div className={commonStyles.adAccountName}>
            {account.name}
          </div>
          <div className={commonStyles.adAccountId}>
            {account.accountId}
          </div>
        </div>
        <div className={commonStyles.adAccountRemove}>
          <Button
            type="ghost"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={() => removeGoogleAdAccount(account.id)}
          />
        </div>
      </div>
    );
  };

  const renderEmpty = () => (
    <div className={commonStyles.adAccountsEmpty}>
      <Empty />
    </div>
  );

  const filteredAdAccounts = useMemo(() => {
    if (!searchValue) {
      return availableAdAccounts;
    }

    const fuzzySearchResults = fuzzySearch(
      searchValue,
      availableAdAccounts,
      ['name', 'accountId'],
    );

    return map(fuzzySearchResults, 'item');
  }, [searchValue, availableAdAccounts]);

  const renderAvailableOptions = () => {
    const options = map(filteredAdAccounts, (adAccount) => ({
      label: `${adAccount.name} [${adAccount.accountId}]`,
      value: adAccount.accountId,
    }));

    return (

      <Checkbox.Group
        options={options}
        value={selectedAccountIds}
        onChange={(values) => setSelectedAccountIds(values as string[])}
        className={commonStyles.checkboxGroup}
      />
    );
  };

  const addAdAccounts = () => {
    if (!isEmpty(selectedAccountIds)) {
      const selectedAdAccounts = filter(
        availableAdAccounts,
        (adAccount) => includes(selectedAccountIds, adAccount.accountId),
      );

      addGoogleAdAccounts(selectedAdAccounts);
      setSelectedAccountIds([]);
    }

    setShowModal(false);
  };

  const renderModal = () => (
    <Modal
      title={t('fb.init.choose.title')}
      visible={showModal}
      centered
      onCancel={() => setShowModal(false)}
      onOk={addAdAccounts}
    >
      <Search
        className={commonStyles.searchBar}
        placeholder={t('fb.init.search')}
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        allowClear
      />
      {
        isEmpty(filteredAdAccounts)
          ? renderEmpty()
          : renderAvailableOptions()
      }
    </Modal>
  );

  const handleAddClick = async () => {
    setIsFetching(true);

    try {
      await signIn();
    } catch (err: any) {
      message.error(err);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (!showModal) {
      setSelectedAccountIds([]);
      setSearchValue('');
    }
  }, [showModal]);

  const handleAuthSuccess = async (authID: string) => {
    setValue('authID', undefined);
    setIsFetching(true);

    try {
      const customersResponse = await publicHttp('/public/google/customers', {
        params: {
          authID,
        },
      });

      const { customers } = customersResponse.data.payload;

      const filteredCustomers = filter(
        customers,
        (customer) => !includes(googleExisitingIds, customer.accountId),
      );

      setAvailableAdAccounts(filteredCustomers);

      setShowModal(true);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (searchQuery?.authID) {
      handleAuthSuccess(searchQuery?.authID as string);
    }
  }, [searchQuery]);

  return (
    <>
      {renderModal()}
      <DataProviderWrapper
        label="Google Ads"
        provider="google"
        isOpen={isProviderOpen}
        onToggleOpen={() => setIsProviderOpen(!isProviderOpen)}
        icon={<img src={assets.thirdParty.google.color} alt="Google Icon" />}
        onClickAdd={handleAddClick}
        isAddLoading={isFetching}
        counts={size(googleExistingAccounts)}
      >
        {
          !isEmpty(googleExistingAccounts)
            ? map(googleExistingAccounts, renderAccount)
            : renderEmpty()
        }
      </DataProviderWrapper>
    </>
  );
};

export default GoogleProviderComponent;
