/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import cx from 'classnames';
import {
  each, isString, orderBy, sortBy,
} from 'lodash';
import {
  Bar,
  BarChart,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { ArrowDownOutlined } from '@ant-design/icons';

import { titleCase } from '../../../../common/cases';
import { IMetricDataElement } from '../../../../common/hooks/useCampaignBreakdowns';
import styles from './styles.module.scss';

const { useState } = React;

interface IMetricProps {
  metricInformation: Record<string, any>;
  data: IMetricDataElement;
}

const millionMetrics = ['cpm', 'cpc', 'cpl', 'ctr', 'cpe', 'cpp'];

const MetricComponent: React.FC<IMetricProps> = (props) => {
  const {
    metricInformation,
    data,
  } = props;

  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(true);

  const isMillion = millionMetrics.includes(metricInformation.field);

  const wrapChart = (
    element: React.ReactElement,
    label: string,
    fullWidth?: boolean,
    extraHeight?: boolean,
  ) => (
    <div
      className={cx(
        styles.chart,
        {
          [styles.fullWidth]: fullWidth,
          [styles.extraHeight]: extraHeight,
        },
      )}
    >
      <div className={styles.chartLabel}>
        {t(label)}
      </div>
      <div className={styles.chartContent}>
        <ResponsiveContainer
          width="100%"
          height="100%"
        >
          {element}
        </ResponsiveContainer>
      </div>
    </div>
  );

  const renderLineChart = (
    blockData: Record<string, number>,
    formatTime?: boolean,
    sort?: boolean,
  ) => {
    let parsedBlockData: Record<string, any>[] = [];

    each(blockData, (value, key) => {
      if (!value) {
        return;
      }

      let formattedKey = titleCase(key);

      if (formatTime) {
        const timeSplitted = formattedKey.split(' - ');
        formattedKey = `${timeSplitted[0].substring(0, 5)} - ${timeSplitted[1].substring(0, 5)}`;
      }

      let calcValue = value;

      if (!isMillion) {
        calcValue = Math.round(calcValue);
      }

      parsedBlockData.push({
        name: t(`metrics.keys.${key}`, {
          defaultValue: formattedKey,
        }),
        value: calcValue,
      });
    });

    parsedBlockData = orderBy(parsedBlockData, 'name', 'asc');

    if (sort) {
      parsedBlockData = sortBy(parsedBlockData, 'value').reverse();
    }

    const element = (
      <LineChart
        data={parsedBlockData}
        margin={{
          top: 0, right: 10, left: 0, bottom: 50,
        }}
      >
        <XAxis
          dataKey="name"
          interval={0}
          angle={-90}
          textAnchor="end"
        />
        <YAxis width={43} />
        <Line dataKey="value" fill="#8884d8" />
        <Tooltip />
      </LineChart>
    );

    return element;
  };

  const renderBarChart = (blockData: Record<string, number>, sort?: boolean) => {
    let parsedBlockData: Record<string, any>[] = [];

    each(blockData, (value, key) => {
      if (!value) {
        return;
      }

      const calcValue = value;

      parsedBlockData.push({
        name: t(`metrics.keys.${key}`, {
          defaultValue: titleCase(key),
        }),
        value: calcValue,
      });
    });

    if (sort) {
      parsedBlockData = sortBy(parsedBlockData, 'value').reverse();
    }

    const element = (
      <BarChart
        data={parsedBlockData}
        margin={{
          top: 0, right: 10, left: 0, bottom: 50,
        }}
      >
        <XAxis
          dataKey="name"
          interval={0}
          angle={-90}
          textAnchor="end"
        />
        <YAxis width={43} />
        <Bar dataKey="value" fill="#8884d8" />
        <Tooltip />
      </BarChart>
    );

    return element;
  };

  const renderBarChartVertical = (
    blockData: Record<string, number>,
    sort?: boolean,
  ) => {
    let parsedBlockData: Record<string, any>[] = [];

    const tickFormatter = (value: string) => {
      const limit = 7;

      if (!value || !isString(value)) {
        return value;
      }

      return (value.length < limit)
        ? value
        : `${value?.substring(0, limit)}...`;
    };

    const mult = 1;

    each(blockData, (value, key) => {
      if (!value) {
        return;
      }

      let calcValue = value;

      if (isMillion) {
        calcValue *= mult;
      }

      parsedBlockData.push({
        name: t(`metrics.keys.${key}`, {
          defaultValue: `${titleCase(key)}`,
        }),
        value: calcValue,
      });
    });

    if (sort) {
      parsedBlockData = sortBy(parsedBlockData, 'value').reverse();
    }

    const element = (
      <BarChart
        data={parsedBlockData}
        layout="vertical"
        margin={{
          top: 0, right: 0, left: 10, bottom: 0,
        }}
      >
        <XAxis type="number" />
        <YAxis
          type="category"
          dataKey="name"
          interval={0}
          tickFormatter={tickFormatter}
        />
        <Bar dataKey="value" fill="#8884d8" />
        <Tooltip />
      </BarChart>
    );

    return element;
  };

  const renderDoughnut = (blockData: Record<string, number>) => {
    const parsedBlockData: Record<string, any>[] = [];

    each(blockData, (value, key) => {
      if (!value) {
        return;
      }

      let calcValue = Math.round(value);

      if (isMillion) {
        calcValue /= 100;
      }

      parsedBlockData.push({
        name: t(`metrics.keys.${key}`, {
          defaultValue: key,
        }),
        value: Math.round(calcValue),
      });
    });

    const renderLabel = (entry: any) => `${entry.name} (${entry.value})`;

    const element = (
      <PieChart
        margin={{
          top: 10, right: 10, left: 10, bottom: 10,
        }}
      >
        <Pie
          data={parsedBlockData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          fill="#8884d8"
          innerRadius={30}
          outerRadius={40}
          label={renderLabel}
        />
      </PieChart>
    );

    return element;
  };

  const renderAge = () => {
    const blockData = data.age;

    if (!blockData) {
      return null;
    }

    const element = renderBarChart(blockData);

    return wrapChart(element, 'charts.age');
  };

  const renderGender = () => {
    const blockData = data.gender;

    if (!blockData) {
      return null;
    }

    const element = renderDoughnut(blockData);

    return wrapChart(element, 'charts.gender');
  };

  const renderCountry = () => {
    const blockData = data.country;

    if (!blockData) {
      return null;
    }

    const element = renderBarChartVertical(blockData, true);

    return wrapChart(element, 'charts.country', true);
  };

  const renderRegion = () => {
    const blockData = data.region;

    if (!blockData) {
      return null;
    }

    const element = renderBarChartVertical(blockData, true);

    return wrapChart(element, 'charts.region', true, true);
  };

  const renderDevice = () => {
    const blockData = data.device;

    if (!blockData) {
      return null;
    }

    const element = renderBarChartVertical(blockData, true);

    return wrapChart(element, 'charts.device', true);
  };

  const renderPlatform = () => {
    const blockData = data.platform;

    if (!blockData) {
      return null;
    }

    const element = renderBarChartVertical(blockData, true);

    return wrapChart(element, 'charts.platform');
  };

  const renderPosition = () => {
    const blockData = data.position;

    if (!blockData) {
      return null;
    }

    const element = renderBarChartVertical(blockData, true);

    return wrapChart(element, 'charts.position');
  };

  const renderDates = () => {
    const blockData = data.dates;

    if (!blockData) {
      return null;
    }

    const element = renderLineChart(blockData);

    return wrapChart(element, 'charts.dates', true);
  };

  // const renderHours = () => {
  //   const blockData = data.hourly;

  //   if (!blockData) {
  //     return null;
  //   }

  //   const element = renderLineChart(blockData, true);

  //   return wrapChart(element, 'charts.hours', true);
  // };

  return (
    <div
      className={cx(
        styles.metric,
        {
          [styles.expanded]: isExpanded,
        },
      )}
    >
      <div
        className={styles.metricHeader}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className={styles.metricLabel}>
          {metricInformation.label}
        </div>
        <div
          className={styles.metricToggle}
        >
          <ArrowDownOutlined />
        </div>
      </div>
      <div className={styles.metricContent}>
        {renderAge()}
        {renderGender()}
        {renderDates()}
        {/* {renderHours()} */}
        {renderCountry()}
        {renderRegion()}
        {renderDevice()}
        {renderPlatform()}
        {renderPosition()}
      </div>
    </div>
  );
};

const Metric = React.memo(MetricComponent);

export default Metric;
