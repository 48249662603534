import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import { LockOutlined } from '@ant-design/icons';
import { User } from '@auth0/auth0-spa-js';

import { IField } from '../../common/fields';
import { authorizedHttp } from '../../common/http';
import ModuleModal from '../ModuleModal';
import styles from './styles.module.scss';

const { useMemo, useState } = React;

interface IChangePassword {
  user: User;
  disabled?: boolean;
  onChange?: (data: any) => void;
}

const ChangePasswordComponent: React.FC<IChangePassword> = (props) => {
  const {
    onChange,
    user,
    disabled,
  } = props;

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formContents = useMemo((): IField[] => [
    {
      type: 'new-password',
      name: 'password',
      required: true,
      label: t('fields.newPassword'),
    },
  ], []);

  const handleSubmit = async (values: any) => {
    const request = await authorizedHttp.put(`/user/${user.id || user.user_id}/password`, { password: values.password });
    onChange?.(request.data.payload.account);
    return null;
  };

  return (
    <>
      <Button
        icon={<LockOutlined />}
        className={styles.AddAccountBtn}
        type="ghost"
        disabled={disabled}
        onClick={() => setIsModalOpen(true)}
      >
        {t('actions.changePassword.label')}
      </Button>
      <ModuleModal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formFields={formContents}
        onSubmit={handleSubmit}
        title="actions.changePassword.title"
        replaceTitleMap={{
          name: user.name || '',
        }}
        submitLabel="actions.changePassword.submit"
      />
    </>
  );
};

const ChangePassword = React.memo(ChangePasswordComponent);

export default ChangePassword;
