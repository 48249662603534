import * as React from 'react';

import CampaignsTable from '../../components/CampaignsTable';
import withPlatformLayout from '../../components/HOC/withPlatformLayout';
import ModuleWrapper from '../../components/ModuleWrapper';

const Campaigns = () => (
  <ModuleWrapper
    titleKey="campaigns.pageTitle"
  >
    <CampaignsTable />
  </ModuleWrapper>
);

export default withPlatformLayout(Campaigns);
