import { Method } from 'axios';

import assets from '../assets';

export const statusTransaltionKey = 'status.services.{{key}}';

// eslint-disable-next-line no-shadow
export enum STATUS_SEVERITY_LEVEL {
  NONE = 0,
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
  FATAL = 4,
}

export interface IStatusCheck {
  key: string;
  iconURL: string;
  pingURL: string;
  preferredMethod?: Method;
  severity: STATUS_SEVERITY_LEVEL;
  slaHours?: number;
  statusPage: string;
}

const statusChecksDefinition: IStatusCheck[] = [
  {
    key: 'api',
    iconURL: assets.logo.monogram,
    pingURL: `${process.env.REACT_APP_API_URL}/public` || '',
    severity: STATUS_SEVERITY_LEVEL.FATAL,
    preferredMethod: 'GET',
    slaHours: 12,
    statusPage: '/status',
  },
  {
    key: 'auth0',
    iconURL: assets.thirdParty.auth0.hiRes,
    pingURL: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
    severity: STATUS_SEVERITY_LEVEL.FATAL,
    statusPage: 'https://status.auth0.com',
  },
  {
    key: 'fb',
    iconURL: assets.thirdParty.fb.color.medium,
    pingURL: 'https://graph.facebook.com/v4.0',
    severity: STATUS_SEVERITY_LEVEL.HIGH,
    statusPage: 'https://status.fb.com',

  },
  {
    key: 'twilio',
    iconURL: assets.thirdParty.twilio.medium,
    pingURL: 'https://api.twilio.com',
    severity: STATUS_SEVERITY_LEVEL.MEDIUM,
    statusPage: 'https://status.twilio.com/',

  },
  {
    key: 'mailgun',
    iconURL: assets.thirdParty.mailgun.hiRes,
    pingURL: 'https://api.mailgun.net/',
    severity: STATUS_SEVERITY_LEVEL.LOW,
    statusPage: 'https://status.mailgun.com',
  },
];

export default statusChecksDefinition;
