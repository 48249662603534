import React, { FC } from 'react';

import commonStyles from '../styles.module.scss';
import FBProviderComponent from './FBProvider';
import GoogleProviderComponent from './GoogleProvider';

const OrganizationProvidersInformation: FC = () => (
  <div className={commonStyles.organizationSaveForm}>
    <FBProviderComponent />
    <GoogleProviderComponent />
  </div>
);

export default OrganizationProvidersInformation;
