import * as React from 'react';

import cx from 'classnames';
import { isEmpty } from 'lodash';

import { useReport } from '../../../providers/Platform/Reports/Report';
import EmptyContent from '../../EmptyContent';
import TableReport from '../Table';
import styles from './styles.module.scss';

const LeftPanelComponent: React.FC = () => {
  const {
    report,
    selectedAdAccountIds,
  } = useReport();

  const logoComponent = (() => {
    if (!report?.logo) {
      return null;
    }

    const obj = report.logo;

    const showPoweredBy = !!obj.main && obj.poweredBy;

    const mainLogo = obj.main || obj.poweredBy;

    return (
      <div
        className={cx(
          styles.logos,
          {
            [styles.showPowered]: showPoweredBy,
          },
        )}
      >
        <div className={styles.mainLogo}>
          <div
            className={styles.mainLogoBinder}
            style={{ backgroundImage: `url(${mainLogo})` }}
          />
        </div>
        {
          showPoweredBy && (
            <div className={styles.powerLogo}>
              <div className={styles.poweredBy}>
                Powered By
              </div>
              <div
                className={styles.powerLogoBinder}
                style={{ backgroundImage: `url(${obj.poweredBy})` }}
              />
            </div>
          )
        }
      </div>
    );
  })();

  return (
    <div className={styles.leftPanel}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          { logoComponent }
        </div>
      </div>
      {
        isEmpty(selectedAdAccountIds) || isEmpty(report)
          ? (
            <div className={styles.empty}>
              <EmptyContent label="report.emptyContent" />
            </div>
          )
          : (
            <div className={styles.tableContainer}>
              <TableReport />
            </div>
          )
      }

    </div>
  );
};

const LeftPanel = LeftPanelComponent;

export default LeftPanel;
