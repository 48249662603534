import * as React from 'react';
import { Link } from 'react-router-dom';

import getBrandLogo from '../../common/brandLogo';
import { useAuth } from '../../providers/Global/Auth';
import { customizedClientData, getCustomClientByHost } from '../../utils';
import UserInfoInline from '../UserInfoInline';
import styles from './styles.module.scss';

const { useEffect, useState } = React;

interface ITopbar {

}

const Topbar: React.FC<ITopbar> = () => {
  const {
    user,
    isAuthorized,
  } = useAuth();

  const [brandLogo, setBrandLogo] = useState('');

  const renderLeft = () => (
    <Link to="/">
      <div className={styles.brandLogo}>
        <div style={{ backgroundImage: `url("${brandLogo}")` }} />
      </div>
    </Link>
  );

  const customClient = getCustomClientByHost();

  const fetchSetBrandLogo = async () => {
    if (customClient !== 'velox') {
      setBrandLogo(customizedClientData[customClient].headerLogo);
      return;
    }

    const logo = await getBrandLogo();
    setBrandLogo(logo);
  };

  /** @TODO Fetch on changes */
  useEffect(() => {
    fetchSetBrandLogo();
  }, []);

  const renderRight = () => {
    if (isAuthorized) {
      return (
        <div className={styles.topbarAuthorized}>
          <UserInfoInline user={user} />
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.topbar}>
      <div className={styles.topbarRow}>
        <div className={styles.topbarRowLeft}>
          {renderLeft()}
        </div>
        <div className={styles.topbarRowRight}>
          {renderRight()}
        </div>
      </div>
    </div>
  );
};

export default Topbar;
