import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button, message } from 'antd';
import cx from 'classnames';

import routesPaths from '../../common/routesPaths';
import Spinner from '../../components/Common/Spinner';
import withPlatformLayout from '../../components/HOC/withPlatformLayout';
import ModuleWrapper from '../../components/ModuleWrapper';
import { OrganizationSaveProvider, useOrganizationSave } from '../../providers/OrganizationSave';
import OrganizationBasicInformation from './BasicInformation';
import OrganizationProvidersInformation from './ProvidersInformation';
import styles from './styles.module.scss';

const OrganizationSaveComponent: FC = () => {
  const {
    isMinimumDataSet,
    isUpdate,
    hasDataChanged,
    isSubmitting,
    isFetchingOrganization,
    organization,
    submit,
  } = useOrganizationSave();

  const { t } = useTranslation();

  const titleKey = useMemo(() => (
    isUpdate
      ? 'organization.editTitle'
      : 'organization.addTitle'
  ), [isUpdate]);

  const isDisabled = useMemo(() => (
    !hasDataChanged || !isMinimumDataSet
  ), [hasDataChanged, isMinimumDataSet]);

  const renderContent = () => (
    <>
      <div className={styles.organizationSaveCols}>
        <div className={cx(styles.organizationSaveCol, styles.basicInformation)}>
          <div className={styles.organizationSaveColTitle}>
            {t('modules.organization.save.basicInformation')}
          </div>
          <OrganizationBasicInformation />
        </div>
        <div className={cx(styles.organizationSaveCol, styles.providersInformation)}>
          <div className={styles.organizationSaveColTitle}>
            {t('modules.organization.save.organizationProviders')}
          </div>
          <OrganizationProvidersInformation />
        </div>
      </div>
      <div className={styles.organizationSaveActions}>
        <Button
          type="primary"
          size="large"
          disabled={isDisabled}
          loading={isSubmitting}
          onClick={submit}
        >
          {t('modules.organization.save.saveOrganization')}
        </Button>
      </div>
    </>
  );

  return (
    <ModuleWrapper
      goBackBreadCrumbTitle={t('organizations.pageTitle')}
      titleKey={t(titleKey)}
      translateVariables={{
        organizationName: organization.name,
      }}
    >
      <div className={styles.organizationSavePage}>
        {
          isFetchingOrganization
            ? <Spinner size={40} />
            : renderContent()
        }
      </div>
    </ModuleWrapper>
  );
};

const OrganizationSave: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleError = (errMsg: string) => {
    message.success(errMsg);
  };

  const handleSubmit = () => {
    message.success(t('modules.organization.save.successMessage'));
    history.push(routesPaths.organizations);
  };

  return (
    <OrganizationSaveProvider
      onError={handleError}
      onSave={handleSubmit}
    >
      <OrganizationSaveComponent />
    </OrganizationSaveProvider>
  );
};

export default withPlatformLayout(OrganizationSave);
