import * as React from 'react';
import { useTranslation } from 'react-i18next';

import 'moment/locale/es-mx';

import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import { Locale } from 'antd/lib/locale-provider';
import { keys } from 'lodash';
import moment from 'moment';

import { useAuth } from '../Global/Auth';

const { createContext, useEffect, useState } = React;

// eslint-disable-next-line no-shadow
const SUPPORTED_LOCALES: Record<string, Record<string, unknown>> = {
  en: {
    object: enUS,
    momentLocale: 'en',
  },
  es: {
    object: esES,
    momentLocale: 'es-mx',
  },
};

export const DEFAULT_LOCALE = 'en';
export const SUPPORTED_LOCALES_KEYS = keys(SUPPORTED_LOCALES);

interface IAntdCustomContext {
  locale: string;
  changeLocale: (locale: string) => void;
  momentLocale: string;
}

const AntdCustomContext = createContext<IAntdCustomContext>({} as IAntdCustomContext);

interface IAntdCustomProviderProps {
  children: React.ReactElement;
}

const AntdCustomProvider: React.FC<IAntdCustomProviderProps> = (props) => {
  const {
    children,
  } = props;

  const { i18n } = useTranslation();

  const {
    locale: auth0Locale,
  } = useAuth();

  const [locale, setLocale] = useState<string>(DEFAULT_LOCALE);
  const [localeObject, setLocaleObject] = useState<Locale>(
    SUPPORTED_LOCALES[DEFAULT_LOCALE].object as Locale,
  );

  const trySetLocale = (localeParam: string) => {
    if (SUPPORTED_LOCALES[localeParam]) {
      setLocale(localeParam);
    } else {
      setLocale(DEFAULT_LOCALE);
    }
  };

  const setLocaleFromAuth0 = trySetLocale;

  useEffect(() => {
    setLocaleFromAuth0(auth0Locale);
  }, [auth0Locale]);

  const initLocale = () => {
    const info = SUPPORTED_LOCALES[locale];
    setLocaleObject(info.object as Locale);
    moment.locale(info.momentLocale as string);

    i18n.changeLanguage(locale);
  };

  useEffect(() => {
    initLocale();
  }, [locale]);

  return (
    <AntdCustomContext.Provider
      value={{
        locale,
        changeLocale: trySetLocale,
        momentLocale: (SUPPORTED_LOCALES[locale].momentLocale as string),
      }}
    >
      <ConfigProvider locale={localeObject}>
        { children }
      </ConfigProvider>
    </AntdCustomContext.Provider>
  );
};

export const platformMoment = moment;

export const usePlatformConfig = () => React.useContext(AntdCustomContext);

export default AntdCustomProvider;
