import check from './lottie/check-1.json';
import error from './lottie/error.json';
import error404 from './lottie/error404.json';
import footerAnimation from './lottie/footerAnimation-1.json';
import loaderReport from './lottie/loaderReport.json';
import loadingSuccess from './lottie/loadingSuccessCustom.json';
import spinner from './lottie/spinner.json';

const lottieAssets: Record<string, Record<string, unknown>> = {
  footerAnimation,
  spinner,
  check,
  error,
  error404,
  loadingSuccess,
  loaderReport,
};

export default lottieAssets;
