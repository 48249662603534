import * as React from 'react';

import cx from 'classnames';

import lottieAssets from '../../assets/lottie';
import { useReport } from '../../providers/Platform/Reports/Report';
import LottieCommon from '../Common/Lottie/Common';
import LeftPanel from './Left';
import RightPanel from './Right';
import styles from './styles.module.scss';
import Topbar from './Topbar';

const ReportComponent: React.FC = () => {
  const {
    isLoadingClient,
    isLoadingReport,
    isPublic,
  } = useReport();

  const renderSuspense = (isRefresh?: boolean) => (
    <div className={
      cx(
        styles.reportSuspense,
        {
          [styles.suspenseLight]: isRefresh,
        },
      )
    }
    >
      <div className={styles.reportSpinner}>
        <LottieCommon
          width={100}
          height={100}
          animationData={lottieAssets.loaderReport}
        />
      </div>
    </div>
  );

  const reportContent = (
    <>
      <div className={styles.topbar}>
        <Topbar />
      </div>
      <div className={styles.cols}>
        <div
          className={cx(
            styles.reportCol,
            styles.reportColLeft,
          )}
        >
          <LeftPanel />
        </div>
        <RightPanel />
        {
          isLoadingReport && (renderSuspense(true))
        }
      </div>
    </>
  );

  return (
    <div
      className={cx(
        styles.report,
        {
          [styles.isPrivate]: !isPublic,
        },
      )}
    >
      {
        isLoadingClient
          ? renderSuspense()
          : reportContent
      }
    </div>
  );
};

const Report = React.memo(ReportComponent);

export default Report;
