import {
  difference, filter, includes, isEmpty, isUndefined, size,
} from 'lodash';

import { authorizedHttp } from './http';
import logger from './logger';

const permissions = {
  users: {
    minimum: 'users:all',
    all: 'users:all',
    isMe: 'isMe',
    notIsMe: 'notIsMe',
  },
  markup: {
    minimum: 'markup:all',
    all: 'markup:all',
  },
  accounts: {
    minimum: 'account:all',
    all: 'account:all',
    single: 'account:single',
  },
  clients: {
    minimum: 'client:all',
    all: 'client:all',
    single: 'client:single',
  },
  organizations: {
    minimum: 'organization:all',
    all: 'organization:all',
    single: 'organization:single',
  },
  roles: {
    all: 'permissions:all',
  },
};

export interface IOwnEntity {
  entity: string;
  value: string;
  returnEntity?: boolean;
}
export interface IValidatePermissions {
  authedPermissions: string[];
  testPermissions: string | string[];
  ownEntity?: IOwnEntity;
  skipEntity?: boolean
}

/** Special permissions that should be validated on case */
export const INNER_PERMISSIONS = [
  permissions.users.isMe,
  permissions.users.notIsMe,
];

export const validatePermissions = async (args: IValidatePermissions) => {
  const {
    authedPermissions,
    testPermissions,
    ownEntity,
    skipEntity = true,
  } = args;

  const testPermissionsTmp = typeof testPermissions === 'string'
    ? [testPermissions]
    : testPermissions;

  const filteredPermissions = filter(testPermissionsTmp, (permission: string) => (
    !includes(INNER_PERMISSIONS, permission)
  ));

  if (isEmpty(authedPermissions)) {
    return false;
  }

  const areValid = size(difference(filteredPermissions, authedPermissions)) === 0;

  if (!ownEntity || !areValid || skipEntity) {
    /** Prevent a call to the API */
    logger.debug('Skipped API permission check.');
    return areValid;
  }

  try {
    const ownsResponse = await authorizedHttp.get('/user/own-entity', {
      params: {
        entity: ownEntity.entity,
        value: ownEntity.value,
        returnEntity: ownEntity.returnEntity,
      },
    });

    if (ownsResponse?.data?.payload && areValid) {
      if (!isUndefined(ownsResponse.data.payload.valid)) {
        return ownsResponse.data.payload.valid;
      }

      if (!isUndefined(ownsResponse.data.payload.entity)) {
        return ownsResponse.data.payload.entity;
      }
    }

    return false;
  } catch (err) {
    logger.error('Error while checking entity own.');
    return false;
  }
};

export interface IRole {
  name: string;
  color: string;
  labelPath: string;
  descriptionPath: string;
  minPermissions: string;
}

export const roleMap: Record<string, IRole> = {
  'master-admin': {
    name: 'master-admin',
    color: '#861A6E',
    labelPath: 'roles.master.title',
    descriptionPath: 'roles.master.description',
    minPermissions: 'account:all',
  },
  'account-admin': {
    name: 'account-admin',
    color: '#2F1A86',
    labelPath: 'roles.account.title',
    descriptionPath: 'roles.account.description',
    minPermissions: 'organization:all',
  },
  'organization-admin': {
    name: 'organization-admin',
    color: '#FEB23F',
    labelPath: 'roles.organization.title',
    descriptionPath: 'roles.organization.description',
    minPermissions: 'client:all',
  },
  'client-admin': {
    name: 'client-admin',
    color: '#2FE78E',
    labelPath: 'roles.client.title',
    descriptionPath: 'roles.client.description',
    minPermissions: 'users:all',
  },
  /** We treat public as default */
  public: {
    name: 'public',
    color: '#A2BEBD',
    labelPath: 'roles.public.title',
    descriptionPath: 'roles.public.description',
    minPermissions: '*',
  },
};

export const rolesScore: Record<string, number> = {
  'master-admin': 0,
  'account-admin': 1,
  'organization-admin': 2,
  'client-admin': 3,
  public: 98,
  none: 99,
};

export default permissions;
