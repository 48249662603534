import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Breadcrumb } from 'antd';
import { isFunction } from 'lodash';

import styles from './styles.module.scss';

const { useMemo } = React;

interface IModuleWrapper {
  titleKey?: string;
  translateVariables?: Record<string, string>;
  headerLogo?: string;
  children: React.ReactNode;
  headerRender?: () => React.ReactElement;
  goBackBreadCrumbTitle?: string;
}

const ModuleWrapper: React.FC<IModuleWrapper> = (props) => {
  const {
    children,
    titleKey,
    headerRender,
    headerLogo,
    translateVariables,
    goBackBreadCrumbTitle,
  } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const appendHeader = useMemo(() => {
    if (isFunction(headerRender)) {
      return headerRender();
    }
    return null;
  }, [headerRender]);

  const renderBreadCrumbs = () => (
    <div className={styles.breadcrumb}>
      <Breadcrumb>
        <Breadcrumb.Item
          href="#"
          onClick={() => history.goBack()}
        >
          {t(`modules.${goBackBreadCrumbTitle}`)}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {t(`modules.${titleKey}`, translateVariables)}
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  return (
    <div className={styles.moduleWrapper}>
      {
        titleKey && (
          <div className={styles.moduleHeader}>
            {
              goBackBreadCrumbTitle && renderBreadCrumbs()
            }
            {
              headerLogo && (
                <div className={styles.moduleLogo}>
                  <div
                    className={styles.moduleLogoSrc}
                    style={{ backgroundImage: `url('${headerLogo}')` }}
                  />
                </div>
              )
            }
            <div className={styles.moduleTitle}>
              {t(`modules.${titleKey}`, translateVariables)}
            </div>
            <div className={styles.moduleHeaderAppend}>
              { appendHeader }
            </div>
          </div>
        )
      }
      <div className={styles.moduleContent}>
        { children }
      </div>
    </div>
  );
};

export default ModuleWrapper;
