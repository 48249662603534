import * as React from 'react';
import { useTranslation } from 'react-i18next';

import cx from 'classnames';
import {
  find,
  isUndefined, map, remove, size,
} from 'lodash';

import lottieAssets from '../../../assets/lottie';
import statusChecksDefinition, { IStatusCheck } from '../../../common/statusChecksDef';
import LottieCommon from '../Lottie/Common';
import StatusCheck from './statusCheck';
import styles from './styles.module.scss';

const { useMemo, useState } = React;

interface IStatusChecks {}

interface IDefObject {
  key: IStatusCheck['key'],
  severity: IStatusCheck['severity'],
  isHealthy: boolean | undefined;
  isLoading: boolean;
}

const StatusChecksComponent: React.FC<IStatusChecks> = () => {
  const { t } = useTranslation();
  const [statusState, setStatusState] = useState<IDefObject[]>([]);

  const globalDefinition = useMemo(() => {
    const expectedChecks = size(statusChecksDefinition);
    const currentChecks = size(statusState);

    const definition = {
      isLoading: true,
      isHealthy: true,
      title: 'status.global.loading.title',
    };

    if (expectedChecks !== currentChecks) {
      return definition;
    }

    const isSomeCheckLoading = !!find(statusState, (check) => check.isLoading);
    const isSomeCheckUnhealthy = !!find(
      statusState,
      (check) => !isUndefined(check.isHealthy) && !check.isHealthy,
    );

    definition.isLoading = isSomeCheckLoading;
    definition.isHealthy = !isSomeCheckUnhealthy;

    if (definition.isLoading) {
      definition.title = 'status.global.loading.title';
    } else if (definition.isHealthy) {
      definition.title = 'status.global.ok.title';
    } else {
      definition.title = 'status.global.error.title';
    }

    return definition;
  }, [statusState]);

  const setStatusCheck = (definintion: IStatusCheck, isHealthy: boolean | undefined) => {
    const newState = [...statusState];
    remove(newState, (def: IDefObject) => def.key === definintion.key);

    const defObject: IDefObject = {
      key: definintion.key,
      severity: definintion.severity,
      isHealthy,
      isLoading: isUndefined(isHealthy),
    };

    newState.push(defObject);
    setStatusState(newState);
  };

  const renderGlobalDefinition = () => {
    const { isLoading, isHealthy } = globalDefinition;

    const iconAnimationData = (() => {
      if (isLoading) {
        return lottieAssets.spinner;
      }
      if (isHealthy) {
        return lottieAssets.check;
      }
      return lottieAssets.error;
    })();

    return (
      <div className={cx(
        styles.statusChecksGlobal,
        {
          [styles.isLoading]: isLoading,
          [styles.unHealthy]: !isHealthy && !isLoading,
          [styles.healthy]: isHealthy && !isLoading,
        },
      )}
      >
        <div className={styles.statusChecksGlobalContainer}>
          <div className={styles.statusChecksGlobalTitle}>
            {t(globalDefinition.title)}
          </div>
        </div>
        <div className={styles.statusChecksGlobalIcon}>
          <LottieCommon
            animationData={iconAnimationData}
            width={globalDefinition.isLoading ? 40 : 60}
            height={globalDefinition.isLoading ? 40 : 60}
          />
        </div>

      </div>
    );
  };

  return (
    <div className={styles.statusChecks}>
      <div className={styles.statusChecksTitle}>
        {t('status.title')}
      </div>
      <div className={styles.statusChecksWrapper}>
        { renderGlobalDefinition() }
        {map(statusChecksDefinition, (definition) => (
          <StatusCheck
            onPerformCheck={(isHealthy) => setStatusCheck(definition, isHealthy)}
            key={definition.key}
            definition={definition}
          />
        ))}
      </div>
    </div>
  );
};

const StatusChecks = React.memo(StatusChecksComponent);

export default StatusChecks;
