import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Select } from 'antd';
import {
  filter, includes, isArray, map,
} from 'lodash';

import { User } from '@auth0/auth0-spa-js';

import fuzzySearch from '../../common/fuzzySearch';
import { authorizedHttp } from '../../common/http';
import logger from '../../common/logger';
import { useAuth } from '../../providers/Global/Auth';
import AddUserButton from '../AddUserButton';
import Spinner from '../Common/Spinner';
import styles from './userPicker.module.scss';

const { Option } = Select;

const { useEffect, useMemo, useState } = React;

interface IUserPicker {
  value?: string | string[];
  onChange?: (value: string | string[]) => void;
  excludeIds?: string[];
  multiple?: boolean;
  hideSelfAssign?: boolean;
}

const UserPicker: React.FC<IUserPicker> = (props) => {
  const {
    value,
    onChange,
    multiple,
    hideSelfAssign,
    excludeIds,
  } = props;

  const { t } = useTranslation();

  const {
    auth0Id,
  } = useAuth();

  const [users, setUsers] = useState<User[]>([]);
  const [sourceUsers, setSourceUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const options = map(users, (user: User) => (
    <Option value={user.user_id} key={user.user_id}>
      {`${user.name} [${user.email}]`}
    </Option>
  ));

  const fetchAndSet = async (forceCache?: boolean) => {
    setIsLoading(true);

    try {
      const response = await authorizedHttp.get('/users', {
        params: {
          limit: 1000,
          excludeMe: hideSelfAssign ? 1 : 0,
          t: (forceCache && +Date.now()),
        },
      });

      setUsers(response.data.payload.users);
      setSourceUsers(response.data.payload.users);
    } catch (err: any) {
      logger.error(`Error while fetching users: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (searchValue: string): User[] => {
    if (!searchValue) {
      setUsers(sourceUsers);
      return sourceUsers;
    }

    const newUsersRes = fuzzySearch(searchValue, sourceUsers);
    const newUsers = map(newUsersRes, 'item') as User[];

    const filteredUsers = filter(newUsers, (user) => !includes(excludeIds, user.user_id));

    setUsers(filteredUsers);

    return filteredUsers;
  };

  useEffect(() => {
    fetchAndSet();
  }, []);

  const handleAssignMyself = () => {
    onChange?.(auth0Id);
  };

  const handleChange = (valueArg: string | string[]) => {
    let newValue = valueArg;

    if (multiple && !isArray(newValue)) {
      newValue = [valueArg as string];
    }

    onChange?.(newValue);
  };

  const handleAddUser = async (auth0IdArg: string) => {
    await fetchAndSet(true);
    const currentValue = (value || []) as string[];
    handleChange([...currentValue, auth0IdArg]);
  };

  const selectComponent = useMemo(() => (
    <Select
      showSearch
      className={styles.userPickerSelect}
      value={value}
      mode={multiple ? 'multiple' : undefined}
      onChange={handleChange}
      onSearch={handleSearch}
      filterOption={false}
      placeholder={t(`fields.pickUser${multiple ? 's' : ''}.placeholder`)}
    >
      { options }
    </Select>
  ), [value, options]);

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <div className={styles.userPickerSpinner}>
          <Spinner />
        </div>
      );
    }

    return (
      <div className={styles.userPickerContainer}>
        {selectComponent}
        <div className={styles.userPickerBtns}>
          <AddUserButton onSubmit={handleAddUser} />
          {
            !hideSelfAssign && (
              <Button
                type="ghost"
                className={styles.btnAssignMyself}
                onClick={handleAssignMyself}
              >
                {t('actions.assignMyself.label')}
              </Button>
            )
          }
        </div>
      </div>
    );
  }, [value, options]);

  return (
    <div className={styles.userPicker}>
      {content}
    </div>
  );
};

export default UserPicker;
