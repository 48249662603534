import { authorizedHttp } from './http';
import logger from './logger';

// eslint-disable-next-line import/prefer-default-export
export const validateEmail = async (
  email: string,
  userId?: string,
  validateCurrent?: boolean,
): Promise<boolean> => {
  try {
    const response = await authorizedHttp.get('/user/emailAvailability', {
      params: {
        email,
        userId,
        validateCurrent: !!validateCurrent,
      },
    });

    return response?.data?.payload?.isAvailable;
  } catch (err) {
    logger.error('Error while validating email.');
    return false;
  }
};
