import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { Button, Divider, Popover } from 'antd';
import cx from 'classnames';

import { LogoutOutlined } from '@ant-design/icons';
import { User } from '@auth0/auth0-spa-js';

import routesPaths from '../../common/routesPaths';
import { useAuth } from '../../providers/Global/Auth';
import UserAvatar from '../UserAvatar';
import styles from './styles.module.scss';

const { useEffect, useState } = React;

interface IUserInfoInline {
  user?: User
}

const UserInfoInline: React.FC<IUserInfoInline> = (props) => {
  const {
    user: propsUser,
  } = props;

  const history = useHistory();
  const [user, setUser] = useState<User>();

  const { t } = useTranslation();
  const {
    logout,
    user: contextUser,
  } = useAuth();

  useEffect(() => {
    setUser(propsUser || contextUser);
  }, [propsUser, contextUser]);

  if (!user) {
    return null;
  }

  const userInfoComponent = (() => (
    <Link to={routesPaths.userMe}>
      <div className={styles.userInfoInline}>
        <div className={styles.userName}>
          { user.name }
        </div>
        <div className={styles.userAvatar}>
          <UserAvatar profilePicture={user?.picture} onUpload={setUser} />
        </div>
      </div>
    </Link>
  ))();

  const isMyProfile = history.location.pathname === routesPaths.userMe;

  const userActions = (() => (
    <div className={styles.userActions}>
      <Button
        type="text"
        className={cx(
          styles.userActionBtn,
          {
            [styles.active]: isMyProfile,
          },
        )}
        onClick={() => history.push(routesPaths.userMe)}
      >
        {t('topbar.user.profile')}
      </Button>
      <Divider className={styles.userActionsDivider} />
      <Button
        icon={<LogoutOutlined />}
        type="text"
        className={styles.userActionBtn}
        onClick={logout}
      >
        {t('topbar.user.logout')}
      </Button>
    </div>
  ))();

  return (
    <Popover
      overlayClassName={styles.popover}
      content={userActions}
    >
      {userInfoComponent}
    </Popover>
  );
};

export default UserInfoInline;
