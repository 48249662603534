import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from 'antd';
import cx from 'classnames';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { useAuth } from '../../providers/Global/Auth';
import Sidebar from '../Sidebar';
import Topbar from '../Topbar';
import styles from './styles.module.scss';

const { useMemo, useState } = React;

interface IPlatformWrapper {
  children: React.ReactNode;
}

const PlatformWrapper: React.FC<IPlatformWrapper> = (props) => {
  const {
    isAuthorized,
  } = useAuth();

  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  const { children } = props;

  const location = useLocation();

  const shouldDisplaySidebar = useMemo(() => isAuthorized, [isAuthorized]);

  const sidebar = useMemo(() => {
    if (shouldDisplaySidebar) {
      return (
        <div className={styles.platformSidebar}>
          <Sidebar expanded={isSidebarExpanded} />
        </div>
      );
    }

    return null;
  }, [shouldDisplaySidebar, isSidebarExpanded, location]);

  return (
    <div className={cx(
      styles.platformWrapper,
      {
        [styles.sidebarExtended]: isSidebarExpanded,
      },
    )}
    >
      <div className={styles.platformContainer}>
        { sidebar }
        <div className={styles.platformContent}>
          <div className={styles.platformTopbar}>
            <Topbar />
          </div>
          <div className={styles.platformChildren}>
            <div className={styles.platformContentWrapper}>
              {children}
            </div>
            <>
              {
                shouldDisplaySidebar && (
                  <div className={styles.platformSidebarToggle}>
                    <Button
                      className={styles.platformSidebarToggleBtn}
                      onClick={() => setIsSidebarExpanded(!isSidebarExpanded)}
                      icon={isSidebarExpanded ? <LeftOutlined /> : <RightOutlined />}
                    />
                  </div>
                )
              }
            </>

          </div>
        </div>
      </div>
    </div>
  );
};

export default PlatformWrapper;
