import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { isUndefined } from 'lodash';
import qs, { ParsedQuery } from 'query-string';

const { useEffect, useState } = React;

type TParsedQuery = ParsedQuery<string>;

interface ISearchQuery {
  current: TParsedQuery,
  setValue: (key: string, value: unknown) => void,
  setValues: (valuesObject: Record<string, any>, reset?: boolean) => void,
}

const useSearchQuery = (): ISearchQuery => {
  const [current, setCurrent] = useState<TParsedQuery>({});

  const history = useHistory();
  const { location } = history;

  const handleLocationSearchChange = () => {
    const { search } = location || {};
    const filteredSearch = search.replace('?', '');

    const parsed = qs.parse(filteredSearch);

    setCurrent(parsed);
  };

  const setValue = (key: string, value: any) => {
    const currentQS = qs.parse(location.search.replace('?', ''));

    const newCurrent = { ...currentQS };

    if (value || isUndefined(newCurrent[key])) {
      newCurrent[key] = value;
    } else {
      delete newCurrent[key];
    }

    const newPath = qs.stringifyUrl({
      url: location.pathname,
      query: newCurrent,
    });

    history.push(newPath);
  };

  const setValues = (valuesObject: Record<string, any>, reset?: boolean) => {
    const currentQS = qs.parse(location.search.replace('?', ''));

    const newCurrent = reset
      ? valuesObject
      : {
        ...currentQS,
        ...valuesObject,
      };

    const newPath = qs.stringifyUrl({
      url: location.pathname,
      query: newCurrent,
    });

    history.push(newPath);
  };

  useEffect(() => {
    handleLocationSearchChange();
  }, [location?.search]);

  return {
    current,
    setValue,
    setValues,
  };
};

export default useSearchQuery;
