/* eslint-disable import/prefer-default-export */
import React, {
  createContext, FC, useContext, useEffect, useMemo, useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  cloneDeep, every, isEqual, remove,
} from 'lodash';

import { authorizedHttp } from '../../common/http';
import logger from '../../common/logger';
import routesPaths from '../../common/routesPaths';
import { IOrganization, OrganizationRequiredFields } from './model';

interface IOrganizationSaveContext {
  isUpdate: boolean;
  hasDataChanged: boolean;
  isMinimumDataSet: boolean;
  isSubmitting: boolean;
  isFetchingOrganization: boolean;
  organization: IOrganization;
  organizationData: IOrganization;
  setDataValues: (values: Record<string, unknown>) => void;
  removeFBAdAccount: (id: string) => void;
  addFBAdAccounts: (accounts: any[]) => void;
  removeGoogleAdAccount: (id: string) => void;
  addGoogleAdAccounts: (accounts: any[]) => void;
  submit: () => Promise<null>;
}

const OrganizationSaveContext = createContext<IOrganizationSaveContext | null>(null);

interface IProps {
  onSave?: (organization: IOrganization) => void;
  onError?: (message: string) => void;
  children: React.ReactElement | React.ReactNode,
}

export const OrganizationSaveProvider: FC<IProps> = (props) => {
  const {
    children,
    onSave,
    onError,
  } = props;

  const history = useHistory();

  const [isFetchingOrganization, setIsFetchingOrganization] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [organizationData, setOrganizationData] = useState<IOrganization>({} as IOrganization);
  const [organization, setOrganization] = useState<IOrganization>({} as IOrganization);

  const { organizationId } = useParams<{ organizationId: string }>();

  const isUpdate = useMemo(() => organizationId !== 'new', [organizationId]);

  const fetchSetOrganization = async () => {
    setIsFetchingOrganization(true);

    try {
      const params: any = {
        t: +Date.now(),
      };

      const response = await authorizedHttp.get(`/organization/${organizationId}`, {
        params,
      });

      setOrganization(response.data.payload.organization);
    } catch (err) {
      logger.error(`Error while fetching organization: ${JSON.stringify(err)}`);

      // Redirect to organizations, no sense on keeping the user here.
      history.push(routesPaths.organizations);
    } finally {
      setIsFetchingOrganization(false);
    }
  };

  useEffect(() => {
    if (isUpdate && organizationId) {
      fetchSetOrganization();
    } else {
      setOrganization({} as IOrganization);
    }
  }, [isUpdate, organizationId]);

  const defaultData: IOrganization = useMemo(() => {
    if (!isUpdate) {
      return {} as IOrganization;
    }

    return {
      ...organization,
      guardian: organization?.guardian?.auth0Id || '',
      accountId: organization?.account?.id,
    };
  }, [isUpdate, organizationId, organization]);

  const hasDataChanged = useMemo(() => (
    !isEqual(defaultData, organizationData)
  ), [defaultData, organizationData]);

  useEffect(() => {
    setOrganizationData(defaultData);
  }, [defaultData]);

  useEffect(() => () => {
    setOrganization({} as IOrganization);
    setOrganizationData({} as IOrganization);
  }, []);

  const isMinimumDataSet = useMemo(() => (
    every(
      OrganizationRequiredFields,
      (requiredField) => !!(organizationData as unknown as Record<string, unknown>)[requiredField],
    )
  ), [organizationData]);

  const setDataValues = (values: Record<string, unknown>) => {
    setOrganizationData((currentData) => {
      const newData = cloneDeep(currentData);

      return {
        ...newData,
        ...values,
      };
    });
  };

  const removeFBAdAccount = (id: string) => {
    setOrganizationData((currentData) => {
      const newData = cloneDeep(currentData);
      const newFBData = cloneDeep(newData.fbAccounts || []);

      remove(newFBData, ['id', id]);
      return {
        ...newData,
        fbAccounts: newFBData,
      };
    });
  };

  const addFBAdAccounts = (accounts: any[]) => {
    setOrganizationData((currentData) => {
      const newData = cloneDeep(currentData);
      const existingFBData = cloneDeep(newData.fbAccounts || []);

      const newFBData = [
        ...existingFBData,
        ...accounts,
      ];

      return {
        ...newData,
        fbAccounts: newFBData,
      };
    });
  };

  const removeGoogleAdAccount = (id: string) => {
    setOrganizationData((currentData) => {
      const newData = cloneDeep(currentData);
      const newGoogleData = cloneDeep(newData.googleAccounts || []);

      remove(newGoogleData, ['id', id]);
      return {
        ...newData,
        googleAccounts: newGoogleData,
      };
    });
  };

  const addGoogleAdAccounts = (accounts: any[]) => {
    setOrganizationData((currentData) => {
      const newData = cloneDeep(currentData);
      const existingGoogleData = cloneDeep(newData.googleAccounts || []);

      const newGoogleData = [
        ...existingGoogleData,
        ...accounts,
      ];

      return {
        ...newData,
        googleAccounts: newGoogleData,
      };
    });
  };

  const submit = async () => {
    setIsSubmitting(true);

    try {
      if (isUpdate) {
        const request = await authorizedHttp.put(`/organization/${organizationId}`, organizationData);
        onSave?.(request.data.payload.organization);
        return null;
      }

      const request = await authorizedHttp.post('/organizations', organizationData);
      onSave?.(request.data.payload.organization);
      return null;
    } catch (e: any) {
      onError?.(e.message);
    } finally {
      setIsSubmitting(false);
    }

    return null;
  };

  const value = useMemo(() => ({
    isUpdate,
    hasDataChanged,
    isMinimumDataSet,
    isSubmitting,
    isFetchingOrganization,
    organization,
    organizationData,
    setDataValues,
    removeFBAdAccount,
    addFBAdAccounts,
    removeGoogleAdAccount,
    addGoogleAdAccounts,
    submit,
  }), [
    isUpdate,
    isMinimumDataSet,
    hasDataChanged,
    organization,
    isSubmitting,
    isFetchingOrganization,
    organizationData,
  ]);

  return (
    <OrganizationSaveContext.Provider
      value={value}
    >
      {children}
    </OrganizationSaveContext.Provider>
  );
};

export const useOrganizationSave = () => {
  const context = useContext(OrganizationSaveContext);

  if (!context) {
    throw new Error('useOrganizationSave must be used under a provider.');
  }

  return context;
};
