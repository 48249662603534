import axios, { AxiosRequestConfig } from 'axios';

import { LS_KEY_TOKEN } from '../providers/Global/Auth';
import logger from './logger';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const authorizedHttp = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const publicHttp = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: process.env.REACT_APP_API_KEY || '',
  },
});

authorizedHttp.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
  const lsToken = localStorage.getItem(LS_KEY_TOKEN);

  if (!lsToken) {
    logger.error('Invalid token.');
    return config;
  }

  const newConfig: AxiosRequestConfig = {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${lsToken}`,
    },
  };

  return newConfig;
});

export default http;
