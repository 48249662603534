import numeral from 'numeral';

import formatCurrency from './currency';

const getMarkupedValue = (implemented: unknown, markupValue: number, type: string) => {
  const impValue = numeral(implemented).value() || 0;
  const mkValue = numeral(markupValue).value() || 0;

  const isRelative = type === 'RELATIVE';

  if (isRelative) {
    return formatCurrency(impValue * (1 + (mkValue / 100)));
  }

  if (!impValue) {
    return '∞';
  }

  const absoluteVal = (mkValue / impValue) - 1;

  return `${(absoluteVal * 100).toFixed(2)}%`;
};

export default getMarkupedValue;
