import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import {
  each, filter, flattenDeep, get, map,
} from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import slugify from 'slugify';

import colors from '../assets/colors';
import formatCurrency from './currency';
import kpisMap from './kpis';
import kpisActionsMap from './kpisActions';

export interface IXLSXReport {
  from: Date;
  to: Date;
  rawReport: Record<string, any>;
  clientColumns?: string[];
}

const colorToArgb = (hex: string) => {
  const pure = hex.substr(1);
  return `FF${pure}`;
};

const getBase64FromUrl = async (url: string) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

const createAndDownloadReportXLSX = async (args: IXLSXReport) => {
  const {
    from,
    to,
    rawReport,
    clientColumns = [],
  } = args;

  if (
    !rawReport
      || !rawReport?.client
      || !rawReport?.client?.organization
  ) {
    return;
  }

  const { client } = rawReport;
  const { organization } = client;

  const clientLogoB64 = (await getBase64FromUrl(client.logo)) as string;

  const title = (() => {
    let titleExp = client.name;

    titleExp += ` Report: ${moment(from).format('YYYY-MM-DD')}`;
    titleExp += `:${moment(to).format('YYYY-MM-DD')}`;
    titleExp += ` ${organization.name}`;

    return titleExp;
  })();

  const slug = slugify(title);

  const workbook = new ExcelJS.Workbook();
  workbook.creator = organization.name;
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.title = title;

  const clientImgId = workbook.addImage({
    base64: clientLogoB64,
    extension: 'png',
  });

  const commonFirstRowFill: ExcelJS.FillPattern = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: colorToArgb(colors.primary),
    },
  };

  const getKPISColumns = () => {
    const kpiMap = kpisMap();

    return map(kpiMap, (kpi) => ({
      header: kpi.label,
      key: kpi.field,
      width: 30,
      visible: clientColumns.includes(kpi.field),
    }));
  };

  const getKPISActionsColumns = () => {
    const kpiMap = kpisActionsMap();

    return map(kpiMap, (kpi) => ({
      header: kpi.label,
      key: kpi.field,
      width: 20,
      visible: clientColumns.includes(kpi.field),
    }));
  };

  const formatKPI = (value: any, type: string, currency?: string) => {
    let formatted = value;

    if (type === 'numeric') {
      formatted = numeral(formatted).format('0,0');
    } else if (type === 'percentage') {
      formatted = numeral(+formatted / 100).format('0.00%');
    } else if (type === 'currency') {
      formatted = formatCurrency(formatted, currency);
    }

    return formatted;
  };

  const getInsightsKpis = (insights: any[], currency = 'USD') => {
    const kpiMap = kpisMap();
    const output: Record<string, any> = {};

    each(kpiMap, (kpi) => {
      const value = insights?.[kpi.field] || '';
      output[kpi.field] = formatKPI(value, kpi.type, currency);
    });

    return output;
  };

  const getActionsKpis = (actions: any[], currency = 'USD') => {
    const kpiMap = kpisActionsMap();
    const output: Record<string, any> = {};

    each(kpiMap, (kpi) => {
      const value = actions?.[kpi.field] || '';
      output[kpi.field] = formatKPI(value, kpi.type, currency);
    });

    return output;
  };

  /** Welcome */

  const welcomeSheet = workbook.addWorksheet('Welcome', {
    views: [
      {
        showGridLines: false,
      },
    ],
  });

  welcomeSheet.getColumn('B').width = 40;
  welcomeSheet.addImage(clientImgId, 'B4:B7');

  welcomeSheet.getColumn('D').width = 100;

  welcomeSheet.getCell('D5').value = 'Paid Media Report';
  welcomeSheet.getCell('D5').font = {
    name: 'Calibri',
    color: {
      argb: colorToArgb(colors.primary),
    },
    size: 20,
    bold: true,
  };

  welcomeSheet.getCell('D8').value = `From: ${moment(from).format('LLL')}`;
  welcomeSheet.getCell('D8').font = {
    name: 'Calibri',
    color: {
      argb: colorToArgb(colors.primaryLight),
    },
    size: 18,
    bold: true,
  };

  welcomeSheet.getCell('D9').value = `To: ${moment(to).format('LLL')}`;
  welcomeSheet.getCell('D9').font = {
    name: 'Calibri',
    color: {
      argb: colorToArgb(colors.primaryLight),
    },
    size: 18,
    bold: true,
  };

  welcomeSheet.getCell('D11').value = `Powered by ${organization.name}`;
  welcomeSheet.getCell('D11').font = {
    name: 'Calibri',
    color: {
      argb: colorToArgb(colors.neutral),
    },
    size: 16,
    bold: true,
  };

  /** Campaigns */
  const campaignsWS = workbook.addWorksheet('Campaigns');

  const { campaigns } = rawReport;
  const rawCampaigns = filter(campaigns, (c) => !!c?.id);

  campaignsWS.columns = [
    {
      header: 'Campaign ID',
      key: 'id',
      width: 28,
      visible: true,
    },
    {
      header: 'Campaign Name',
      key: 'name',
      width: 70,
      visible: true,
    },
    {
      header: 'Bugdet',
      key: 'budget',
      width: 15,
      visible: clientColumns.includes('budget'),
    },
    {
      header: 'Spend',
      key: 'spend',
      width: 15,
      visible: clientColumns.includes('spend'),
    },
    ...getKPISColumns(),
    ...getKPISActionsColumns(),
  ].filter((x) => x.visible);

  const campaignsData = map((rawCampaigns), (campaign) => ({
    id: campaign?.id,
    name: campaign.name,
    budget: formatCurrency(campaign.lifetime_budget, campaign.currency),
    spend: formatCurrency(campaign.spend || campaign?.insights?.[0]?.spend || 0, campaign.currency),
    ...getInsightsKpis(campaign.insights?.[0], campaign.currency),
    ...getActionsKpis(campaign.insights?.[0]?.actions, campaign.currency),
  }));

  campaignsWS.addRows(campaignsData);

  campaignsWS.getRow(1).fill = commonFirstRowFill;
  campaignsWS.getRow(1).font = {
    name: 'Calibri',
    color: {
      argb: colorToArgb(colors.white),
    },
    bold: true,
  };

  /** Adsets */
  const adsetsWS = workbook.addWorksheet('AdSets');

  const rawAdsets = flattenDeep(map(rawCampaigns, (campaign) => {
    const data = get(campaign, 'adsets');

    each(data, (adset) => {
      // eslint-disable-next-line no-param-reassign
      adset.campaignName = campaign.name;
    });

    return data;
  })).filter((adset) => !!adset?.id);

  adsetsWS.columns = [
    {
      header: 'Campaign Name',
      key: 'campaign',
      width: 70,
      visible: true,
    },
    {
      header: 'Adset ID',
      key: 'id',
      width: 28,
      visible: true,
    },
    {
      header: 'Adset Name',
      key: 'name',
      width: 70,
      visible: true,
    },
    {
      header: 'Spend',
      key: 'spend',
      width: 15,
      visible: clientColumns.includes('spend'),
    },
    ...getKPISColumns(),
    ...getKPISActionsColumns(),
  ].filter((x) => x.visible);

  const adsetsData = map((rawAdsets), (adset) => ({
    id: adset?.id,
    name: adset.name,
    campaign: adset.campaignName,
    budget: formatCurrency(adset.lifetime_budget, adset.currency),
    spend: formatCurrency(adset?.insights?.[0]?.spend, adset.currency),
    ...getInsightsKpis(adset.insights?.[0], adset.currency),
    ...getActionsKpis(adset.insights?.[0]?.actions, adset.currency),
  }));

  adsetsWS.addRows(adsetsData);

  adsetsWS.getRow(1).fill = commonFirstRowFill;
  adsetsWS.getRow(1).font = {
    name: 'Calibri',
    color: {
      argb: colorToArgb(colors.white),
    },
    bold: true,
  };

  /** Ads */
  const adsWS = workbook.addWorksheet('Ads');

  const rawAds = flattenDeep(map(rawAdsets, (adset) => {
    const data = get(adset, 'ads');

    each(data, (ad) => {
      // eslint-disable-next-line no-param-reassign
      ad.adsetName = adset.name;
      // eslint-disable-next-line no-param-reassign
      ad.campaignName = adset.campaignName;
    });

    return data;
  })).filter((ad) => !!ad?.id);

  adsWS.columns = [
    {
      header: 'Campaign Name',
      key: 'campaign',
      width: 70,
      visible: true,
    },
    {
      header: 'Adset Name',
      key: 'adset',
      width: 70,
      visible: true,
    },
    {
      header: 'Ad ID',
      key: 'id',
      width: 28,
      visible: true,
    },
    {
      header: 'Ad Name',
      key: 'name',
      width: 70,
      visible: true,
    },
    {
      header: 'Bugdet',
      key: 'budget',
      width: 15,
      visible: clientColumns.includes('budget'),
    },
    {
      header: 'Spend',
      key: 'spend',
      width: 15,
      visible: clientColumns.includes('spend'),
    },
    ...getKPISColumns(),
    ...getKPISActionsColumns(),
  ].filter((x) => x.visible);

  const adsData = map((rawAds), (ad) => ({
    id: ad?.id,
    name: ad.name,
    adset: ad.adsetName,
    campaign: ad.campaignName,
    budget: formatCurrency(ad.lifetime_budget, ad.currency),
    spend: formatCurrency(ad.insights?.[0]?.spend, ad.currency),
    ...getInsightsKpis(ad.insights?.[0], ad.currency),
    ...getActionsKpis(ad.insights?.[0]?.actions, ad.currency),
  }));

  adsWS.addRows(adsData);

  adsWS.getRow(1).fill = commonFirstRowFill;
  adsWS.getRow(1).font = {
    name: 'Calibri',
    color: {
      argb: 'FFFFFFFF',
    },
    bold: true,
  };

  const buf = await workbook.xlsx.writeBuffer();

  saveAs(new Blob([buf]), `${slug}.xlsx`);
};

export default createAndDownloadReportXLSX;
