import React from 'react';

import './common/styles/AntdOverrides.less';
import './common/styles/global.scss';

import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

import * as FullStory from '@fullstory/browser';

import MainRouterWrapper from './routes/RouterWrapper';

const firebaseConfig = {
  apiKey: 'AIzaSyAUjNm3imGrqZTYHRlFUZg_fGmYQHBouR4',
  authDomain: 'optimal-plate-349012.firebaseapp.com',
  projectId: 'optimal-plate-349012',
  storageBucket: 'optimal-plate-349012.appspot.com',
  messagingSenderId: '1073935421613',
  appId: '1:1073935421613:web:afea6ff53e0a0fcebff624',
  measurementId: 'G-EYQ74BP6DX',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

// Fullstory
if (process.env.NODE_ENV === 'production') {
  FullStory.init({ orgId: '16PP54' });
}

function App() {
  return (
    <MainRouterWrapper />
  );
}

export default App;
