/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  DatePicker,
  Input,
  message,
  notification,
  Radio,
  Select,
  TableProps,
  Tooltip,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import cx from 'classnames';
import {
  debounce,
  findIndex,
  get,
  isEmpty,
  isNil,
  isUndefined,
  map,
  set,
  size,
  uniq,
  upperFirst,
} from 'lodash';
import moment from 'moment';

import {
  CheckCircleFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
  FacebookFilled,
  GoogleOutlined,
  LoadingOutlined,
  StarFilled,
  StarOutlined,
  SyncOutlined,
} from '@ant-design/icons';

import colors from '../../assets/colors';
import formatCurrency from '../../common/currency';
import fuzzySearch from '../../common/fuzzySearch';
import { authorizedHttp } from '../../common/http';
import logger from '../../common/logger';
import { CAMPAIGNS_DEFAULT_LOOKBACK_TIME, FB_MAXIMUM_FETCH } from '../../common/vars';
import EmptyContent from '../EmptyContent';
import VeloxTable from '../Table';
import CurrencyCell from './currencyCellComponent';
import MarkupCell from './markupCellComponent';
import styles from './styles.module.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

const {
  useEffect, useMemo, useState,
} = React;

interface ICampaignsTable {
  tableProps?: TableProps<any>;
}

const CampaignsTableComponent: React.FC<ICampaignsTable> = (props) => {
  const {
    tableProps: tablePropsProp,
  } = props;

  const [isLoadingOrganizations, setIsLoadingOrganizations] = useState(false);
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<any>({});

  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [clients, setClients] = useState<any[]>([]);
  const [selectedClient, setSelectedClient] = useState<string>();

  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(false);
  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [payload, setPayload] = useState<any>({});
  const [isSearching, setIsSearching] = useState(false);
  const [dateRange, setDateRange] = useState<[moment.Moment, moment.Moment]>();
  const [x, setX] = useState(false);

  const [asyncStatus, setAsyncStatus] = useState<Record<string, boolean>>({});

  const { t } = useTranslation();

  const tableProps = useMemo(() => {
    const finalProps = {
      ...tablePropsProp,
    };

    return finalProps;
  }, [tablePropsProp]);

  const data = useMemo(() => {
    const parsedData = map(campaigns, (campaign: any) => ({
      key: campaign.id,
      id: campaign.id,
      name: campaign.name,
      io: campaign.io,
      flagged: campaign.bl?.flagged,
      markupValue: campaign.bl?.markupValue,
      markupTarget: campaign.bl?.markupTarget,
      updatedAt: campaign.bl?.updatedAt,
      createdAt: campaign.raw?.createdAt,
    }));

    return parsedData;
  }, [campaigns, x, asyncStatus]);

  const updateCampaignValue = async (
    id: string,
    field: string,
    value: any,
    overwriteField?: string,
    mutateCampaignCallback?: (campaign: any) => any,
  ) => {
    const newAsyncStatus = { ...asyncStatus };
    newAsyncStatus[id] = true;
    setAsyncStatus(newAsyncStatus);

    try {
      const newCampaigns = [...campaigns];
      const campaignIdx = findIndex(newCampaigns, ['id', id]);
      let newCampaign = newCampaigns[campaignIdx];
      set(newCampaign, overwriteField || field, value);
      set(newCampaign, 'bl.updatedAt', +Date.now());

      if (mutateCampaignCallback) {
        newCampaign = mutateCampaignCallback(newCampaign);
      }

      newCampaigns.splice(campaignIdx, 1, newCampaign);

      setCampaigns(newCampaigns);
      setX(!x);

      await authorizedHttp.post(
        `/organization/${selectedOrganization?.id}/campaigns/${id}`,
        {
          field,
          value,
          clientId: selectedClient,
        },
      );
    } catch {
      message.error(t('feedback.errorUpdatingCampaign'));
    } finally {
      const updatedAsyncStatus = { ...asyncStatus };
      updatedAsyncStatus[id] = false;
      setAsyncStatus(updatedAsyncStatus);
    }
  };

  const renderCurrencyCell = (value: any) => (
    <CurrencyCell
      campaign={value}
      onSave={(id, currency, currencyValue) => {
        updateCampaignValue(id, 'customCurrency', currency, 'bl.customCurrency');
        setTimeout(() => {
          updateCampaignValue(id, 'currencyConversionValue', currencyValue, 'bl.currencyConversionValue');
        }, 500);
      }}
    />
  );

  const filteredCurrencyMap = useMemo(() => uniq(map(campaigns, 'bl.currency')), [campaigns]);

  const renderCurrencyColumn = () => {
    const filters = map(filteredCurrencyMap, (currency) => ({
      text: currency,
      value: currency,
    }));

    return {
      title: t('utils.currency'),
      key: 'currency',
      render: renderCurrencyCell,
      filters,
      width: 210,
      onFilter: (value: any, record: any) => {
        if (value === record?.bl?.currency) {
          return true;
        }
        return false;
      },
    };
  };

  const filteredMarkupTypeMap = useMemo(() => uniq(map(campaigns, 'bl.markupSchema')), [campaigns]);
  const filteredFlaggedTypeMap = useMemo(() => uniq(map(campaigns, 'bl.flagged')), [campaigns]);
  const filteredSourceMap = useMemo(() => uniq(map(campaigns, 'source')), [campaigns]);
  const filteredVisibleMap = [true, false];

  const renderMarkupTypeCell = (row: any) => {
    const options = [
      { label: t('markup.ABSOLUTE'), value: 'ABSOLUTE' },
      { label: t('markup.RELATIVE'), value: 'RELATIVE' },
    ];

    return (
      <Radio.Group
        options={options}
        optionType="button"
        buttonStyle="solid"
        size="small"
        className={styles.radioBtn}
        defaultValue={row?.bl?.markupSchema}
        onChange={(e) => {
          updateCampaignValue(row.id, 'markupSchema', e.target.value, 'bl.markupSchema');
        }}
      />
    );
  };

  const renderMarkupTargetCell = (row: any) => {
    const options = [
      { label: t('markup.IMPLEMENTED'), value: 'IMPLEMENTED' },
      { label: t('markup.SPEND'), value: 'SPEND' },
    ];

    return (
      <Radio.Group
        options={options}
        optionType="button"
        buttonStyle="solid"
        size="small"
        className={styles.radioBtn}
        defaultValue={row?.bl?.markupTarget}
        onChange={(e) => {
          updateCampaignValue(row.id, 'markupTarget', e.target.value, 'bl.markupTarget');
        }}
      />
    );
  };

  const renderMarkupTypeColumn = () => {
    const filters = map(filteredMarkupTypeMap, (type) => ({
      text: t(`markup.${type}`),
      value: type,
    }));

    return {
      title: t('markup.type.label'),
      key: 'markupSchema',
      render: renderMarkupTypeCell,
      filters,
      width: 200,
      onFilter: (value: any, record: any) => {
        if (value === record?.bl?.markupType) {
          return true;
        }
        return false;
      },
    };
  };

  const renderMarkupTargetColumn = () => {
    const filters = map(filteredMarkupTypeMap, (type) => ({
      text: t(`markup.${type}`),
      value: type,
    }));

    return {
      title: t('markup.target.label'),
      key: 'markupTarget',
      render: renderMarkupTargetCell,
      filters,
      width: 180,
      onFilter: (value: any, record: any) => {
        if (value === record?.bl?.markupTarget) {
          return true;
        }
        return false;
      },
    };
  };

  const renderMarkupCell = (row: any) => {
    const isSpend = row.bl?.markupTarget === 'SPEND';

    const implemented = (() => {
      if (!isSpend) {
        return row.implemented;
      }

      return row.raw?.insights?.[0]?.spend;
    })();

    const currentValue = row?.bl?.markupValue;

    return (
      <MarkupCell
        initialValue={currentValue}
        implementedValue={implemented || 0}
        markupSchema={row?.bl?.markupSchema}
        onSave={(markupValue) => updateCampaignValue(
          row.id,
          'markupValue',
          markupValue,
          'bl.markupValue',
        )}
      />
    );
  };

  const renderMarkupColumn = () => (
    {
      title: t('modules.campaigns.table.columns.markup.title'),
      key: 'markup',
      width: 280,
      render: renderMarkupCell,
      sorter: (a: any, b: any) => {
        const aName = a?.bl?.markupValue || 0;
        const bName = b?.bl?.markupValue || 0;

        if (aName > bName) {
          return -1;
        } if (bName > aName) {
          return 1;
        }
        return 0;
      },
    }
  );

  const renderLoadingStatus = (row: any) => {
    const isLoading = !!asyncStatus[row.id];

    return (
      !isLoading
        ? (
          <CheckCircleFilled style={{ color: colors.success }} />
        )
        : (
          <LoadingOutlined style={{ color: colors.success }} />
        )
    );
  };

  const renderVisibilityStatusCell = (row: any) => {
    const isVisible = row?.bl?.visible;
    const currentMarkupValue = get(row, 'bl.markupValue', null);
    const isDisabled = isNil(currentMarkupValue);

    const disabledText = isDisabled
      ? t('feedback.disabledCampaingVisible')
      : '';

    return (
      <div className={styles.visibilityCell}>
        <Tooltip color="volcano" title={disabledText}>
          <Button
            shape="circle"
            disabled={isDisabled}
            onClick={() => updateCampaignValue(row.id, 'visible', !isVisible, 'bl.visible')}
            type={!isVisible ? 'ghost' : 'primary'}
            icon={(
            isVisible
              ? <EyeOutlined />
              : <EyeInvisibleOutlined />
            )}
          />
        </Tooltip>
      </div>
    );
  };

  const renderVisibilityStatus = () => {
    const filters = map(filteredVisibleMap, (type) => ({
      text: t(`utils.${type ? 'visible' : 'hidden'}`),
      value: type,
    }));

    return {
      key: 'visible',
      align: 'center',
      width: 80,
      className: styles.noBorderColumn,
      render: renderVisibilityStatusCell,
      filters,
      fixed: 'left',
      onFilter: (value: any, record: any) => {
        // eslint-disable-next-line eqeqeq
        if (value == record?.bl?.visible) {
          return true;
        }
        return false;
      },
    };
  };

  const renderImplementedCell = (row: any) => {
    const value = formatCurrency(row.implemented, row?.raw?.currency);

    return (
      <div>
        {value}
      </div>
    );
  };

  const renderImplemented = () => (
    {
      title: t('modules.campaigns.table.columns.implemented.title'),
      key: 'implemented',
      width: 150,
      render: renderImplementedCell,
      sorter: (a: any, b: any) => {
        const aName = a.implemented || 0;
        const bName = b.implemented || 0;

        if (aName > bName) {
          return -1;
        } if (bName > aName) {
          return 1;
        }
        return 0;
      },
    }
  );

  const renderSpendCell = (row: any) => {
    const spend = row.raw?.spend;

    if (isUndefined(spend)) {
      return (
        <div style={{ opacity: 0.5 }}>
          N/A
        </div>
      );
    }

    const value = formatCurrency(spend, row?.raw?.currency);

    return (
      <div>
        {value}
      </div>
    );
  };

  const renderSpend = () => (
    {
      title: t('modules.campaigns.table.columns.spend.title'),
      key: 'spend',
      width: 150,
      render: renderSpendCell,
      sorter: (a: any, b: any) => {
        const aName = a.raw?.insights?.[0].spend || 0;
        const bName = b.raw?.insights?.[0].spend || 0;

        if (aName > bName) {
          return -1;
        } if (bName > aName) {
          return 1;
        }
        return 0;
      },
    }
  );

  const renderFlaggedCell = (row: any) => {
    const isFlagged = !!row?.bl?.flagged;

    return (
      <Button
        className={cx(
          styles.tableActionsBtn,
          {
            [styles.isFlagged]: isFlagged,
          },
        )}
        type={isFlagged ? 'primary' : 'ghost'}
        size="small"
        onClick={() => updateCampaignValue(row.id, 'flagged', !isFlagged, 'bl.flagged')}
        icon={isFlagged ? <StarFilled /> : <StarOutlined />}
      />
    );
  };

  const renderSourceCell = (row: any) => {
    const { source } = row;

    if (source === 'google') {
      return <GoogleOutlined />;
    }

    if (source === 'facebook') {
      return <FacebookFilled />;
    }

    return null;
  };

  const renderFlagged = () => {
    const filters = map(filteredFlaggedTypeMap, (type) => ({
      text: t(`utils.${type ? 'highlighted' : 'normal'}`),
      value: type,
    }));

    return {
      key: 'flagged',
      render: renderFlaggedCell,
      className: styles.noBorderColumn,
      width: 50,
      filters,
      fixed: 'left',
      onFilter: (value: any, record: any) => {
        if (value === record?.bl?.flagged) {
          return true;
        }
        return false;
      },
    };
  };

  const renderSource = () => {
    const filters = map(filteredSourceMap, (type) => ({
      text: upperFirst(type),
      value: type,
    }));

    return {
      key: 'source',
      render: renderSourceCell,
      className: styles.noBorderColumn,
      width: 50,
      filters,
      fixed: 'left',
      onFilter: (value: any, record: any) => {
        if (value === '*') {
          return true;
        }
        if (value === record.source) {
          return true;
        }
        return false;
      },
    };
  };

  const renderNameCell = (value: any) => (
    <div className={styles.nameCell}>
      <Tooltip title={value.name}>
        <div className={styles.name}>
          {value.name}
        </div>
      </Tooltip>
      <div className={styles.id}>
        {`ID: ${value.id}`}
      </div>
    </div>
  );

  const renderIOCell = (row: any) => {
    const handleIOChange = debounce((e) => {
      const val = e.target.value;

      updateCampaignValue(
        row.id,
        'insertionOrder',
        val,
        'bl.insertionOrder',
      );
    }, 1200);

    return (
      <div className={styles.io}>
        <Input
          className={styles.ioInput}
          defaultValue={row?.bl?.insertionOrder}
          onChange={handleIOChange}
          placeholder={t('modules.campaigns.table.columns.io.placeholder')}
        />
      </div>
    );
  };

  const renderNotesCell = (row: any) => {
    const handleNotesChange = debounce((e) => {
      const val = e.target.value;

      updateCampaignValue(
        row.id,
        'notes',
        val,
        'bl.notes',
      );
    }, 1200);

    return (
      <div className={styles.io}>
        <Input
          className={styles.ioInput}
          defaultValue={row?.bl?.notes}
          onChange={handleNotesChange}
          placeholder={t('modules.campaigns.table.columns.notes.placeholder')}
        />
      </div>
    );
  };

  const renderIOColumn = () => ({
    title: t('modules.campaigns.table.columns.io.title'),
    key: 'io',
    width: 180,
    render: renderIOCell,
    sorter: (a: any, b: any) => {
      const aName = a?.bl?.insertionOrder || '';
      const bName = b?.bl?.insertionOrder || '';

      if (aName > bName) {
        return -1;
      } if (bName > aName) {
        return 1;
      }
      return 0;
    },
  });

  const renderNotesColumn = () => ({
    title: t('modules.campaigns.table.columns.notes.title'),
    key: 'io',
    width: 250,
    render: renderNotesCell,
  });

  const columns = useMemo((): ColumnType<any>[] => [
    renderSource() as ColumnType<any>,
    renderFlagged() as ColumnType<any>,
    {
      key: 'loadingIndicator',
      width: 30,
      className: styles.noBorderColumn,
      render: renderLoadingStatus,
      fixed: 'left',
    },
    renderVisibilityStatus() as ColumnType<any>,
    {
      title: t('modules.campaigns.table.columns.name.title'),
      key: 'name',
      width: 180,
      render: renderNameCell,
      fixed: 'left',
      sorter: (a: any, b: any) => {
        const aName = a.name || '';
        const bName = b.name || '';

        if (aName > bName) {
          return -1;
        } if (bName > aName) {
          return 1;
        }
        return 0;
      },
    },
    renderIOColumn(),
    renderCurrencyColumn(),
    renderImplemented(),
    renderSpend(),
    renderMarkupTargetColumn(),
    renderMarkupColumn(),
    renderMarkupTypeColumn(),
    renderNotesColumn(),
    {
      title: t('modules.campaigns.table.columns.lastUpdated.title'),
      key: 'updatedAt',
      width: 140,
      render: (value) => {
        if (!value?.bl?.updatedAt) {
          return t('feedback.never');
        }
        return upperFirst(moment(value?.bl?.updatedAt).fromNow());
      },
      sorter: (a: any, b: any) => {
        const aCmp = a?.bl?.updatedAt ? +(new Date(a?.bl?.updatedAt)) : 0;
        const bCmp = b?.bl?.updatedAt ? +(new Date(b?.bl?.updatedAt)) : 0;

        if (aCmp > bCmp) {
          return 1;
        } if (bCmp > aCmp) {
          return -1;
        }
        return 0;
      },
    },
  ], [data, campaigns, x, asyncStatus]);

  const customConfig = useMemo((): TableProps<any> => {
    const fromProps = tableProps || {};

    const custom: Partial<TableProps<any>> = {
      rowClassName: (record: any) => (record?.bl?.flagged ? styles.flaggedRow : styles.normalRow),
    };

    return ({
      ...fromProps,
      ...custom,
      scrollX: true,
    } as TableProps<any>);
  }, [tableProps]);

  const organizationsOptions = useMemo(() => (
    map(organizations, (organization: any) => (
      <Option value={organization.id} key={organization.id} origin={JSON.stringify(organization)}>
        {organization.name}
      </Option>
    ))
  ), [organizations]);

  const clientsOptions = useMemo(() => (
    map(clients, (client: any) => (
      <Option value={client.id} key={client.id}>
        {client.name}
      </Option>
    ))
  ), [clients]);

  const fetchSetOrganizations = async (forceCache?: boolean) => {
    setIsLoadingOrganizations(true);

    try {
      const params: any = {};
      if (forceCache) {
        params.t = +Date.now();
      }
      const response = await authorizedHttp.get('/organizations', {
        params,
      });

      const fetchedOrganizations = response.data.payload.organizations;

      setOrganizations(fetchedOrganizations);
    } catch (err) {
      logger.error(`Error while fetching Organizations: ${JSON.stringify(err)}`);
      notification.error({
        message: t('modules.organizations.alerts.fetched.error.title'),
        description: t('modules.organizations.alerts.fetched.error.description'),
      });
    } finally {
      setIsLoadingOrganizations(false);
    }
  };

  const fetchSetClients = async () => {
    setIsLoadingClients(true);

    try {
      const params: any = {};
      /** Force cache always */
      params.t = +Date.now();
      const response = await authorizedHttp.get(`/organization/${selectedOrganization.id}/clients`, {
        params,
      });

      const fetchedClients = response.data.payload.clients;

      setClients(fetchedClients);
    } catch (err) {
      logger.error(`Error while fetching clients: ${JSON.stringify(err)}`);
      notification.error({
        message: t('modules.clients.alerts.fetched.error.title'),
        description: t('modules.clients.alerts.fetched.error.description'),
      });
    } finally {
      setIsLoadingClients(false);
    }
  };

  const fetchSetCampaigns = async (forceCache?: boolean) => {
    setIsLoadingCampaigns(true);

    try {
      const params: any = {
        timeRange: {
          since: dateRange?.[0].format('YYYY-MM-DD'),
          until: dateRange?.[1].format('YYYY-MM-DD'),
        },
        client: selectedClient,
      };

      if (forceCache) {
        params.t = +Date.now();
      }

      const response = await authorizedHttp.get(`/organization/${selectedOrganization?.id}/campaigns`, {
        params,
      });

      setPayload(response.data);
      const fetchedCampaigns = response.data.payload.campaigns;
      setCampaigns(fetchedCampaigns);
    } catch (err) {
      logger.error(`Error while fetching campaigns: ${JSON.stringify(err)}`);
      notification.error({
        message: t('modules.campaigns.alerts.fetched.error.title'),
        description: t('modules.campaigns.alerts.fetched.error.description'),
      });
    } finally {
      setIsLoadingCampaigns(false);
    }
  };

  const onRefresh = () => {
    fetchSetCampaigns(true);
  };

  const renderDateRange = () => (
    <RangePicker
      disabledDate={(current: moment.Moment) => (
        current > moment() || current < moment().subtract(FB_MAXIMUM_FETCH, 'months')
      )}
      ranges={{
        [t('date.today')]: [moment(), moment()],
        [t('date.thisMonth')]: [moment().startOf('month'), moment().endOf('month')],
        '30D': [moment().subtract(30, 'days'), moment()],
        '60D': [moment().subtract(60, 'days'), moment()],
        '90D': [moment().subtract(90, 'days'), moment()],
        MAX: [moment().subtract(FB_MAXIMUM_FETCH, 'months'), moment()],
      }}
      onChange={(dates) => setDateRange(dates as [moment.Moment, moment.Moment])}
      value={dateRange}
    />
  );

  const renderTableHeaderActions = () => {
    const hasEmptyClients = !isEmpty(selectedOrganization)
      && !isLoadingClients
      && isEmpty(clients);

    const organizationsMessage = hasEmptyClients
      ? t('feedback.emptyClients')
      : '';

    return (
      <>
        <Button
          size="middle"
          type="ghost"
          onClick={() => onRefresh()}
          disabled={isLoadingCampaigns}
          icon={<SyncOutlined />}
          style={{ marginRight: 10 }}
        />
        { renderDateRange() }
        <Tooltip title={organizationsMessage} color="volcano">
          <Select
            className={cx(
              styles.picker,
              {
                [styles.pickerDanger]: hasEmptyClients,
              },
            )}
            placeholder={t('feedback.selectOrganization')}
            value={selectedOrganization?.id}
            onChange={(selected: any, option: any) => {
              setSelectedOrganization(JSON.parse(option.origin));
            }}
            filterOption={false}
            loading={isLoadingOrganizations}
          >
            { organizationsOptions }
          </Select>
        </Tooltip>
        {
        !isEmpty(clients) && (
          <Select
            className={styles.picker}
            placeholder={t('feedback.selectClient')}
            value={selectedClient}
            onChange={(selected: any) => {
              setSelectedClient(selected);
            }}
            filterOption={false}
            loading={isLoadingClients}
          >
            { clientsOptions }
          </Select>
        )
      }
      </>
    );
  };

  useEffect(() => {
    /** Set default date range */
    const defaultStart = moment().subtract(CAMPAIGNS_DEFAULT_LOOKBACK_TIME, 'months');
    const defaultEnd = moment();

    setDateRange([defaultStart, defaultEnd]);

    fetchSetOrganizations();
  }, []);

  useEffect(() => {
    if (selectedOrganization?.id) {
      setClients([]);
      fetchSetClients();
    }
  }, [selectedOrganization?.id]);

  useEffect(() => {
    if (selectedOrganization?.id && dateRange && selectedClient) {
      fetchSetCampaigns();
    }
  }, [selectedOrganization?.id, dateRange, selectedClient]);

  const handleSearch = debounce((searchQuery: string) => {
    const doSearch = () => {
      const collection = payload.payload.campaigns;

      if (!searchQuery) {
        setCampaigns(collection);
        return;
      }

      const filteredDataSearch = fuzzySearch(
        searchQuery,
        collection,
        ['name', 'id', 'io'],
      );

      const filteredData = map(filteredDataSearch, 'item');

      setCampaigns(filteredData);
      setIsSearching(false);
    };

    doSearch();
  }, 1000);

  return (
    <div className={styles.table}>
      <VeloxTable
        data={campaigns}
        tableProps={customConfig}
        columns={columns}
        isLoading={isLoadingCampaigns || isLoadingOrganizations}
        total={size(campaigns)}
        currentPage={1}
        pageSize={30}
        showSearch
        lastUpdateAt={payload.timestamp}
        tableHeaderActions={renderTableHeaderActions()}
        isSearching={isSearching}
        renderEmptyContent={() => <EmptyContent label="modules.campaigns.emptyContent" />}
        onSearch={(value: string) => {
          setIsSearching(true);
          handleSearch(value);
        }}
      />
    </div>
  );
};

const CampaignsTable = React.memo(CampaignsTableComponent);

export default CampaignsTable;
