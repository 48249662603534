import * as React from 'react';
import { useTranslation } from 'react-i18next';

import kpisMap from '../kpis';
import kpisActionsMap from '../kpisActions';

const { useMemo } = React;

export interface IColumnField {
  field: string;
  label: string;
  actionType?: string;
}

const useAllReportColumns = (): IColumnField[] => {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    const kpis = kpisMap() as IColumnField[];
    const kpisActions = kpisActionsMap() as IColumnField[];

    const customColumns = [
      {
        field: 'progress',
        label: t('report.progress'),
      },
      {
        field: 'budget',
        label: t('report.budget'),
      },
      {
        field: 'spend',
        label: t('report.spend'),
      },
      {
        field: 'metrics',
        label: t('report.metrics'),
      },
      {
        field: 'notes',
        label: t('report.notes'),
      },
    ];

    const output = [
      ...customColumns,
      ...kpis,
      ...kpisActions,
    ];

    return output;
  }, []);

  return columns;
};

export default useAllReportColumns;
