import * as React from 'react';

import { find } from 'lodash';

import Suspense from '../../components/Common/Suspense';
import { useFB } from '../Platform/FB';
import { usePlatformPreferences } from './Preferences';

const { createContext, useContext, useState } = React;

interface ILoadingContext {
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
}

const LoadingContext = createContext<ILoadingContext>({} as ILoadingContext);

interface ILoadingProvider {
  children: React.ReactNode
}

const LoadingProvider: React.FC<ILoadingProvider> = (props) => {
  const {
    children,
  } = props;

  const {
    isLoading: isLoadingPreferences,
  } = usePlatformPreferences();

  const {
    isLoading: isLoadingFB,
  } = useFB();

  const [isLoadingState, setIsLoadingState] = useState(false);

  const loadingWatchers = [isLoadingState, isLoadingPreferences, isLoadingFB];

  const isLoading = !!find(loadingWatchers, true);

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        setIsLoading: setIsLoadingState,
      }}
    >
      { isLoading ? <Suspense /> : children }
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);

export default LoadingProvider;
