import React from 'react';

import permissions from '../../common/permissions';
import ProfileDetails from './ProfileDetails';

type TabComponentProps = {
  entity?: Record<string, unknown> | undefined,
  onUpdate?: (entity: Record<string, unknown>) => void,
  isMe?: boolean,
}

type TabComponent = React.NamedExoticComponent<TabComponentProps>

export interface IProfileTab {
  key: string;
  title: string;
  permissions: string[];
  component: TabComponent;
  entity?: Record<string, unknown>;
  entityType: string;
  isMe?: boolean;
}

export type TProfileTabs = IProfileTab[];

const profileTabs: TProfileTabs = [
  {
    key: 'details',
    title: 'modules.user.tabs.details',
    permissions: [
      permissions.users.isMe,
      permissions.users.all,
    ],
    component: ProfileDetails as TabComponent,
    entityType: 'user',
  },
  // {
  //   key: 'roles',
  //   title: 'modules.user.tabs.roles',
  //   permissions: [
  //     permissions.users.notIsMe,
  //     permissions.roles.all,
  //   ],
  //   component: ProfileDetails as TabComponent,
  //   entityType: 'roles',
  // },
  // {
  //   key: 'dangerous',
  //   title: 'modules.user.tabs.dangerous',
  //   permissions: [
  //     permissions.users.notIsMe,
  //     permissions.users.all,
  //   ],
  //   component: ProfileDetails as TabComponent,
  //   entityType: 'user',
  // },
];

export default profileTabs;
