import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, message } from 'antd';
import axios from 'axios';

import { useReport } from '../../../providers/Platform/Reports/Report';
import styles from './styles.module.scss';

interface IProps {
  adRecord: any,
}

const AdCreative: React.FC<IProps> = (props) => {
  const {
    adRecord,
  } = props;

  const {
    creativesURLsRelationship,
  } = useReport();

  const { t } = useTranslation();

  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState<any>(null);

  const creativeId = adRecord?.creative?.id;

  const fetchCreative = async () => {
    setIsFetching(true);

    try {
      const url = creativesURLsRelationship[creativeId];
      const response = await axios.get(url);

      setData(response.data);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (
      !isFetching
        && !data
        && creativeId
        && creativesURLsRelationship?.[creativeId]
    ) {
      fetchCreative();
    }
  }, [creativeId, data, isFetching, creativesURLsRelationship]);

  const url = useMemo(() => {
    if (!data?.effective_object_story_id) {
      return '';
    }

    return `https://facebook.com/${data.effective_object_story_id}`;
  }, [data]);

  const copyPermalink = () => {
    navigator.clipboard.writeText(url);
    message.success(t('report.permalinkCopied'));
  };

  if (!creativeId || !creativesURLsRelationship?.[creativeId] || !data?.thumbnail_url) {
    return <div style={{ width: 200, height: 2 }} />;
  }

  return (
    <div className={styles.Creative}>
      <img src={data.thumbnail_url} alt={data.effective_object_story_id} />
      <div className={styles.cta}>
        <Button size="small" onClick={copyPermalink}>
          {t('report.copyPermalink')}
        </Button>
      </div>
    </div>
  );
};

export default AdCreative;
