import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Tabs } from 'antd';
import {
  first,
  includes, isEmpty, isNil, map, upperFirst,
} from 'lodash';
import moment from 'moment';

import { User } from '@auth0/auth0-spa-js';

import permissions, { IOwnEntity, validatePermissions } from '../../common/permissions';
import { useAuth } from '../../providers/Global/Auth';
import { usePlatformConfig } from '../../providers/Platform/Antd';
import ChangePassword from '../ChangePasswordButton';
import Spinner from '../Common/Spinner';
import UserAvatar from '../UserAvatar';
import styles from './styles.module.scss';
import profileTabs, { TProfileTabs } from './tabs';

type TTabs = TProfileTabs;
type TTab = TTabs[0];

interface IProfileSettings {
  user: User;
  isMe?: boolean;
  onChangeTop?: () => void;
}

const { TabPane } = Tabs;
const { useEffect, useMemo, useState } = React;

const ProfileSettingsComponent: React.FC<IProfileSettings> = (props) => {
  const {
    user,
    isMe,
    onChangeTop,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [tabsObject, setTabsObject] = useState<TTabs>([]);
  const [x, setX] = useState(false);

  const forceUpdate = () => setX(!x);

  const { t } = useTranslation();

  const {
    permissions: authedPermissions,
    locale,
  } = useAuth();

  const {
    momentLocale,
  } = usePlatformConfig();

  useEffect(() => {
    moment.locale(momentLocale);
  }, [momentLocale]);

  const shouldRenderTab = async (tab: TTab): Promise<TTab | null> => {
    const {
      permissions: tabPermissions,
    } = tab;

    const outputTab = {
      ...tab,
      isMe,
    };

    if (isMe && includes(tabPermissions, permissions.users.isMe)) {
      outputTab.entity = user;
      return outputTab;
    }

    if (isMe && includes(tabPermissions, permissions.users.notIsMe)) {
      return null;
    }

    const entity: IOwnEntity = {
      entity: tab.entityType,
      value: user.user_id || user.sub || '',
      returnEntity: true,
    };

    const entityResponse = await validatePermissions({
      authedPermissions,
      testPermissions: tabPermissions,
      ownEntity: entity,
    });

    if (!entityResponse) {
      return null;
    }

    outputTab.entity = entityResponse;

    return tab;
  };

  const setPossibleTabs = async () => {
    setIsLoading(true);
    const result = (await Promise.all(
      map(profileTabs, shouldRenderTab),
    )).filter((rt) => !isNil(rt));

    setTabsObject(result as TTabs);

    setIsLoading(false);
  };

  useEffect(() => {
    setPossibleTabs();
  }, [user, isMe]);

  const fixedContent = useMemo(() => (
    <div className={styles.profileSettingsTop}>
      <div className={styles.profileSettingsAvatar}>
        <UserAvatar
          userIdentifier={user.user_id}
          profilePicture={user.picture}
          size={90}
          onUpload={() => onChangeTop?.()}
          allowEdition
        />
      </div>
      <div className={styles.profileSettingsBasic}>
        <div className={styles.profileSettingsName}>
          {user.name}
        </div>
        <div className={styles.profileSettingsEmail}>
          <Button
            type="link"
            className={styles.profileSettingsLink}
            href={`mailto:${user.email}`}
          >
            {user.email}
          </Button>
        </div>
        <div className={styles.profileSettingsInfo}>
          <strong>{t('modules.user.lastUpdate')}</strong>
          <span>{upperFirst(moment(user.updated_at).fromNow())}</span>
        </div>
        <div className={styles.profileSettingsInfo}>
          <ChangePassword user={user} onChange={() => onChangeTop?.()} />
        </div>
      </div>
    </div>
  ), [user, locale, x]);

  const tabsContent = useMemo(() => {
    if (isEmpty(tabsObject)) {
      return null;
    }

    const firstTab = first(tabsObject);

    return (
      <Tabs defaultActiveKey={firstTab?.key}>
        {map(tabsObject, (tab) => (
          <TabPane
            tab={<div className={styles.tab}>{t(tab.title)}</div>}
            key={tab.key}
          >
            <div className={styles.tabPane}>
              <tab.component entity={user} onUpdate={forceUpdate} isMe={tab.isMe} />
            </div>
          </TabPane>
        ))}
      </Tabs>
    );
  }, [tabsObject]);

  return (
    <div className={styles.profileSettings}>
      { fixedContent }
      {
        isLoading
          ? (
            <div className={styles.profileTabsLoader}>
              <Spinner size={40} />
            </div>
          )
          : tabsContent
      }
    </div>
  );
};

const ProfileSettings = React.memo(ProfileSettingsComponent);

export default ProfileSettings;
