import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'antd';
import { map } from 'lodash';

import styles from './styles.module.scss';

const { useMemo } = React;
const { Option } = Select;

interface IMetricsPicker {
  selected: string[];
  onChange: (metrics: string[]) => void;
  availableMetrics: Record<string, any>[];
}

const MetricsPickerComponent: React.FC<IMetricsPicker> = (props) => {
  const {
    availableMetrics,
    selected,
    onChange,
  } = props;

  const { t } = useTranslation();

  const children = useMemo(() => map(availableMetrics, (metric) => (
    <Option
      key={`metric_${metric.field}`}
      value={metric.field}
      title={metric.label}
    >
      {metric.label}
    </Option>
  )), [availableMetrics]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.label}>
          {t('report.metricsLabel')}
        </div>
        <Select
          mode="multiple"
          placeholder={t('report.metricsPlaceholder')}
          value={selected}
          onChange={onChange}
          maxTagCount={2}
          bordered={false}
          className={styles.select}
        >
          {children}
        </Select>
      </div>
    </div>
  );
};

const MetricsPicker = React.memo(MetricsPickerComponent);

export default MetricsPicker;
