/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal, TableProps } from 'antd';
import {
  each,
  filter,
  find,
  includes,
  indexOf,
  isEmpty,
  isUndefined,
  map, merge, omit, size, uniq, uniqBy,
} from 'lodash';

import { CheckCircleFilled, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';

import assets from '../../../../assets';
import fuzzySearch from '../../../../common/fuzzySearch';
import { publicHttp } from '../../../../common/http';
import { useFB } from '../../../../providers/Platform/FB';
import Spinner from '../../../Common/Spinner';
import VeloxTable from '../../../Table';
import styles from './styles.module.scss';

const { useMemo, useState, useEffect } = React;

interface IFBChooseAccount {
  onChange?: (value: any[]) => void;
  value?: any;
}

interface IFBAdAccount {
  name: string;
  currency: string;
  account_id: number;
  id: string;
  accessToken: string;
}

interface IFBResponse {
  id: number;
  name: string;
  email: string;
  adaccounts: {
    data: IFBAdAccount[]
  };
}

const ChooseAccountComponent: React.FC<IFBChooseAccount> = (props) => {
  const {
    onChange,
    value,
  } = props;

  const [FBResponseAdAccounts, setFBResponseAdAccounts] = useState<IFBAdAccount[]>();
  const [FBResponseSource, setFBResponseSource] = useState<IFBResponse>();
  const [isSyncing, setIsSyncing] = useState(false);
  const [adAccountModalOpen, setAdAccountModalOpen] = useState(false);
  const [selectedAdAccounts, setSelectedAdAccounts] = useState<string[]>([]);
  const [existingAccounts, setExistingAccounts] = useState<IFBAdAccount[]>([]);

  const { t } = useTranslation();

  const {
    isAvailable: isFBAvailable,
    FB,
  } = useFB();

  useEffect(() => {
    if (!isEmpty(value)) {
      const parsedValue = map(value, (adAccount: any) => {
        const newAdAccount = { ...adAccount };
        const { accountId, account_id } = newAdAccount;
        newAdAccount.account_id = accountId || account_id;
        newAdAccount.id = `act_${accountId}`;

        return omit(newAdAccount, 'accountId') as IFBAdAccount;
      });

      setSelectedAdAccounts(uniq(map(parsedValue, 'id')));
      setExistingAccounts(parsedValue);
    }
  }, [value]);

  const handleInit = async () => {
    if (isFBAvailable) {
      setIsSyncing(true);

      FB.login((response: any) => {
        if (response.authResponse) {
          publicHttp.post('/public/auth/fb', {
            accessToken: response.authResponse.accessToken,
          }).then((longLivedTokenRequest) => {
            const longAccessToken = longLivedTokenRequest.data.payload.accessToken;

            FB.api('/me?fields=id,name,email,adaccounts.limit(2000){name,currency,account_id,media_agency}', (responseMe: any) => {
              setIsSyncing(false);

              const adAccountsData = responseMe?.adaccounts.data;

              each(adAccountsData, (account) => {
              // eslint-disable-next-line no-param-reassign
                account.accessToken = longAccessToken;
              });

              const mergedData = uniqBy(merge(adAccountsData as IFBAdAccount[], existingAccounts), 'id');
              setFBResponseAdAccounts(mergedData);

              const newResponse: IFBResponse = { ...responseMe };

              newResponse.adaccounts.data = mergedData;

              setFBResponseSource(newResponse);
              setAdAccountModalOpen(true);
            }, {
              scope: 'email,ads_read,business_management',
            });
          });
        } else {
          setIsSyncing(false);
        }
      }, {
        scope: 'email,ads_read,business_management',
        returnScopes: true,
      });
    }
  };

  const renderInit = () => (
    <div
      className={styles.fbInit}
      onClick={handleInit}
    >
      <div className={styles.fbLogo}>
        <img src={assets.thirdParty.fb.white.medium} alt="Facebook" />
      </div>
      <div className={styles.fbInitHelp}>
        {t('fb.init.help')}
      </div>
      <div className={styles.fbInitSyncIcon}>
        <Button
          type="primary"
          icon={<SyncOutlined />}
        >
          {t('fb.init.start')}
        </Button>
      </div>
    </div>
  );

  const handleRemove = (account: IFBAdAccount) => {
    const newAccs = filter(selectedAdAccounts, (acc: any) => acc !== account.id);
    const adAccounts: any = [];

    each(newAccs, (accountId: string) => {
      const em = find(FBResponseSource?.adaccounts.data, ['id', accountId])
        || find(existingAccounts, ['id', accountId]);

      if (!isUndefined(em)) {
        adAccounts.push(em);
      }
    });

    onChange?.(adAccounts);

    setSelectedAdAccounts(newAccs);
  };

  const renderSelected = () => (
    <div
      className={styles.fbChoosen}
      onClick={handleInit}
    >
      <div className={styles.fbLogo}>
        <img src={assets.thirdParty.fb.white.medium} alt="Facebook" />
      </div>
      <div className={styles.fbInitHelp}>
        {t('fb.init.selected')}
      </div>
      <div className={styles.fbInitSelected}>
        {map(value, (account) => (
          <div className={styles.account}>
            <div className={styles.accountName}>
              {account.name}
            </div>
            <div className={styles.accountRemove}>
              <Button
                className={styles.accountRemove}
                icon={<CloseCircleOutlined />}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleRemove(account);
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const toggleSelection = (rowId: any) => {
    const newSelected = [...selectedAdAccounts];
    if (includes(selectedAdAccounts, rowId)) {
      const ix = indexOf(newSelected, rowId);
      newSelected.splice(ix, 1);
    } else {
      newSelected.push(rowId);
    }

    setSelectedAdAccounts(newSelected);
  };

  const chooseAdAccountsContent = useMemo(() => {
    const data = FBResponseAdAccounts || [];

    const allCurrencies = uniq(map(data, 'currency'));

    const currencyFilters = map(allCurrencies, (currency) => ({
      text: currency,
      value: currency,
    }));

    const columns = [
      {
        key: 'id',
        title: t('fb.init.choose.table.id'),
        render: (adAccount: IFBAdAccount) => (
          <div className={styles.adAccountCell}>
            {adAccount.account_id}
          </div>
        ),
      },
      {
        key: 'name',
        title: t('fb.init.choose.table.name'),
        render: (adAccount: IFBAdAccount) => (
          <div className={styles.adAccountCell}>
            {adAccount.name}
          </div>
        ),
      },
      {
        key: 'currency',
        title: t('fb.init.choose.table.currency'),
        filters: currencyFilters,
        // eslint-disable-next-line max-len
        onFilter: (currencyValue: any, record: IFBAdAccount) => currencyValue === record.currency,
        render: (adAccount: IFBAdAccount) => (
          <div className={styles.adAccountCell}>
            {adAccount.currency}
          </div>
        ),
      },
    ];

    const customConfig: TableProps<any> = {
      rowKey: (row) => row.id,
      onRow: (record) => ({
        onClick: () => {
          toggleSelection(record.id);
        },
      }),
      rowClassName: styles.tableRow,
      rowSelection: {
        type: 'checkbox',
        fixed: true,
        preserveSelectedRowKeys: true,
        selectedRowKeys: selectedAdAccounts,
        onChange: (keys: any) => setSelectedAdAccounts(keys),
        getCheckboxProps: (record: IFBAdAccount) => ({
          id: record.id,
          name: record.id,
        }),
      },
    };

    const handleSearch = (searchValue: string) => {
      const collection = FBResponseSource?.adaccounts?.data || [];

      if (!searchValue) {
        setFBResponseAdAccounts(collection);
        return;
      }

      const result = fuzzySearch(
        searchValue,
        collection,
        ['id', 'account_id', 'name', 'currency'],
      );

      const filteredItems = map(result, 'item');
      setFBResponseAdAccounts(filteredItems as IFBAdAccount[]);
    };

    return (
      <div className={styles.fbChooseAccountsModalContent}>
        <div className={styles.fbChooseAccountsUser}>
          <div className={styles.fbChooseAccountsUserIcon}>
            <CheckCircleFilled />
          </div>
          <div className={styles.fbChooseAccountsUserContent}>
            <div className={styles.name}>
              {FBResponseSource?.name}
            </div>
            <div className={styles.email}>
              {FBResponseSource?.email}
            </div>
          </div>
        </div>
        <div className={styles.fbTable}>
          <VeloxTable
            tableProps={customConfig}
            isLoading={false}
            total={size(FBResponseAdAccounts)}
            pageSize={50}
            currentPage={1}
            data={data}
            showSearch
            onSearch={(v) => handleSearch(v)}
            columns={columns}
          />
        </div>
      </div>
    );
  }, [FBResponseSource, FBResponseAdAccounts, selectedAdAccounts]);

  const setFinalValue = () => {
    const adAccounts: IFBAdAccount[] = [];

    each(selectedAdAccounts, (accountId: any) => {
      const em = find(FBResponseSource?.adaccounts.data, ['id', accountId])
        || find(existingAccounts, ['id', accountId]);

      if (!isUndefined(em)) {
        adAccounts.push(em);
      }
    });

    onChange?.(adAccounts);

    setAdAccountModalOpen(false);
  };

  const renderContent = () => {
    if (isSyncing) {
      return (
        <div className={styles.fbSpinner}>
          <Spinner size={40} />
        </div>
      );
    } if (isEmpty(selectedAdAccounts)) {
      return renderInit();
    }
    return renderSelected();
  };

  return (
    <div className={styles.fbChooseAdAccounts}>
      { renderContent() }
      <Modal
        visible={adAccountModalOpen}
        destroyOnClose
        centered
        title={t('fb.init.choose.title')}
        className={styles.fbChooseAccountsModal}
        onCancel={() => {
          setAdAccountModalOpen(false);
          setSelectedAdAccounts([]);
          onChange?.([]);
        }}
        onOk={() => setFinalValue()}
      >
        {chooseAdAccountsContent}
      </Modal>
    </div>
  );
};

const ChooseAdAccount = React.memo(ChooseAccountComponent);

export default ChooseAdAccount;
