import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'antd';
import { map } from 'lodash';

import { User } from '@auth0/auth0-spa-js';

import fuzzySearch from '../../../common/fuzzySearch';
import { authorizedHttp } from '../../../common/http';
import logger from '../../../common/logger';
import Spinner from '../../Common/Spinner';
import styles from './accountPicker.module.scss';

const { useEffect, useMemo, useState } = React;

interface IAccountPicker {
  value?: string;
  onChange?: (value: string) => void;
}

const AccountPicker: React.FC<IAccountPicker> = (props) => {
  const {
    value,
    onChange,
  } = props;

  const { t } = useTranslation();

  const [accounts, setAccounts] = useState<any[]>([]);
  const [sourceAccounts, setSourceAccounts] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const options = useMemo(() => (
    map(accounts, (account: any) => (
      {
        label: account.name,
        value: account.id,
      }
    ))
  ), [accounts, value]);

  const fetchAndSet = async (forceCache?: boolean) => {
    setIsLoading(true);

    try {
      const response = await authorizedHttp.get('/accounts', {
        params: {
          t: (forceCache && +Date.now()),
        },
      });

      setAccounts(response.data.payload.accounts);
      setSourceAccounts(response.data.payload.accounts);
    } catch (err: any) {
      logger.error(`Error while fetching accounts: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (searchValue: string): User[] => {
    if (!searchValue) {
      setAccounts(sourceAccounts);
      return sourceAccounts;
    }

    const newUsersRes = fuzzySearch(searchValue, sourceAccounts);
    const newUsers = map(newUsersRes, 'item') as User[];

    setAccounts(newUsers);

    return newUsers;
  };

  useEffect(() => {
    fetchAndSet();
  }, []);

  return (
    <div className={styles.accountPicker}>
      {
        isLoading
          ? (
            <div className={styles.accountPickerSpinner}>
              <Spinner />
            </div>
          )
          : (
            <div className={styles.accountPickerContainer}>
              <Select
                showSearch
                className={styles.acountPickerSelect}
                value={value}
                onSelect={(selectedValue: string) => onChange?.(selectedValue)}
                onSearch={handleSearch}
                filterOption={false}
                placeholder={t('fields.pickAccount.placeholder')}
                options={options}
              />
            </div>
          )
      }
    </div>
  );
};

export default AccountPicker;
