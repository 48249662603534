/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import {
  Button,
  Modal,
  notification,
  TableProps,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import { map, size, upperFirst } from 'lodash';
import moment from 'moment';

import {
  DeleteOutlined, EditOutlined, EyeOutlined, SyncOutlined,
} from '@ant-design/icons';

import { authorizedHttp } from '../../common/http';
import routesPaths from '../../common/routesPaths';
import VeloxTable from '../Table';
import styles from './styles.module.scss';

const { useMemo, useState } = React;

interface IOrganizationsTable {
  organizations: unknown[];
  tableProps?: TableProps<any>;
  total: number;
  currentPage: number;
  pageSize: number;
  isLoading?: boolean;
  selectedIds: string[];
  onRefresh: () => void;
  onSearch: (value: string) => void;
  onSelect: (ids: any[]) => void;
  lastUpdatedAt: number;
}

const OrganizationsTableComponent: React.FC<IOrganizationsTable> = (props) => {
  const {
    tableProps: tablePropsProp,
    isLoading,
    total,
    currentPage,
    pageSize,
    organizations,
    onSearch,
    onRefresh,
    onSelect,
    selectedIds,
    lastUpdatedAt,
  } = props;

  const [isDeleting, setIsDeleting] = useState(false);

  const { t } = useTranslation();

  const history = useHistory();

  const tableProps = useMemo(() => {
    const finalProps = {
      ...tablePropsProp,
    };

    return finalProps;
  }, [tablePropsProp]);

  const data = (() => {
    const parsedData = map(organizations, (organization: any) => ({
      key: organization.id,
      id: organization.id,
      name: organization.name,
      logo: organization.logo,
      createdAt: organization.createdAt,
      updatedAt: organization.updatedAt,
      authorId: organization.author?.auth0Id,
      guardianId: organization.guardian?.auth0Id,
      fbAccounts: organization.fbAccounts,
    }));

    return parsedData;
  })();

  const renderNameCell = (organization: any) => (
    <div className={styles.nameCell}>
      {
        organization.logo && (
          <div className={styles.cellLogo}>
            <div
              className={styles.cellLogoBinder}
              style={{ backgroundImage: `url('${organization.logo}')` }}
            />
          </div>
        )
      }
      <div className={styles.name}>
        {organization.name}
      </div>
    </div>
  );

  const proceedDelete = async (ids: string[]) => {
    setIsDeleting(true);
    await authorizedHttp.delete('/organizations', {
      data: {
        organizationsIds: ids,
      },
    });

    notification.success({
      message: t('feedback.organizationsDeleted'),
    });

    setIsDeleting(false);
    onRefresh();
  };

  const handleDelete = (ids: string[]) => {
    Modal.confirm({
      title: t('feedback.areYouSure'),
      content: t('feedback.irreversibleAction'),
      centered: true,
      closable: true,
      onCancel: () => false,
      onOk: () => {
        proceedDelete(ids);
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  const renderActions = (organization: any) => (
    <div className={styles.tableActions}>
      <Button
        className={styles.tableActionsBtn}
        type="primary"
        onClick={() => history.push(`${routesPaths.organizations}/${organization.id}`)}
        icon={<EyeOutlined />}
      />
      <Button
        className={styles.tableActionsBtn}
        onClick={() => history.push(`${routesPaths.organization}/${organization.id}`)}
        icon={<EditOutlined />}
      />
      <Button
        className={styles.tableActionsBtn}
        type="primary"
        danger
        onClick={() => handleDelete([organization.id])}
        icon={<DeleteOutlined />}
      />
    </div>
  );

  const actionsColumn = useMemo((): ColumnType<any> => {
    const column: ColumnType<any> = {
      title: t('modules.users.table.columns.actions.title'),
      key: 'actions',
      render: renderActions,
    };

    return column;
  }, [data]);

  const columns = useMemo((): ColumnType<any>[] => [
    {
      title: t('modules.organizations.table.columns.name.title'),
      key: 'name',
      render: renderNameCell,
      sorter: (a: any, b: any) => {
        const aName = a.name || '';
        const bName = b.name || '';

        if (aName > bName) {
          return -1;
        } if (bName > aName) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: t('modules.organizations.table.columns.createdAt.title'),
      key: 'createdAt',
      render: (value) => moment(value.createdAt).format('LLL'),
      sorter: (a: any, b: any) => {
        const aCmp = a.createdAt ? +(new Date(a.createdAt)) : 0;
        const bCmp = b.createdAt ? +(new Date(b.createdAt)) : 0;

        if (aCmp > bCmp) {
          return 1;
        } if (bCmp > aCmp) {
          return -1;
        }
        return 0;
      },
    },
    {
      title: t('modules.organizations.table.columns.lastUpdated.title'),
      key: 'createdAt',
      render: (value) => {
        if (!value.updatedAt) {
          return t('feedback.never');
        }
        return upperFirst(moment(value.updatedAt).fromNow());
      },
      sorter: (a: any, b: any) => {
        const aCmp = a.updatedAt ? +(new Date(a.updatedAt)) : 0;
        const bCmp = b.updatedAt ? +(new Date(b.updatedAt)) : 0;

        if (aCmp > bCmp) {
          return 1;
        } if (bCmp > aCmp) {
          return -1;
        }
        return 0;
      },
    },
    actionsColumn,
  ], [data]);

  const customConfig = useMemo((): TableProps<any> => {
    const fromProps = tableProps || {};

    const custom = {
      rowSelection: {
        type: 'checkbox',
        fixed: true,
        preserveSelectedRowKeys: true,
        onChange: onSelect,
        getCheckboxProps: (record: any) => ({
          name: record.id,
        }),
      },
    };

    return ({
      ...fromProps,
      ...custom,
    } as TableProps<any>);
  }, [tableProps]);

  const renderTableHeaderActions = () => (
    <>
      <Button
        size="middle"
        type="ghost"
        onClick={() => onRefresh()}
        disabled={isLoading}
        icon={<SyncOutlined />}
        style={{ marginRight: 10 }}
      />
      <Button
        size="middle"
        danger
        type="primary"
        onClick={() => handleDelete(selectedIds)}
        disabled={size(selectedIds) === 0}
        icon={<DeleteOutlined />}
      />
    </>
  );

  const handleSearch = (value: any) => {
    onSearch(value as string);
  };

  return (
    <div className={styles.table}>
      <VeloxTable
        data={data}
        tableProps={customConfig}
        columns={columns}
        isLoading={!!isLoading || isDeleting}
        total={total}
        currentPage={currentPage}
        pageSize={pageSize}
        showSearch
        lastUpdateAt={lastUpdatedAt}
        tableHeaderActions={renderTableHeaderActions()}
        onSearch={handleSearch}
      />
    </div>
  );
};

const OrganizationsTable = React.memo(OrganizationsTableComponent);

export default OrganizationsTable;
