/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import {
  Button,
  message,
  Modal,
  notification,
  TableProps,
  Tooltip,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import {
  get, map, size, uniq, upperFirst,
} from 'lodash';
import moment from 'moment';

import {
  DeleteOutlined,
  EyeOutlined,
  FacebookFilled,
  GoogleCircleFilled,
  LinkOutlined,
  SyncOutlined,
  TableOutlined,
} from '@ant-design/icons';

import { authorizedHttp } from '../../common/http';
import routesPaths from '../../common/routesPaths';
import ClientColumnsModal from '../ClientColumnsModal';
import ClientWizard from '../ClientWizard';
import VeloxTable from '../Table';
import styles from './styles.module.scss';

const { useMemo, useState } = React;

interface IClientsTable {
  clients: unknown[];
  tableProps?: TableProps<any>;
  total: number;
  currentPage: number;
  pageSize: number;
  isLoading?: boolean;
  selectedIds: string[];
  onRefresh: () => void;
  onSearch: (value: string) => void;
  onSelect: (ids: any[]) => void;
  lastUpdatedAt: number;
}

const ClientsTableComponent: React.FC<IClientsTable> = (props) => {
  const {
    tableProps: tablePropsProp,
    isLoading,
    total,
    currentPage,
    pageSize,
    clients,
    onSearch,
    onRefresh,
    onSelect,
    selectedIds,
    lastUpdatedAt,
  } = props;

  const [editColumnsTarget, setEditColumnsTarget] = useState();
  const [isDeleting, setIsDeleting] = useState(false);

  const { t } = useTranslation();

  const history = useHistory();

  const filteredOrganizations = useMemo(() => uniq(map(clients, 'organization.name')), [clients]);

  const tableProps = useMemo(() => {
    const finalProps = {
      ...tablePropsProp,
    };

    return finalProps;
  }, [tablePropsProp]);

  const data = useMemo(() => {
    const parsedData = map(clients, (client: any) => ({
      key: client.id,
      id: client.id,
      name: client.name,
      logo: client.logo,
      createdAt: client.createdAt,
      updatedAt: client.updatedAt,
      authorId: client.author?.auth0Id,
      guardianId: client.guardian?.auth0Id,
      connectionFB: client.connectionFB || [],
      connectionGoogle: client.connectionGoogle || [],
      reportURL: client.reportURL?.url || '',
      organization: client.organization,
      columns: client?.columns,
    }));

    return parsedData;
  }, [clients]);

  const renderNameCell = (client: any) => (
    <div className={styles.nameCell}>
      {
        client.logo && (
          <div className={styles.cellLogo}>
            <div
              className={styles.cellLogoBinder}
              style={{ backgroundImage: `url('${client.logo}')` }}
            />
          </div>
        )
      }
      <div className={styles.name}>
        {client.name}
      </div>
    </div>
  );

  const proceedDelete = async (ids: string[]) => {
    setIsDeleting(true);
    await authorizedHttp.delete('/clients', {
      data: {
        clientsIds: ids,
      },
    });

    notification.success({
      message: t('feedback.clientsDeleted'),
    });

    setIsDeleting(false);
    onRefresh();
  };

  const handleDelete = (ids: string[]) => {
    Modal.confirm({
      title: t('feedback.areYouSure'),
      content: t('feedback.irreversibleAction'),
      centered: true,
      closable: true,
      onCancel: () => false,
      onOk: () => {
        proceedDelete(ids);
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  const handleEdit = () => {
    onRefresh();
  };

  const renderActions = (client: any) => (
    <div className={styles.tableActions}>
      <Button
        className={styles.tableActionsBtn}
        type="primary"
        onClick={() => {
          setEditColumnsTarget(client);
        }}
        icon={<TableOutlined />}
      />
      <Button
        className={styles.tableActionsBtn}
        type="primary"
        onClick={() => history.push(`${routesPaths.clients}/${client.id}`)}
        icon={<EyeOutlined />}
      />
      <ClientWizard
        className={styles.tableActionsBtn}
        onCreate={handleEdit}
        client={client}
        isEdit
      />
      <Button
        className={styles.tableActionsBtn}
        type="primary"
        danger
        onClick={() => handleDelete([client.id])}
        icon={<DeleteOutlined />}
      />
    </div>
  );

  const actionsColumn = useMemo((): ColumnType<any> => {
    const column: ColumnType<any> = {
      title: t('modules.users.table.columns.actions.title'),
      key: 'actions',
      render: renderActions,
    };

    return column;
  }, [data]);

  const handleOnCopy = () => {
    message.success(t('feedback.linkCopySuccess'));
  };

  const renderReportURL = (client: any) => {
    if (client.reportURL) {
      return (
        <div className={styles.reportURL}>
          <CopyToClipboard text={client.reportURL} onCopy={handleOnCopy}>
            <div className={styles.reportURLChip}>
              <div className={styles.reportURLChipIcon}>
                <LinkOutlined />
              </div>
              <div className={styles.reportURLChipText}>
                {client.reportURL.replace('https://', '')}
              </div>
            </div>
          </CopyToClipboard>
        </div>
      );
    }

    return (
      <div className={styles.noContent}>
        N/A
      </div>
    );
  };

  const renderAdAccountsCell = (client: any) => {
    const accountsData = {
      fb: {
        counts: size(client.connectionFB),
        tooltipText: map(client.connectionFB, (c) => <div>{c.name}</div>),
      },
      google: {
        counts: size(client.connectionGoogle),
        tooltipText: map(client.connectionGoogle, (c) => <div>{c.name}</div>),
      },
    };

    return (
      <div className={styles.adAccountsCell}>
        <Tooltip title={accountsData.fb.tooltipText}>
          <div className={styles.adAccountChip}>
            <FacebookFilled />
            <div>
              {accountsData.fb.counts}
            </div>
          </div>
        </Tooltip>
        <Tooltip title={accountsData.google.tooltipText}>
          <div className={styles.adAccountChip}>
            <GoogleCircleFilled />
            <div>
              {accountsData.google.counts}
            </div>
          </div>
        </Tooltip>
      </div>
    );
  };

  const renderOrganizationColumn = () => {
    const filters = map(filteredOrganizations, (type) => ({
      text: type,
      value: type,
    }));

    return {
      key: 'organization',
      width: 130,
      title: t('menu.singleOrganization.title'),
      className: styles.noBorderColumn,
      render: (client: any) => <div>{get(client, 'organization.name', '-')}</div>,
      filters,
      onFilter: (value: any, record: any) => {
        if (value === get(record, 'organization.name', '-')) {
          return true;
        }
        return false;
      },
    };
  };

  const columns = useMemo((): ColumnType<any>[] => [
    {
      title: t('modules.clients.table.columns.name.title'),
      key: 'name',
      render: renderNameCell,
      sorter: (a: any, b: any) => {
        const aName = a.name || '';
        const bName = b.name || '';

        if (aName > bName) {
          return -1;
        } if (bName > aName) {
          return 1;
        }
        return 0;
      },
    },
    renderOrganizationColumn(),
    {
      title: t('modules.clients.table.columns.reportURL.title'),
      key: 'reportURL',
      render: renderReportURL,
    },
    {
      title: t('modules.clients.table.columns.adAccounts.title'),
      key: 'adAccounts',
      render: renderAdAccountsCell,
    },
    {
      title: t('modules.clients.table.columns.createdAt.title'),
      key: 'createdAt',
      render: (value) => moment(value.createdAt).format('LLL'),
      sorter: (a: any, b: any) => {
        const aCmp = a.createdAt ? +(new Date(a.createdAt)) : 0;
        const bCmp = b.createdAt ? +(new Date(b.createdAt)) : 0;

        if (aCmp > bCmp) {
          return 1;
        } if (bCmp > aCmp) {
          return -1;
        }
        return 0;
      },
    },
    {
      title: t('modules.clients.table.columns.lastUpdated.title'),
      key: 'createdAt',
      render: (value) => {
        if (!value.updatedAt) {
          return t('feedback.never');
        }
        return upperFirst(moment(value.updatedAt).fromNow());
      },
      sorter: (a: any, b: any) => {
        const aCmp = a.updatedAt ? +(new Date(a.updatedAt)) : 0;
        const bCmp = b.updatedAt ? +(new Date(b.updatedAt)) : 0;

        if (aCmp > bCmp) {
          return 1;
        } if (bCmp > aCmp) {
          return -1;
        }
        return 0;
      },
    },
    actionsColumn,
  ], [data]);

  const customConfig = useMemo((): TableProps<any> => {
    const fromProps = tableProps || {};

    const custom = {
      rowSelection: {
        type: 'checkbox',
        fixed: true,
        preserveSelectedRowKeys: true,
        onChange: onSelect,
        getCheckboxProps: (record: any) => ({
          name: record.id,
        }),
      },
    };

    return ({
      ...fromProps,
      ...custom,
    } as TableProps<any>);
  }, [tableProps]);

  const renderTableHeaderActions = () => (
    <>
      <Button
        size="middle"
        type="ghost"
        onClick={() => onRefresh()}
        disabled={isLoading}
        icon={<SyncOutlined />}
        style={{ marginRight: 10 }}
      />
      <Button
        size="middle"
        danger
        type="primary"
        onClick={() => handleDelete(selectedIds)}
        disabled={size(selectedIds) === 0}
        icon={<DeleteOutlined />}
      />
    </>
  );

  const handleSearch = (value: any) => {
    onSearch(value as string);
  };

  return (
    <>
      <div className={styles.table}>
        <VeloxTable
          data={data}
          tableProps={customConfig}
          columns={columns}
          isLoading={!!isLoading || isDeleting}
          total={total}
          currentPage={currentPage}
          pageSize={pageSize}
          showSearch
          lastUpdateAt={lastUpdatedAt}
          tableHeaderActions={renderTableHeaderActions()}
          onSearch={handleSearch}
        />
      </div>
      <ClientColumnsModal
        client={editColumnsTarget}
        onSave={onRefresh}
        onClose={() => setEditColumnsTarget(undefined)}
      />
    </>
  );
};

const ClientsTable = React.memo(ClientsTableComponent);

export default ClientsTable;
