import * as React from 'react';

import Error404Page from '../../pages/Error404';
import { useAuth } from '../../providers/Global/Auth';

interface IPrivateWrapper {
  children: React.ReactNode;
}

const PrivateWrapper: React.FC<IPrivateWrapper> = ({ children }) => {
  const {
    isAuthorized,
  } = useAuth();

  if (!isAuthorized) {
    return <Error404Page />;
  }

  return (
    <div>
      {children}
    </div>
  );
};

export default PrivateWrapper;
