import * as React from 'react';

import withPlatformLayout from '../../components/HOC/withPlatformLayout';
import ReportComponent from '../../components/Report';
import ReportProvider from '../../providers/Platform/Reports/Report';
import SocketProvider from '../../providers/Platform/Socket';

const VisionPage = () => (
  <SocketProvider>
    <ReportProvider>
      <ReportComponent />
    </ReportProvider>
  </SocketProvider>
);

export default withPlatformLayout(VisionPage);
