import * as React from 'react';

import { Upload, UploadProps } from 'antd';
import ImgCrop, { ImgCropProps } from 'antd-img-crop';
import { isFunction } from 'lodash';
import { UploadRequestOption } from 'rc-upload/lib/interface';

import { PictureOutlined } from '@ant-design/icons';

import useFileUpload, { IUploadedFile } from '../../common/hooks/utils/useFileUpload';
import Spinner from '../Common/Spinner';
import styles from './styles.module.scss';

interface IImageUpload {
  aspect?: number;
  uploadProps?: UploadProps;
  cropperProps?: ImgCropProps;
  onUpload?: (fileResult: IUploadedFile[]) => void;
  onPreview?: (b64Strings: string) => void;
}

const ImageUploadComponent: React.FC<IImageUpload> = (props) => {
  const {
    aspect = 1,
    uploadProps,
    cropperProps,
    onUpload,
    onPreview,
  } = props;

  const {
    upload,
    isLoading,
  } = useFileUpload();

  const handlePreview = async (file: File): Promise<string> => {
    const src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });

    if (isFunction(onPreview)) {
      onPreview(src as string);
    }

    return (src as string);
  };

  const handleRequest = async (options: UploadRequestOption) => {
    const { file } = options;
    const files = [file] as File[];

    handlePreview(file as File);

    const response = await upload(files);

    if (isFunction(onUpload)) {
      onUpload(response);
    }
  };

  const handleUploadPreview = async (data: any) => {
    const { file } = data;
    handlePreview(file.originFileObj);
  };

  const renderActions = (): React.ReactElement => (
    <div className={styles.imageUploadActions}>
      <ImgCrop
        rotate
        grid
        aspect={1 / aspect}
        minZoom={0.5}
        fillColor="transparent"
        cropperProps={{
          restrictPosition: false,
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...cropperProps}
      >
        <Upload
          className={styles.uploadTool}
          showUploadList={false}
          customRequest={handleRequest}
          onChange={handleUploadPreview}
            // eslint-disable-next-line react/jsx-props-no-spreading
          {...uploadProps}
        >
          <div className={styles.imageUploadSelect}>
            <div className={styles.imageUploadIcon}>
              <PictureOutlined />
            </div>
          </div>
        </Upload>
      </ImgCrop>
    </div>
  );

  const renderSuspense = () => (
    <div className={styles.imageUploadSpinner}>
      <Spinner size={40} />
    </div>
  );

  return (
    <div className={styles.imageUpload}>
      {
        isLoading
          ? renderSuspense()
          : renderActions()
      }
    </div>
  );
};

const ImageUpload = React.memo(ImageUploadComponent);

export default ImageUpload;
