import Fuse from 'fuse.js';

const fuzzySearch = (search: string, collection: any, keys?: string[]) => {
  const options = {
    keys: keys || ['email', 'name'],
    threshold: 0.2,
    ignoreLocation: true,
    distance: 1000000,
    minMatchCharLength: search.length,
  };

  const fuse = new Fuse(collection, options);

  const result = fuse.search(search);

  return result;
};

export default fuzzySearch;
