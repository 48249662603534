import * as React from 'react';

import {
  ContainerOutlined,
  DeploymentUnitOutlined,
  FireOutlined,
  GoldOutlined,
  GroupOutlined,
  HomeOutlined,
  PercentageOutlined,
  RocketOutlined,
  SisternodeOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

import permissions from '../common/permissions';
import routesPaths from '../common/routesPaths';
import Account from '../pages/Account';
import AccountChoose from '../pages/Account/choose';
import Accounts from '../pages/Accounts';
import CallbackComponent from '../pages/Callback';
import Campaigns from '../pages/Campaigns';
import Client from '../pages/Client';
import ClientChoose from '../pages/Client/choose';
import Clients from '../pages/Clients';
import ErrorPage from '../pages/Error';
import LegalPage from '../pages/Legal';
import Organization from '../pages/Organization';
import OrganizationChoose from '../pages/Organization/choose';
import Organizations from '../pages/Organizations';
import OrganizationSave from '../pages/OrganizationSave';
import StatusPage from '../pages/Status';
import User from '../pages/User';
import UsersManagement from '../pages/Users';
import VisionPage from '../pages/Vision';

export interface IRouteDefinition {
  path?: string;
  url?: string;
  exact?: boolean;
  requiredRoles?: string[],
  isPublic?: boolean;
  component?: React.ReactElement,
  redirectTo?: string,
  showOnMenu?: boolean,
  menuTitleKey?: string,
  permissions?: string[],
  menuMatch?: RegExp[],
  role?: string,
  icon?: React.ReactElement,
}

/** Order is important */
const routesDefinition: IRouteDefinition[] = [
  /** Public Routes */
  {
    path: '/legal',
    exact: true,
    isPublic: true,
    component: <LegalPage />,
  },
  {
    path: '/callback/:callbackType',
    exact: true,
    isPublic: true,
    component: <CallbackComponent />,
  },
  {
    path: '/status',
    exact: true,
    isPublic: true,
    component: <StatusPage />,
  },
  {
    path: '/error',
    exact: true,
    isPublic: true,
    component: <ErrorPage />,
  },
  {
    path: routesPaths.report,
    exact: true,
    isPublic: true,
    component: <VisionPage />,
  },
  {
    path: routesPaths.oauth,
    exact: true,
    isPublic: true,
    component: <h1>Oauth</h1>,
  },
  /** Authorized routes */
  {
    path: '/',
    exact: true,
    redirectTo: routesPaths.platformHome,
  },
  {
    path: routesPaths.platformHome,
    exact: true,
    component: <VisionPage />,
    showOnMenu: true,
    menuTitleKey: 'menu.home.title',
    icon: <HomeOutlined />,
  },
  {
    path: routesPaths.campaigns,
    exact: true,
    component: <Campaigns />,
    showOnMenu: true,
    menuTitleKey: 'menu.campaigns.title',
    permissions: [permissions.markup.all],
    icon: <PercentageOutlined />,
  },
  {
    path: routesPaths.clients,
    exact: true,
    component: <Clients />,
    showOnMenu: true,
    menuTitleKey: 'menu.clients.title',
    permissions: [permissions.clients.all],
    icon: <SisternodeOutlined />,
    menuMatch: [/\/platform\/clients\/.*/],
  },
  {
    path: `${routesPaths.clients}/choose`,
    exact: true,
    component: <ClientChoose />,
    showOnMenu: true,
    menuTitleKey: 'menu.singleClient.title',
    role: 'client-admin',
    permissions: [permissions.clients.single],
    icon: <FireOutlined />,
    menuMatch: [/\/platform\/clients\/.*/],
  },
  {
    path: `${routesPaths.clients}/:clientId`,
    exact: true,
    permissions: [permissions.clients.single],
    component: <Client />,
  },
  {
    path: routesPaths.organizations,
    exact: true,
    component: <Organizations />,
    showOnMenu: true,
    menuTitleKey: 'menu.organizations.title',
    permissions: [permissions.organizations.all],
    icon: <GoldOutlined />,
    menuMatch: [/\/platform\/organizations\/.*/],
  },
  {
    path: routesPaths.organizationSave,
    exact: true,
    component: <OrganizationSave />,
    permissions: [permissions.organizations.all],
  },
  {
    path: `${routesPaths.organizations}/choose`,
    exact: true,
    component: <OrganizationChoose />,
    showOnMenu: true,
    menuTitleKey: 'menu.singleOrganization.title',
    role: 'organization-admin',
    permissions: [permissions.organizations.single],
    icon: <RocketOutlined />,
    menuMatch: [/\/platform\/organization.*/],
  },
  {
    path: `${routesPaths.organizations}/:organizationId`,
    exact: true,
    permissions: [permissions.organizations.single],
    component: <Organization />,
  },
  {
    path: routesPaths.accounts,
    exact: true,
    component: <Accounts />,
    showOnMenu: true,
    menuTitleKey: 'menu.accounts.title',
    permissions: [permissions.accounts.all],
    icon: <DeploymentUnitOutlined />,
    menuMatch: [/\/platform\/accounts\/.*/],
  },
  {
    path: `${routesPaths.accounts}/choose`,
    exact: true,
    component: <AccountChoose />,
    showOnMenu: true,
    menuTitleKey: 'menu.singleAccount.title',
    role: 'account-admin',
    permissions: [permissions.accounts.single],
    icon: <GroupOutlined />,
    menuMatch: [/\/platform\/accounts\/.*/],
  },
  {
    path: `${routesPaths.accounts}/:accountId`,
    exact: true,
    permissions: [permissions.accounts.single],
    component: <Account />,
  },
  {
    path: routesPaths.usersManagement,
    exact: true,
    component: <UsersManagement />,
    showOnMenu: true,
    menuTitleKey: 'menu.users.title',
    permissions: [permissions.users.all],
    icon: <TeamOutlined />,
  },
  {
    path: routesPaths.userMe,
    exact: true,
    component: <User isMe />,
    showOnMenu: true,
    menuTitleKey: 'menu.userMe.title',
    icon: <UserOutlined />,
  },
  {
    path: routesPaths.user,
    exact: true,
    component: <User />,
    permissions: [permissions.users.all],
  },
  {
    url: 'https://velox.vision/legal',
    exact: true,
    permissions: [permissions.users.all],
    showOnMenu: true,
    icon: <ContainerOutlined />,
    menuTitleKey: 'menu.legal.title',
  },
];

export default routesDefinition;
