import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';
import cx from 'classnames';
import { map } from 'lodash';

import { EditOutlined, UngroupOutlined } from '@ant-design/icons';

import { IField } from '../../common/fields';
import { authorizedHttp } from '../../common/http';
import ModuleModal from '../ModuleModal';
import styles from './styles.module.scss';

const { useMemo, useState } = React;

interface IClientWizard {
  onCreate: (data: any) => void;
  isEdit?: boolean;
  client?: any;
  className?: string;
}

const ClientWizardComponent: React.FC<IClientWizard> = (props) => {
  const {
    onCreate,
    isEdit,
    className,
    client,
  } = props;

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formContents = useMemo((): IField[] => [
    {
      type: 'image-picker',
      name: 'logo',
      label: t('fields.logo'),
      imagePickerProps: {
        aspect: 0.3,
      },
    },
    {
      type: 'text',
      name: 'name',
      required: true,
      label: t('fields.clientName'),
    },
    {
      type: 'organization-picker',
      name: 'organizationObject',
      required: true,
      label: t('fields.organization'),
    },
    {
      type: 'user-picker',
      name: 'guardian',
      required: true,
      label: t('fields.guardian'),
    },
  ], []);

  const handleSubmit = async (values: any) => {
    const data = {
      guardian: values.guardian,
      logo: values.logo,
      name: values.name,
      organization: values.organizationObject?.organizationId,
      organizationFBIds: values.organizationObject?.organizationFBIds,
      organizationGoogleIds: values.organizationObject?.organizationGoogleIds,
    };

    if (isEdit) {
      const request = await authorizedHttp.put(`/client/${client.id}`, data);
      onCreate(request.data.payload.client);
      return null;
    }

    const request = await authorizedHttp.post('/clients', data);
    onCreate(request.data.payload.client);
    return null;
  };

  const initialValues = useMemo(() => {
    if (!isEdit || !client) {
      return {};
    }
    return {
      logo: client.logo,
      name: client.name,
      guardian: client.guardianId,
      organizationObject: {
        organizationId: client.organization?.id,
        organizationFBIds: map(client.connectionFB, 'id'),
        organizationGLIds: map(client.connectionGoogle, 'id'),
        selectedAdAccounts: [
          ...map(client.connectionFB, 'id'),
          ...map(client.connectionGoogle, 'id'),
        ],
        adAccounts: client.connectionFB,
      },
    };
  }, [client, isEdit]);

  const verb = isEdit ? 'edit' : 'add';

  const icon = isEdit ? <EditOutlined /> : <UngroupOutlined />;

  return (
    <>
      <Button
        icon={icon}
        className={cx(styles.AddClientBtn, className)}
        type={isEdit ? 'ghost' : 'primary'}
        onClick={() => setIsModalOpen(true)}
      >
        {isEdit ? '' : t('actions.addClient.label')}
      </Button>
      <ModuleModal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formFields={formContents}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        submitLabel={`modules.clients.${verb}.submitLabel`}
        title={`actions.${verb}Client.label`}
      />
    </>
  );
};

const ClientWizard = React.memo(ClientWizardComponent);

export default ClientWizard;
