import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Tooltip } from 'antd';
import cx from 'classnames';
import {
  filter, find, includes, map,
} from 'lodash';

import { validatePermissions } from '../../common/permissions';
import routesPaths from '../../common/routesPaths';
import { useAuth } from '../../providers/Global/Auth';
import routesDefinition, { IRouteDefinition } from '../../routes/routesDefinition';
import { customizedClientData, getCustomClientByHost } from '../../utils';
import styles from './styles.module.scss';

const {
  useEffect, useMemo, useState,
} = React;
interface ISidebar {
  expanded: boolean
}

const Sidebar: React.FC<ISidebar> = (props) => {
  const { expanded } = props;

  const {
    isLoading,
    permissions: authedPermissions,
    user,
  } = useAuth();

  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false);
  const [allowedRoutes, setAllowedRoutes] = useState<string[]>([]);

  const { t } = useTranslation();

  const location = useLocation();

  const routes = useMemo(() => (
    filter(routesDefinition, (route: IRouteDefinition) => route.showOnMenu)
  ), [routesDefinition]);

  const decideAllowedRoutes = async () => {
    setIsLoadingPermissions(true);

    const promiseMap = map(routesDefinition, async (route: IRouteDefinition) => {
      const {
        permissions,
        path,
      } = route;

      if (!permissions) {
        return path;
      }

      if (route.role && user?.role !== route.role) {
        return '';
      }

      const isValid = await validatePermissions({
        testPermissions: permissions,
        authedPermissions,
      });

      if (isValid) {
        return path;
      }
      return '';
    });

    const allowedRoutesMap = (await Promise.all(promiseMap)) as string[];

    setAllowedRoutes(allowedRoutesMap);
    setIsLoadingPermissions(false);
  };

  useEffect(() => {
    decideAllowedRoutes();
  }, [routes, user]);

  const renderSideBarContent = () => {
    if (isLoading || isLoadingPermissions) {
      return null;
    }

    const elements = map(
      (routes),
      (route: IRouteDefinition): React.ReactElement | null => {
        const {
          menuTitleKey,
          icon,
          path,
          url,
        } = route;

        if (!includes(allowedRoutes, path)) {
          return null;
        }

        const matchPaths = route.menuMatch || [];
        matchPaths.push(new RegExp(`${path}$`));

        const isCurrent = find(matchPaths, (matchPath) => {
          const regtest = matchPath?.test(location.pathname);
          return !!regtest;
        });

        if (url) {
          return (
            <div
              key={url}
              className={cx(
                styles.menuElement,
              )}
            >
              <a href={url} target="_blank" rel="noreferrer">
                <Tooltip
                  title={expanded ? undefined : t(menuTitleKey || '')}
                  placement="right"
                >
                  <div className={styles.menuElementContainer}>
                    <div className={styles.menuElementIcon}>
                      { icon }
                    </div>
                    <div className={styles.menuElementLabel}>
                      { t(menuTitleKey || '') }
                    </div>
                  </div>
                </Tooltip>
              </a>
            </div>
          );
        }

        if (!path) {
          return null;
        }

        return (
          <div
            key={path}
            className={cx(
              styles.menuElement,
              {
                [styles.active]: isCurrent,
              },
            )}
          >
            <Link to={path}>
              <Tooltip
                title={expanded ? undefined : t(menuTitleKey || '')}
                placement="right"
              >
                <div className={styles.menuElementContainer}>
                  <div className={styles.menuElementIcon}>
                    { icon }
                  </div>
                  <div className={styles.menuElementLabel}>
                    { t(menuTitleKey || '') }
                  </div>
                </div>
              </Tooltip>
            </Link>
          </div>
        );
      },
    );

    return elements;
  };

  const currentClient = getCustomClientByHost();

  const { monogram, headerLogo } = customizedClientData[currentClient];

  return (
    <div className={cx(
      styles.sidebar,
      {
        [styles.expanded]: expanded,
      },
    )}
    >
      <div className={styles.sidebarLogo}>
        <Link to={routesPaths.home}>
          <img src={monogram} alt="Logo" />
        </Link>
      </div>
      <div className={styles.sidebarContent}>
        { renderSideBarContent() }
      </div>
      <div className={styles.sidebarLettering}>
        <img src={headerLogo} alt="Logo" />
      </div>
    </div>
  );
};

export default Sidebar;
