/* eslint-disable import/prefer-default-export */
/**
 * Disclaimer
 * In this file we're doing a violation of the platform by setting
 * specific client's behaviors.
 *
 * This should be removed and moved to an admin system in the future.
 */

import assets from '../assets';

interface PlatformData {
  monogram: string;
  headerLogo: string;
  clientName: string;
}

type existingCustomClients = 'snowfox'
  | 'velox';

export const customizedClientData: Record<existingCustomClients, PlatformData> = {
  snowfox: {
    monogram: 'https://storage.googleapis.com/velox-vision-public/custom-clients/snfx/Frame%201.png',
    headerLogo: 'https://storage.googleapis.com/velox-vision-public/custom-clients/snfx/logo_02%20(1)%202%20(1).png',
    clientName: 'Snowfox',
  },
  velox: {
    monogram: assets.logo.monogram,
    headerLogo: assets.logo.typed,
    clientName: 'Velox',
  },
};

const snowfoxDomains = ['snfx-kmco.ngrok.io', 'snfx.app'];

export const getCustomClientByHost = (): existingCustomClients => {
  if (snowfoxDomains.includes(window.location.host)) {
    return 'snowfox';
  }
  return 'velox';
};
