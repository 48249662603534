import * as React from 'react';

import useRequest, { IUseRequest, IUseRequestOpts } from './useRequest';

const { useCallback } = React;

const usePublicAuthorizedRequest = (opts: IUseRequestOpts): IUseRequest => {
  const { performRequest, isPerforming } = useRequest(opts);

  const performAuthorizedRequest = useCallback(async (argOpts: IUseRequestOpts) => performRequest({
    ...opts,
    ...argOpts,
    basicToken: process.env.REACT_APP_API_KEY,
  }), [opts]);

  return {
    isPerforming,
    performRequest: performAuthorizedRequest,
  };
};

export default usePublicAuthorizedRequest;
