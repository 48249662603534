import * as React from 'react';
import { useTranslation } from 'react-i18next';
import PasswordStrengthBar from 'react-password-strength-bar';

import { Input } from 'antd';

import styles from './newPassword.module.scss';

const { useEffect, useMemo, useState } = React;

interface INewPassword {
  value?: string;
  onChange?: (value: string) => void;
}

const NewPassword: React.FC<INewPassword> = (props) => {
  const {
    onChange,
  } = props;

  const { t } = useTranslation();

  const [password, setPassword] = useState('');
  const [hasBeenTouched, setHasBeenTouched] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [score, setScore] = useState(0);

  useEffect(() => {
    if (hasBeenTouched) {
      if (
        password
        && (password === confirmPassword)
        && score >= 3
      ) {
        onChange?.(password);
      } else if (password && confirmPassword) {
        onChange?.('');
      }
    }
  }, [password, confirmPassword, score]);

  const handleChangeScore = (scoreArg: number) => {
    setScore(scoreArg);
  };

  const scoreWords = useMemo(() => [
    t('actions.changePassword.scoreWords.weak'),
    t('actions.changePassword.scoreWords.weak'),
    t('actions.changePassword.scoreWords.okay'),
    t('actions.changePassword.scoreWords.good'),
    t('actions.changePassword.scoreWords.strong'),
  ], [t]);

  return (
    <div
      className={styles.newPassword}
    >
      <div className={styles.inputContainer}>
        <Input
          type="password"
          value={password}
          placeholder={t('actions.changePassword.new')}
          onChange={(e) => {
            setPassword(e.target.value);
            setHasBeenTouched(true);
          }}
        />
        <div className={styles.passwordStrength}>
          <PasswordStrengthBar
            password={password}
            minLength={8}
            onChangeScore={handleChangeScore}
            shortScoreWord={t('actions.changePassword.scoreWords.tooShort')}
            scoreWords={scoreWords}
          />
        </div>
      </div>
      <div className={styles.inputContainer}>
        <Input
          type="password"
          placeholder={t('actions.changePassword.repeat')}
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            setHasBeenTouched(true);
          }}
        />
      </div>
    </div>
  );
};

export default NewPassword;
