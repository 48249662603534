import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button, Checkbox, Empty, message, Modal,
} from 'antd';
import Search from 'antd/lib/input/Search';
import cx from 'classnames';
import {
  each, filter, get, includes, isEmpty, map, size, some,
} from 'lodash';

import { DeleteOutlined } from '@ant-design/icons';

import assets from '../../../../assets';
import fuzzySearch from '../../../../common/fuzzySearch';
import { publicHttp } from '../../../../common/http';
import { useOrganizationSave } from '../../../../providers/OrganizationSave';
import { IAdAccount } from '../../../../providers/OrganizationSave/model';
import { useFB } from '../../../../providers/Platform/FB';
import DataProviderWrapper from '../../DataProviderWrapper';
import commonStyles from '../../styles.module.scss';

const FBProviderComponent: FC = () => {
  const {
    organizationData,
    organization,
    removeFBAdAccount,
    addFBAdAccounts,
  } = useOrganizationSave();

  const {
    isAvailable: isFBAvailable,
    FB,
  } = useFB();

  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');

  const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>([]);
  const [isAuthingFacebook, setIsAuthingFacebook] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [availableAdAccounts, setAvailableAdAccounts] = useState([] as any[]);

  const originalAccounts = useMemo(() => get(organization, 'fbAccounts', []), [organization]);
  const fbExistingAccounts = useMemo(() => get(organizationData, 'fbAccounts', []), [organizationData]);
  const fbExisitingIds = useMemo(() => map(
    fbExistingAccounts,
    (account) => account.accountId || account.account_id,
  ),
  [fbExistingAccounts]);

  const [isProviderOpen, setIsProviderOpen] = useState(false);

  useEffect(() => {
    if (!isEmpty(fbExistingAccounts)) {
      setIsProviderOpen(true);
    }
  }, [fbExistingAccounts]);

  const renderAccount = (account: IAdAccount) => {
    const isNew = !some(originalAccounts, (acc: any) => {
      const accId = (acc.account_id || acc.accountId) || '';
      const accountId = (account.account_id || account.accountId) || '';

      return accId === accountId;
    });

    return (
      <div
        className={cx(
          commonStyles.adAccount,
          {
            [commonStyles.newAdAccount]: isNew,
          },
        )}
      >
        <div className={commonStyles.adAccountInformation}>
          <div className={commonStyles.adAccountName}>
            {account.name}
          </div>
          <div className={commonStyles.adAccountId}>
            {account.accountId || account.account_id}
          </div>
        </div>
        <div className={commonStyles.adAccountRemove}>
          <Button
            type="ghost"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={() => removeFBAdAccount(account.id)}
          />
        </div>
      </div>
    );
  };

  const renderEmpty = () => (
    <div className={commonStyles.adAccountsEmpty}>
      <Empty />
    </div>
  );

  const filteredAdAccounts = useMemo(() => {
    if (!searchValue) {
      return availableAdAccounts;
    }

    const fuzzySearchResults = fuzzySearch(
      searchValue,
      availableAdAccounts,
      ['name', 'account_id'],
    );

    return map(fuzzySearchResults, 'item');
  }, [searchValue, availableAdAccounts]);

  const renderAvailableOptions = () => {
    const options = map(filteredAdAccounts, (adAccount) => ({
      label: `${adAccount.name} [${adAccount.account_id}]`,
      value: adAccount.account_id,
    }));

    return (

      <Checkbox.Group
        options={options}
        value={selectedAccountIds}
        onChange={(values) => setSelectedAccountIds(values as string[])}
        className={commonStyles.checkboxGroup}
      />
    );
  };

  const addAdAccounts = () => {
    if (!isEmpty(selectedAccountIds)) {
      const selectedAdAccounts = filter(
        availableAdAccounts,
        (adAccount) => includes(selectedAccountIds, adAccount.account_id),
      );

      addFBAdAccounts(selectedAdAccounts);
      setSelectedAccountIds([]);
    }

    setShowModal(false);
  };

  const renderModal = () => (
    <Modal
      title={t('fb.init.choose.title')}
      visible={showModal}
      centered
      onCancel={() => setShowModal(false)}
      onOk={addAdAccounts}
    >
      <Search
        className={commonStyles.searchBar}
        placeholder={t('fb.init.search')}
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        allowClear
      />
      {
        isEmpty(filteredAdAccounts)
          ? renderEmpty()
          : renderAvailableOptions()
      }
    </Modal>
  );

  const initFB = () => {
    if (!isFBAvailable) {
      return;
    }

    setIsAuthingFacebook(true);

    FB.login((response: any) => {
      publicHttp.post('/public/auth/fb', {
        accessToken: response.authResponse.accessToken,
      }).then((longLivedResponse) => {
        const longAccessToken = longLivedResponse.data.payload.accessToken;

        FB.api('/me?fields=id,name,email,adaccounts.limit(2000){name,currency,account_id,media_agency}', (responseMe: any) => {
          const adAccountsData = responseMe?.adaccounts.data;

          each(adAccountsData, (account: any) => {
            // eslint-disable-next-line no-param-reassign
            account.accessToken = longAccessToken;
          });

          const fbFilteredAdAccounts = filter(
            adAccountsData,
            (account) => !includes(fbExisitingIds, account.account_id),
          );

          setAvailableAdAccounts(fbFilteredAdAccounts);
          setIsAuthingFacebook(false);
          setShowModal(true);
        }, {
          scope: 'email,ads_read,business_management',
        });
      }).catch(() => {
        message.error(t('fb.init.error'));
        setIsAuthingFacebook(false);
      });
    });
  };

  useEffect(() => {
    if (!showModal) {
      setSelectedAccountIds([]);
      setSearchValue('');
    }
  }, [showModal]);

  return (
    <>
      {renderModal()}
      <DataProviderWrapper
        label="Facebook"
        provider="facebook"
        isOpen={isProviderOpen}
        onToggleOpen={() => setIsProviderOpen(!isProviderOpen)}
        icon={<img src={assets.thirdParty.fb.color.medium} alt="Facebook Icon" />}
        onClickAdd={initFB}
        isAddLoading={isAuthingFacebook}
        counts={size(fbExisitingIds)}
      >
        {
          !isEmpty(fbExistingAccounts)
            ? map(fbExistingAccounts, renderAccount)
            : renderEmpty()
        }
      </DataProviderWrapper>
    </>
  );
};

export default FBProviderComponent;
