export const BASE_PLATFORM_PATH = '/platform';

const routesPaths = {
  home: '/',
  platformHome: BASE_PLATFORM_PATH,
  userBase: `${BASE_PLATFORM_PATH}/user`,
  userMe: `${BASE_PLATFORM_PATH}/user/me`,
  user: `${BASE_PLATFORM_PATH}/user/:userId`,
  usersManagement: `${BASE_PLATFORM_PATH}/users`,
  accounts: `${BASE_PLATFORM_PATH}/accounts`,
  organizations: `${BASE_PLATFORM_PATH}/organizations`,
  organization: `${BASE_PLATFORM_PATH}/organization`,
  organizationSave: `${BASE_PLATFORM_PATH}/organization/:organizationId`,
  clients: `${BASE_PLATFORM_PATH}/clients`,
  campaigns: `${BASE_PLATFORM_PATH}/campaigns-billing`,
  oauth: '/oauth',
  report: '/reports/:clientId',
  error: '/error',
};

export default routesPaths;
