import * as React from 'react';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';

import { includes, map, size } from 'lodash';

import { validatePermissions } from '../common/permissions';
import Suspense from '../components/Common/Suspense';
import PrivateWrapper from '../components/Wrappers/PrivateWrapper';
import PublicWrapper from '../components/Wrappers/PublicWrapper';
import ErrorPage from '../pages/Error';
import Error404Page from '../pages/Error404';
import { useAuth } from '../providers/Global/Auth';
import PlatformProvider from '../providers/Platform';
import routesDefinition, { IRouteDefinition } from './routesDefinition';

const { useEffect, useState } = React;

const MainRouter = () => {
  const {
    isLoading,
    isAuthorized,
    auth0Error,
    isFatal,
    permissions: authedPermissions,
  } = useAuth();

  const [permittedRoutes, setPermittedRoutes] = useState<string[]>([]);
  const [loadingPermittedRoutes, setLoadingPermittedRoutes] = useState(false);

  const hasError = !!auth0Error || isFatal;

  const decideAuthedRoutes = async () => {
    setLoadingPermittedRoutes(true);

    const permitted = [];

    for (let i = 0; i < size(routesDefinition); i += 1) {
      const route = routesDefinition[i];

      const {
        permissions,
        path,
      } = route;

      if (!permissions) {
        permitted.push(path);
        // eslint-disable-next-line no-continue
        continue;
      }

      // eslint-disable-next-line no-await-in-loop
      const isValid = await validatePermissions({
        testPermissions: permissions,
        authedPermissions,
        skipEntity: true,
      });

      if (isValid) {
        permitted.push(path);
      }
    }

    setPermittedRoutes(permitted as string[]);

    /** UX Throttling */
    setTimeout(() => (setLoadingPermittedRoutes(false)), 1000);
  };

  useEffect(() => {
    decideAuthedRoutes();
  }, [authedPermissions, isAuthorized]);

  if (loadingPermittedRoutes) {
    return (
      <Router>
        <Route path="*">
          <Suspense />
        </Route>
      </Router>
    );
  }

  if (hasError) {
    return (
      <Router>
        <Route path="*">
          <ErrorPage error={auth0Error} />
        </Route>
      </Router>
    );
  }

  const renderRoute = (def: IRouteDefinition) => {
    const {
      path,
      exact,
      component,
      isPublic,
      redirectTo,
      permissions,
    } = def;

    const Component = () => {
      if (redirectTo) {
        return (
          <Redirect to={redirectTo} />
        );
      }
      if (isPublic) {
        return (
          <PublicWrapper>
            {component}
          </PublicWrapper>
        );
      }
      const hasPermissions = (() => {
        if (!permissions) {
          return true;
        }
        return includes(permittedRoutes, path);
      })();

      if (!hasPermissions) {
        return <Error404Page />;
      }

      return (
        <PrivateWrapper>
          {component}
        </PrivateWrapper>
      );
    };

    return (
      <Route key={path} path={path} exact={exact} component={Component} />
    );
  };

  const PublicRoutes = map(routesDefinition.filter((def) => def.isPublic), renderRoute);

  if (isLoading || loadingPermittedRoutes) {
    return (
      <Router>
        {PublicRoutes}
        <Route path="*">
          <Suspense />
        </Route>
      </Router>
    );
  }

  const PrivateRoutes = map(routesDefinition.filter((def) => !def.isPublic), renderRoute);

  return (
    <Router>
      <PlatformProvider>
        <Switch>
          {PublicRoutes}
          {PrivateRoutes}
          <Route path="*">
            <Error404Page />
          </Route>
        </Switch>
      </PlatformProvider>
    </Router>
  );
};

export default MainRouter;
