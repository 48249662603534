import * as React from 'react';

import { find } from 'lodash';

import usePublicAuthorizedRequest from '../../common/hooks/http/usePublicAuthorizedRequest';

const {
  createContext, useContext, useEffect, useState,
} = React;

interface IPlatformPrefences {
  getPreference: (name: string) => string;
  isLoading: boolean;
}

const PlatformPrefences = createContext<IPlatformPrefences>({} as IPlatformPrefences);

interface IPlatformPrefencesProvider {
  children: React.ReactNode
}

const PlatformPrefencesProvider: React.FC<IPlatformPrefencesProvider> = (props) => {
  const {
    children,
  } = props;

  const [preferences, setPreferences] = useState<Record<string, string>[]>([]);

  const {
    performRequest,
    isPerforming: isLoading,
  } = usePublicAuthorizedRequest({
    endpoint: '/public/platform/preferences',
  });

  const getPreference = (name: string) => {
    const row = find(preferences, ['key', name]);
    return row
      ? row.value
      : '';
  };

  const initPreferences = async () => {
    const resPreferences = await performRequest();

    setPreferences(resPreferences.preferences?.[0]);
  };

  useEffect(() => {
    initPreferences();
  }, []);

  return (
    <PlatformPrefences.Provider
      value={{
        getPreference,
        isLoading,
      }}
    >
      { children }
    </PlatformPrefences.Provider>
  );
};

export const usePlatformPreferences = () => useContext(PlatformPrefences);

export default PlatformPrefencesProvider;
