import { authorizedHttp } from './http';

const getBrandLogo = async () => {
  /** @TODO handle public requests */

  try {
    const instance = authorizedHttp;
    const response = await instance.get('/user/me/brand-logo');

    return response.data.payload?.logo;
  } catch {
    return '';
  }
};

export default getBrandLogo;
