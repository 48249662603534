import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';
import { first, isFunction } from 'lodash';

import { User } from '@auth0/auth0-spa-js';

import assets from '../../assets';
import useAuthorizedRequest from '../../common/hooks/http/useAuthorizedRequest';
import { IUploadedFile } from '../../common/hooks/utils/useFileUpload';
import ImageUpload from '../ImageUpload';
// import cx from 'classnames';
import styles from './styles.module.scss';

const { useEffect, useState } = React;

interface IUserAvatar {
  userIdentifier?: string;
  profilePicture: string | undefined;
  allowEdition?: boolean;
  size?: number;
  onUpload?: (user: User) => void;
}

const UserAvatarComponent: React.FC<IUserAvatar> = (props) => {
  const {
    profilePicture,
    size = 40,
    allowEdition,
    onUpload,
    userIdentifier = 'me',
  } = props;

  const { t } = useTranslation();

  const [avatar, setAvatar] = useState<string>();

  useEffect(() => {
    setAvatar(profilePicture || assets.user.avatar.generic);
  }, [profilePicture]);

  const {
    performRequest,
  } = useAuthorizedRequest({
    method: 'PATCH',
    endpoint: `user/${userIdentifier}`,
  });

  const displayGenericError = () => {
    Modal.error({
      title: t('errors.platformErrors.profilePicture.title'),
      content: t('errors.platformErrors.profilePicture.content'),
    });
  };

  const handleUpload = async (fileResult: IUploadedFile[]) => {
    const file = first(fileResult);

    if (file && !file?.error) {
      try {
        setAvatar(file.url);
        const { user } = await performRequest({
          data: {
            picture: file.url,
          },
        });

        if (isFunction(onUpload)) {
          onUpload(user);
        }
      } catch {
        displayGenericError();
      }
    } else {
      displayGenericError();
    }
  };

  const renderUploadAvatar = () => (
    <div className={styles.userAvatarUpload}>
      <ImageUpload
        onUpload={handleUpload}
        onPreview={(b64: string) => setAvatar(b64)}
      />
    </div>
  );

  return (
    <div
      className={styles.userAvatar}
      style={{
        height: size,
        width: size,
      }}
    >
      <div
        className={styles.userAvatarImage}
        style={{
          backgroundImage: `url(${avatar})`,
          height: size * 0.9,
          width: size * 0.9,
        }}
      >
        {
          allowEdition && renderUploadAvatar()
        }
      </div>
    </div>
  );
};

const UserAvatar = React.memo(UserAvatarComponent);

export default UserAvatar;
