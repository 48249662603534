import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'antd';
import {
  filter, find, first, map, size,
} from 'lodash';

import { FacebookFilled, GoogleCircleFilled } from '@ant-design/icons';

import fuzzySearch from '../../../common/fuzzySearch';
import { authorizedHttp } from '../../../common/http';
import logger from '../../../common/logger';
import Spinner from '../../Common/Spinner';
import styles from './organizationPicker.module.scss';

const { Option } = Select;

const { useEffect, useMemo, useState } = React;

export interface IOrganizationObject {
  organizationId: string;
  organizationFBIds: string[];
  organizationGoogleIds: string[];
  selectedAdAccounts: string[];
}

interface IOrganizationPicker {
  value?: IOrganizationObject;
  onChange?: (value: IOrganizationObject) => void;
}

const OrganizationPicker: React.FC<IOrganizationPicker> = (props) => {
  const {
    value,
    onChange,
  } = props;

  const { t } = useTranslation();

  const [organizations, setOrganizations] = useState<any[]>([]);
  const [sourceOrganizations, setSourceOrganizations] = useState<any[]>([]);

  const [adAccounts, setAdAccounts] = useState<any[]>([]);
  const [sourceAdAccounts, setSourceAdAccounts] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAdAccounts, setIsLoadingAdAccounts] = useState(false);

  useEffect(() => {
    if (value?.organizationId) {
      onChange?.(value);
    } else if (sourceOrganizations && size(sourceOrganizations)) {
      onChange?.(first(sourceOrganizations).id);
    }
  }, [sourceOrganizations]);

  const options = useMemo(() => (
    map(organizations, (account: any) => (
      <Option value={account.id} key={account.id}>
        {account.name}
      </Option>
    ))
  ), [organizations]);

  const getAdAccountIcon = (source: string) => {
    switch (source) {
      case 'facebook':
        return <FacebookFilled />;
      case 'google':
        return <GoogleCircleFilled />;
      default:
        return null;
    }
  };

  const adAccountsOptions = useMemo(() => (
    map(adAccounts, (account: any) => (
      <Option value={account.id} key={account.id}>
        <div className={styles.adAccountOption}>
          <div className={styles.adAccountIcon}>
            {getAdAccountIcon(account.source)}
          </div>
          {`${account.name} [${account.accountId}]`}
        </div>
      </Option>
    ))
  ), [adAccounts]);

  const fetchAndSet = async (forceCache?: boolean) => {
    setIsLoading(true);

    try {
      const response = await authorizedHttp.get('/organizations', {
        params: {
          t: (forceCache && +Date.now()),
        },
      });

      setOrganizations(response.data.payload.organizations);
      setSourceOrganizations(response.data.payload.organizations);
    } catch (err: any) {
      logger.error(`Error while fetching organizations: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAndSetAdAccounts = async (forceCache?: boolean) => {
    if (!value?.organizationId) {
      return;
    }

    setIsLoadingAdAccounts(true);

    try {
      const response = await authorizedHttp.get(`/organization/${value?.organizationId}/adAccounts`, {
        params: {
          t: (forceCache && +Date.now()),
        },
      });

      setAdAccounts(response.data.payload.adAccounts);
      setSourceAdAccounts(response.data.payload.adAccounts);
    } catch (err: any) {
      logger.error(`Error while fetching organization's ad account: ${err.message}`);
    } finally {
      setIsLoadingAdAccounts(false);
    }
  };

  const handleSearch = (searchValue: string): any[] => {
    if (!searchValue) {
      setOrganizations(sourceOrganizations);
      return sourceOrganizations;
    }

    const newOrganizationsRes = fuzzySearch(searchValue, sourceOrganizations);
    const newOrganizations = map(newOrganizationsRes, 'item') as any[];

    setOrganizations(newOrganizations);

    return newOrganizations;
  };

  const handleSearchAdAccounts = (searchValue: string): any[] => {
    if (!searchValue) {
      setAdAccounts(sourceAdAccounts);
      return sourceAdAccounts;
    }

    const newAdAccountsRes = fuzzySearch(searchValue, sourceAdAccounts, ['name', 'accountId', 'currency']);
    const newAdAccounts = map(newAdAccountsRes, 'item') as any[];

    setAdAccounts(newAdAccounts);

    return newAdAccounts;
  };

  useEffect(() => {
    fetchAndSet();
  }, []);

  useEffect(() => {
    fetchAndSetAdAccounts();
  }, [value?.organizationId]);

  const handleSelectOrganization = (organizationId: string) => {
    const newValue: IOrganizationObject = {
      organizationId,
      organizationFBIds: [],
      organizationGoogleIds: [],
      selectedAdAccounts: [],
    };

    onChange?.(newValue);
  };

  const handleSelectAdAccounts = (accountIds: string[]) => {
    const enrichedAccounts = map(accountIds, (accountId) => find(adAccounts, ['id', accountId])).filter(Boolean);

    const organizationFBIds = map(
      filter(
        enrichedAccounts,
        ['source', 'facebook'],
      ),
      'id',
    );

    const organizationGoogleIds = map(
      filter(
        enrichedAccounts,
        ['source', 'google'],
      ),
      'id',
    );

    const newValue: Partial<IOrganizationObject> = {
      organizationId: value?.organizationId || '',
      selectedAdAccounts: accountIds,
      organizationFBIds,
      organizationGoogleIds,
    };

    onChange?.(newValue as IOrganizationObject);
  };

  const renderDropdowns = () => {
    const hasOrganizationValue = !!value?.organizationId;

    return (
      <div className={styles.organizationPickerContainer}>
        <Select
          showSearch
          className={styles.acountPickerSelect}
          value={value?.organizationId}
          onSelect={(selectedValue: any) => handleSelectOrganization(selectedValue)}
          onSearch={handleSearch}
          filterOption={false}
          placeholder={t('fields.pickOrganization.placeholder')}
        >
          { options }
        </Select>
        {
          hasOrganizationValue && (
            <Select
              showSearch
              className={styles.adAccountPickerSelect}
              value={value?.selectedAdAccounts}
              mode="multiple"
              onChange={
                (selectedValues: any) => (
                  handleSelectAdAccounts(selectedValues)
                )
              }
              onSearch={handleSearchAdAccounts}
              filterOption={false}
              loading={isLoadingAdAccounts}
              placeholder={t('fields.pickAdAccount.placeholder')}
            >
              { adAccountsOptions }
            </Select>
          )
        }
      </div>
    );
  };

  return (
    <div className={styles.organizationPicker}>
      {
        isLoading
          ? (
            <div className={styles.organizationPickerSpinner}>
              <Spinner />
            </div>
          )
          : renderDropdowns()
      }
    </div>
  );
};

export default OrganizationPicker;
