import * as React from 'react';

import {
  AxiosError, AxiosRequestConfig, AxiosResponse, Method,
} from 'axios';
import { each, isFunction } from 'lodash';
import moment from 'moment';

import http from '../../http';

const { useCallback, useState } = React;

export interface IUseRequestOpts {
  method?: Method,
  endpoint?: string,
  data?: unknown,
  query?: Record<string, unknown>,
  accessToken?: string;
  basicToken?: string;
  returnPayload?: boolean;
  url?: string;
  files?: File[];
  // eslint-disable-next-line no-unused-vars
  onError?: (error: AxiosError | Error) => void,
  throwErrors?: boolean;
  disableCache?: boolean;
  skip?: boolean;
}

export interface IUseRequest {
  isPerforming: boolean,
  // eslint-disable-next-line no-unused-vars
  performRequest(performConfig?: IUseRequestOpts):
    Promise<any | AxiosResponse<unknown, unknown>>;
}

const useRequest = (opts: IUseRequestOpts): IUseRequest => {
  const [isPerforming, setIsPerforming] = useState(false);

  const {
    method = 'GET',
    endpoint = '',
    data = {},
    query = {},
    accessToken,
    basicToken,
    returnPayload = true,
    url = '',
    throwErrors,
    disableCache,
    files,
    onError,
    skip,
  } = opts;

  const performRequest = useCallback(async (performConfig?: IUseRequestOpts) => {
    if (skip) {
      return true;
    }

    setIsPerforming(true);

    try {
      const config: AxiosRequestConfig = {
        method,
        url: endpoint,
        data,
        params: query,
        headers: {},
      };

      if (accessToken || performConfig?.accessToken) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${accessToken || performConfig?.accessToken}`,
        };
      }

      if (basicToken || performConfig?.basicToken) {
        config.headers = {
          ...config.headers,
          Authorization: `${basicToken || performConfig?.basicToken}`,
        };
      }

      if (disableCache || performConfig?.disableCache) {
        config.params = {
          ...config.params,
          ts: moment().unix(),
        };
      }

      if (files || performConfig?.files) {
        const sendFiles = files || performConfig?.files;
        const formData = new FormData();

        each(sendFiles, (file) => {
          formData.append('files', file, file.name);
        });

        config.data = formData;
        config.headers = {
          ...config?.headers,
          'Content-Type': 'multipart/form-data',
        };
      }

      const finalConfig: AxiosRequestConfig = {
        baseURL: !url
          ? process.env.REACT_APP_API_URL as string
          : url,
        ...config,
        ...performConfig,
      };

      const res = await http.request(finalConfig);
      return returnPayload
        ? (res.data?.payload || {})
        : res.data;
    } catch (err) {
      if (isFunction(onError)) {
        onError(err as AxiosError);
      }

      if (throwErrors) {
        throw err;
      }

      return {};
    } finally {
      setIsPerforming(false);
    }
  }, [opts]);

  return {
    performRequest,
    isPerforming,
  };
};

export default useRequest;
