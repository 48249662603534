import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { first } from 'lodash';

import { authorizedHttp } from '../../common/http';
import Suspense from '../../components/Common/Suspense';
import withPlatformLayout from '../../components/HOC/withPlatformLayout';

const { useEffect } = React;

const AccountChoose = () => {
  const history = useHistory();

  const fetchAccounts = async () => {
    try {
      const res = await authorizedHttp.get('/user/me/accounts');
      const account: any = first(res.data.payload.accounts);
      history.push(account.id);
    } catch {
      throw new Error('Problem while fetching user accounts.');
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  return (
    <Suspense />
  );
};

export default withPlatformLayout(AccountChoose);
