import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Empty } from 'antd';
import cx from 'classnames';

import styles from './styles.module.scss';

interface IEmptyContent {
  className?: string;
  label: string;
}

const EmptyContentComponent: React.FC<IEmptyContent> = (props) => {
  const {
    className,
    label,
  } = props;

  const { t } = useTranslation();

  return (
    <div className={cx(styles.empty, className)}>
      <Empty
        className={styles.antdEmpty}
        description={t(label)}
        image="https://storage.googleapis.com/velox-vision-public/identity/fox%20(3).svg"
      />
    </div>
  );
};

const EmptyContent = React.memo(EmptyContentComponent);

export default EmptyContent;
