import * as React from 'react';
import Lottie from 'react-lottie';

import useWindowSize from '../../../common/hooks/utils/useWindowSize';

const { useMemo } = React;

interface ILottieCommon {
  animationData: Record<string, unknown>;
  fullWidth?: boolean;
  fullHeight?: boolean;
  width?: number;
  height?: number;
  speed?: number;
}

const LottieCommon: React.FC<ILottieCommon> = (props) => {
  const {
    animationData,
    fullWidth,
    fullHeight,
    width,
    height,
    speed = 1,
  } = props;

  const {
    width: windowWidth,
    height: windowHeight,
  } = useWindowSize();

  const { lottieWidth, lottieHeight } = useMemo(() => {
    const output = {
      lottieWidth: 0,
      lottieHeight: 0,
    };

    if (fullWidth) {
      output.lottieWidth = windowWidth;
    }

    if (fullHeight) {
      output.lottieHeight = windowHeight;
    }

    if (width) {
      output.lottieWidth = width;
    }

    if (height) {
      output.lottieHeight = height;
    }

    return output;
  }, [
    fullWidth,
    fullHeight,
    windowWidth,
    windowHeight,
    width,
    height,
  ]);

  const options = {
    loop: true,
    autoplay: true,
    animationData,
    width: lottieWidth,
    height: lottieHeight,
    speed,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie
      width={`${lottieWidth}px`}
      height={`${lottieHeight}px`}
      options={options}
      isClickToPauseDisabled
    />
  );
};

export default LottieCommon;
