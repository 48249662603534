import * as React from 'react';
import Lottie from 'react-lottie-player';

import lottieAssets from '../../../assets/lottie';

export type TLoadingResponseType = 'loading' | 'error' | 'success';

interface ILoadingResponse {
  status: TLoadingResponseType;
}

const LoadingResponse: React.FC<ILoadingResponse> = (props) => {
  const {
    status = 'loading',
  } = props;

  const animationsMap: Record<TLoadingResponseType, [number, number]> = {
    loading: [0, 120],
    error: [420, 825],
    success: [120, 386],
  };

  const shouldLoop = status === 'loading';

  return (
    <Lottie
      segments={animationsMap[status]}
      style={{
        width: 120,
        height: 120,
      }}
      loop={shouldLoop}
      play
      animationData={lottieAssets.loadingSuccess}
      speed={1.5}
    />
  );
};

export default LoadingResponse;
