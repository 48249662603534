import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { message } from 'antd';
import {
  each, filter, isNaN, map, omit,
} from 'lodash';

import { TDateRange, useReport } from '../../providers/Platform/Reports/Report';
import { authorizedHttp } from '../http';
import kpisMap from '../kpis';
import kpisActionsMap from '../kpisActions';
import logger from '../logger';
import useAllReportColumns from './useReportColumns';

const { useEffect, useMemo, useState } = React;

export interface IMetricDataElement {
  [breakdown: string]: {
    [breakdownEm: string]: number
  }
}

interface ICampaignBreakdowns {
  rawData: any;
  isLoading: boolean;
  destroy: () => void;
  metricsKeys: string[];
  setMetricsKeys: (metricsKeys: string[]) => void;
  getMetricData: (metricKey: string) => IMetricDataElement | null;
  availableMetrics: Record<string, any>[];
}

interface ICampaignBreakdownsProps {
  campaignId: string | undefined,
  nodeId: string | undefined,
  dateRange: TDateRange,
  clientId: string,
}

const DEFAULT_METRICS_KEYS = ['spend', 'clicks', 'impressions'];
const EXCLUDED_METRICS = ['cpa', 'cpl', 'conversions'];
const millionMetrics = ['cpm', 'cpc', 'cpl', 'ctr', 'cpe', 'cpp'];

const useCampaignBreakdowns = (props: ICampaignBreakdownsProps): ICampaignBreakdowns => {
  const {
    campaignId,
    dateRange,
    clientId,
    nodeId,
  } = props;

  const {
    report,
  } = useReport();

  const [rawData, setRawData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [metricsKeys, setMetricsKeys] = useState<string[]>([]);

  const allPossibleColumns = useAllReportColumns();
  const allColumnsKeys = useMemo(() => map(allPossibleColumns, 'field'), [allPossibleColumns]);

  const clientColumns = useMemo(() => {
    let cols = allColumnsKeys;
    if (report?.client?.columns) {
      try {
        cols = JSON.parse(report?.client.columns);
      } catch {
        cols = allColumnsKeys;
      }
    }

    return cols;
  }, [report?.client, allColumnsKeys]);

  const availableMetrics = useMemo(() => {
    if (!clientColumns) {
      return [];
    }

    const kpis = kpisMap(true);
    const kpisActions = kpisActionsMap();

    const merged = [...kpis, ...kpisActions];

    return filter(merged, (kpi) => (
      !EXCLUDED_METRICS.includes(kpi.field)
          && clientColumns.includes(kpi.field)
    ));
  }, [clientColumns]);

  const destroy = () => {
    setRawData(undefined);
    setIsLoading(false);
  };

  const { t } = useTranslation();

  const fetchAndSet = async () => {
    setIsLoading(true);

    try {
      const from = dateRange?.[0]?.format('YYYY-MM-DD');
      const to = dateRange?.[1]?.format('YYYY-MM-DD');

      const response = await authorizedHttp.get(
        `reports/client/${clientId}/${campaignId}/${nodeId ? `${nodeId}/` : ''}breakdowns`,
        {
          params: {
            dateRange: {
              since: from,
              until: to,
            },
          },
        },
      );

      const responseData = response.data.payload;

      setRawData(responseData);
    } catch (err) {
      logger.error(`Error while getting breakdowns: ${JSON.stringify(err)}`);

      message.error(t('report.breakdownsError'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      campaignId
        && clientId
        && dateRange
    ) {
      fetchAndSet();
    }
  }, [campaignId, clientId, dateRange]);

  useEffect(() => {
    const defaultMetricKeys = DEFAULT_METRICS_KEYS.filter(
      (metric) => clientColumns.includes(metric),
    );

    setMetricsKeys(defaultMetricKeys);
  }, [clientColumns]);

  const metricsData = useMemo(() => {
    if (!rawData) {
      return {};
    }

    const grouped: Record<string, IMetricDataElement> = {};

    each(rawData, (breakdownData: any) => {
      const { data } = breakdownData;

      each(data, (metricData) => {
        const mergedData = omit({
          ...omit(metricData, 'actions'),
          ...metricData?.actions,
        }, ['age', 'gender', 'country', 'region', 'hourly', 'device_platform', 'date_stop']);

        const date = metricData.date_stop;

        const gender = metricData?.gender;
        const age = metricData?.age;
        const country = metricData?.country;
        const region = metricData?.region;
        const platform = metricData?.publisher_platform;
        const position = metricData?.platform_position;
        const device = metricData?.impression_device;
        const hourly = metricData?.hourly_stats_aggregated_by_advertiser_time_zone;

        const breakdowns = [
          {
            groupKey: 'gender',
            value: gender,
          },
          {
            groupKey: 'age',
            value: age,
          },
          {
            groupKey: 'region',
            value: region,
          },
          {
            groupKey: 'country',
            value: country,
          },
          {
            groupKey: 'platform',
            value: platform,
          },
          {
            groupKey: 'position',
            value: position,
          },
          {
            groupKey: 'device',
            value: device,
          },
          {
            groupKey: 'hourly',
            value: hourly,
          },
        ];

        if (breakdownData.type === 'hourly') {
          breakdowns.push(
            {
              groupKey: 'dates',
              value: date,
            },
          );
        }

        each(mergedData, (metricValue: string | number, metricKey: string) => {
          if (!grouped[metricKey]) {
            grouped[metricKey] = {} as IMetricDataElement;
          }

          const isMillionMetric = millionMetrics.includes(metricKey);

          const numericValue = +metricValue;

          if (isNaN(numericValue)) {
            return;
          }

          const aggregateBy = (groupKey: string, elementValue: string) => {
            if (!grouped[metricKey][groupKey]) {
              grouped[metricKey][groupKey] = {};
            }

            const shouldTakeMax = isMillionMetric && !['dates', 'platform', 'device'].includes(groupKey);

            if (elementValue) {
              if (!shouldTakeMax) {
                grouped[metricKey][groupKey][elementValue] = (
                  (
                    !grouped[metricKey][groupKey][elementValue]
                      ? 0
                      : grouped[metricKey][groupKey][elementValue]
                  )
                  + numericValue
                );
              } else {
                grouped[metricKey][groupKey][elementValue] = Math.max((
                  (
                    !grouped[metricKey][groupKey][elementValue]
                      ? 0
                      : grouped[metricKey][groupKey][elementValue]
                  ), numericValue));
              }
            }
          };

          map(breakdowns, (breakdown) => aggregateBy(breakdown.groupKey, breakdown.value));
        });
      });
    });

    return grouped;
  }, [rawData]);

  const getMetricData = (metricKey: string): IMetricDataElement | null => (
    metricsData?.[metricKey]
      || null
  );

  return {
    rawData,
    isLoading,
    destroy,
    metricsKeys,
    setMetricsKeys,
    getMetricData,
    availableMetrics,
  };
};

export default useCampaignBreakdowns;
