import { reduce } from 'lodash';

export const KPIS_FIELDS_MAP = {
  impressions: {
    type: 'numeric',
    label: 'Impressions',
  },
  reach: {
    type: 'numeric',
    label: 'Reach',
  },
  canvas_avg_view_time: {
    label: 'Dwell Time (s)',
    type: 'numeric',
  },
  clicks: {
    type: 'numeric',
    label: 'Clicks',
  },
  schedule_total: {
    type: 'conversion',
    label: 'Appointments Scheduled',
  },
  subscribe_total: {
    type: 'conversion',
    label: 'Subscribed',
  },
  submitApplicationTotal: {
    type: 'numeric',
    label: 'Submit Application Total',
  },
  frequency: {
    type: 'numeric',
    label: 'Frequency',
  },
  ctr: {
    type: 'percentage',
    label: 'CTR',
  },
  cost_per_inline_link_click: {
    type: 'currency',
    label: 'CPC (Inline)',
  },
  cost_per_inline_post_engagement: {
    type: 'currency',
    label: 'CPE',
  },
  cost_per_thruplay: {
    type: 'currency',
    label: 'CPV',
  },
  costPerAppointment: {
    type: 'currency',
    label: 'Cost per Appointment',
  },
  costPerCompletedRegistration: {
    type: 'currency',
    label: 'Cost per Completed Registration',
  },
  cpc: {
    type: 'currency',
    label: 'CPC',
  },
  cpm: {
    type: 'currency',
    label: 'CPM',
  },
  cpp: {
    type: 'currency',
    label: 'CPP',
  },
  webConversions: {
    type: 'numeric',
    label: 'Web Conversions',
  },
  onsiteConversions: {
    type: 'numeric',
    label: 'On Site Conversions',
  },
  video_15_sec_watched_actions: {
    type: 'action',
    label: '15s Video View',
    actionType: 'video_view',
  },
  video_p100_watched_actions: {
    type: 'action',
    label: '100% Video View',
    actionType: 'video_view',
  },
  video_p75_watched_actions: {
    type: 'action',
    label: '75% Video View',
    actionType: 'video_view',
  },
  video_p50_watched_actions: {
    type: 'action',
    label: '50% Video View',
    actionType: 'video_view',
  },
  video_p25_watched_actionss: {
    type: 'action',
    label: '25% Video View',
    actionType: 'video_view',
  },
};

const kpisMap = (isBreakdowns?: boolean) => {
  let output = reduce(KPIS_FIELDS_MAP, (prev: any, curr: any, key) => {
    const ins = {
      field: key,
      ...curr,
    };

    prev.push(ins);

    return prev;
  }, []);

  if (isBreakdowns) {
    output = [{
      field: 'spend',
      type: 'currency',
      label: 'Spend',
    }, ...output];
  }

  return output;
};

export default kpisMap;
