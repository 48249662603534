import winston from 'winston';

const alignColorsAndTime = winston.format.combine(
  winston.format.colorize({
    all: true,
  }),
  winston.format.label({
    label: '[VELOX API]',
  }),
  winston.format.timestamp({
    format: 'YY-MM-DD HH:MM:SS',
  }),
  winston.format.printf(
    (info) => ` ${info.label}  ${info.timestamp}  ${info.level} : ${info.message}`,
  ),
);

const isProduction = process.env.NODE_ENV === 'production';

const transports = isProduction
  ? [
    new winston.transports.Console({
      format: winston.format.simple(),
    }),
  ]
  : [
    new (winston.transports.Console)({
      format: winston.format.combine(winston.format.colorize(), alignColorsAndTime),
    }),
  ];

const logger = winston.createLogger({
  level: isProduction ? 'error' : 'debug',
  transports,
});

export default logger;
