import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Button } from 'antd';

import { HomeOutlined, ReloadOutlined } from '@ant-design/icons';

import assets from '../../assets';
import lottieAssets from '../../assets/lottie';
import logger from '../../common/logger';
import LottieCommon from '../../components/Common/Lottie/Common';
import StatusChecks from '../../components/Common/StatusChecks';
import { useAuth } from '../../providers/Global/Auth';
import styles from './styles.module.scss';

interface IErrorPage {
  error?: Error;
}

const ErrorPageComponent: React.FC<IErrorPage> = (props) => {
  const {
    error,
  } = props;

  logger.error(`Error boundary hit: ${error}`);

  const history = useHistory();

  const tPrefix = 'errors.errorBoundary';

  const {
    logout,
  } = useAuth();

  const { t } = useTranslation();

  const errorKeysPrefix = `${tPrefix}.generic`;

  const handleReload = () => {
    logout();
    window.location.reload();
  };

  const renderCallToActions = () => (
    <div className={styles.errorPageCallToActions}>
      <Button
        className={styles.errorPageButton}
        type="primary"
        onClick={handleReload}
        icon={<ReloadOutlined />}
      >
        {t(`${tPrefix}.ctas.reload`)}
      </Button>
      <Button
        className={styles.errorPageButton}
        ghost
        type="primary"
        onClick={() => history.push('/')}
        icon={<HomeOutlined />}
      >
        {t(`${tPrefix}.ctas.home`)}
      </Button>
    </div>
  );

  return (
    <div className={styles.errorPage}>
      <div className={styles.errorContainer}>
        <div className={styles.errorLogo}>
          <a href="/">
            <img src={assets.logo.hiRes} alt="Velox Vision Logo" />
          </a>
        </div>
        <div className={styles.errorTitle}>
          {t(`${errorKeysPrefix}.title`)}
        </div>
        <div className={styles.errorDescription}>
          {t(`${errorKeysPrefix}.description`)}
        </div>
        { renderCallToActions() }

        <div className={styles.statusChecksWrapper}>
          <StatusChecks />
        </div>
      </div>
      <div className={styles.footerAnimation}>
        <LottieCommon
          animationData={lottieAssets.footerAnimation}
          fullWidth
          height={300}
        />
      </div>
    </div>
  );
};

const ErrorPage = React.memo(ErrorPageComponent);

export default ErrorPage;
