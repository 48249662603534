import * as React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { message as AntdMessage, Tooltip } from 'antd';

import styles from './styles.module.scss';

interface INewUserAlert {
  password: string;
  email: string;
  name: string;
}

const NewUserAlert: React.FC<INewUserAlert> = (props) => {
  const {
    password,
    email,
    name,
  } = props;

  const { t } = useTranslation();

  const message = (() => (
    <div className={styles.alertContents}>
      <div className={styles.name}>
        {name}
      </div>
      <div className={styles.email}>
        {email}
      </div>
      <div className={styles.password}>
        <span>
          {t('fields.password')}
          :
        </span>
        <strong>{password}</strong>
      </div>
    </div>
  ))();

  const textMessage = `
  ${t('feedback.welcomeNewAccount')}
  \n
  ${`${t('fields.name')}: ${name}`}
  ${`${t('fields.email')}: ${email}`}
  ${`${t('fields.password')}: ${password}`}
  \n
  ${window.location.origin}
  `;

  const handleOnCopy = () => {
    AntdMessage.success(t('feedback.copySuccess'));
  };

  return (
    <div className={styles.newUserAlert}>
      <CopyToClipboard text={textMessage} onCopy={handleOnCopy}>
        <Tooltip title={t('feedback.copyClipboard')}>
          <div className={styles.alert}>
            <div className={styles.alertTitle}>
              {t('feedback.userInformation')}
            </div>
            {message}
          </div>
        </Tooltip>
      </CopyToClipboard>
    </div>
  );
};

export default NewUserAlert;
