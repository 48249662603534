/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button, notification, TableProps, Tooltip,
} from 'antd';
import cx from 'classnames';
import {
  debounce, filter, find, first, includes, isUndefined, map, omit, size, trim,
} from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

import {
  DownloadOutlined,
  FacebookFilled,
  GoogleOutlined,
  PieChartOutlined,
  PlusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';

import formatCurrency from '../../../common/currency';
import useAllReportColumns from '../../../common/hooks/useReportColumns';
import kpisMap from '../../../common/kpis';
import kpisActionsMap from '../../../common/kpisActions';
import logger from '../../../common/logger';
import createAndDownloadReportXLSX from '../../../common/xlsxReport';
import { useAuth } from '../../../providers/Global/Auth';
import { useReport } from '../../../providers/Platform/Reports/Report';
import VeloxTable from '../../Table';
import AdCreative from '../Creative';
import styles from './styles.module.scss';

const { useEffect, useMemo, useState } = React;

const TableReportComponent: React.FC = () => {
  const {
    content,
    refetchReport,
    isLoadingReport,
    lastUpdatedAt,
    handleExpand,
    level,
    getLevelLabel,
    setLevel,
    getReportArgs,
    report,
    displayBreakdowns,
    displayNodeBreakdowns,
    hideBreakdowns,
    selectedCampaignId,
    selectedNodeId,
    campaignId,
    clientId,
  } = useReport();

  const {
    isAuthorized,
  } = useAuth();

  const { t } = useTranslation();

  const allPossibleColumns = useAllReportColumns();
  const allColumnsKeys = useMemo(() => map(allPossibleColumns, 'field'), [allPossibleColumns]);

  const [payload, setPayload] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);

  const clientColumns = useMemo(() => {
    let cols = allColumnsKeys;
    if (report?.client?.columns) {
      try {
        cols = JSON.parse(report?.client.columns);
      } catch {
        cols = allColumnsKeys;
      }
    }

    return cols;
  }, [report?.client, allColumnsKeys]);

  useEffect(() => {
    hideBreakdowns();
  }, [clientId]);

  const isColumnVisible = (fieldKey: string) => includes(clientColumns, fieldKey);

  /** Move to controlled */
  useEffect(() => {
    const reportData = content || [];

    const originPayload = reportData as any[];

    setPayload(originPayload);
    setData(originPayload);
  }, [content]);

  const getSourceIcon = (row: any) => {
    const { source } = row;

    switch (source) {
      case 'google':
        return <GoogleOutlined />;
      case 'facebook':
        return <FacebookFilled />;
      default:
        return null;
    }
  };

  const renderNameCell = (value: any) => (
    <div className={styles.nameCell}>
      <div style={{ flex: 1 }}>
        <div className={styles.name}>
          <div>{value.name}</div>
        </div>
        <div className={styles.id}>
          {getSourceIcon(value)}
          {value.id}
        </div>
      </div>
    </div>
  );

  const renderCreativeCell = (value: any) => {
    if (+level < 2) {
      return null;
    }

    return <AdCreative adRecord={value} />;
  };

  const renderBreadcrumbs = () => {
    const items = [];
    const intLevel = +level;

    for (let x = 0; x <= intLevel; x += 1) {
      items.push(
        <div
          className={cx(
            styles.breadcrumb,
            {
              [styles.active]: intLevel === x,
            },
          )}
          onClick={() => setLevel(x)}
        >
          <span>
            {getLevelLabel(x)}
          </span>
        </div>,
      );
    }

    return (
      <div className={styles.breadcrumbs}>
        {items}
      </div>
    );
  };

  const handleDownload = async () => {
    setIsDownloadingReport(true);
    try {
      await createAndDownloadReportXLSX({
        ...getReportArgs(),
        clientColumns,
      });
      notification.success({ message: t('report.successDownload') });
    } catch (err: any) {
      notification.error({ message: t('report.errorDownload') });
      logger.error(`Error while downloading report: ${err.message}`);
    } finally {
      setIsDownloadingReport(false);
    }
  };

  const renderTableHeaderActions = () => {
    const canRefetch = isAuthorized && content;

    return (
      <>
        {canRefetch && (
          <Button
            disabled={isLoadingReport}
            onClick={refetchReport}
            icon={<SyncOutlined />}
          />
        )}
        <Button
          icon={<DownloadOutlined />}
          onClick={handleDownload}
          type="primary"
          disabled={isLoadingReport}
          loading={isDownloadingReport}
          className={styles.downloadButton}
        >
          {t('utils.downloadReport')}
        </Button>
      </>
    );
  };

  const renderKPI = (record: any, kpiKey: any, type: any) => {
    const kpis = omit(record?.insights?.[0], 'actions');

    /** Search on insights */
    let value = kpis?.[kpiKey];

    if (type === 'conversion') {
      const em = find(record.insights?.[0]?.conversions, (r) => r.action_type === kpiKey);

      if (em) {
        value = em.value;
      }
    }

    if (isUndefined(value)) {
      return (
        <div className={styles.noContent}>
          N/A
        </div>
      );
    }

    let formatted = value;

    if (type === 'numeric' || type === 'conversion') {
      formatted = numeral(formatted).format('0,0');
    } else if (type === 'percentage') {
      formatted = numeral(formatted / 100).format('0.00%');
    } else if (type === 'currency') {
      formatted = formatCurrency(formatted, record.currency || 'USD');
    } else if (type === 'action') {
      const kpiDef = find(allPossibleColumns, ['field', kpiKey]);

      if (kpiDef?.actionType) {
        const actionType = kpiDef.actionType as string;

        const actionValueDirty = find(value, ['action_type', actionType]);
        const actionValue = actionValueDirty?.value || 0;

        formatted = numeral(actionValue).format('0,0');
      } else {
        formatted = 0;
      }
    }

    return (
      <div className={styles.kpiCell}>
        {formatted}
      </div>
    );
  };

  const renderKPIAction = (record: any, kpiKey: any, type: any) => {
    const kpis = record?.insights?.[0]?.actions || [];

    /** Search on insights */
    const value = kpis?.[kpiKey];

    if (isUndefined(value)) {
      return (
        <div className={styles.noContent}>
          N/A
        </div>
      );
    }

    let formatted = value;

    if (type === 'numeric') {
      formatted = numeral(formatted).format('0,0');
    } else if (type === 'percentage') {
      formatted = numeral(formatted / 100).format('0.00%');
    } else if (type === 'currency') {
      formatted = formatCurrency(formatted, record.currency || 'USD');
    }

    return (
      <div className={styles.kpiCell}>
        {formatted}
      </div>
    );
  };

  const renderKpis = useMemo((): any[] => {
    const kpis = kpisMap();
    const output = map(kpis, (kpi: any) => {
      const {
        type,
        field,
        label,
      } = kpi;

      return {
        title: (
          <Tooltip title={label}>
            <div className={styles.kpiTh}>{label}</div>
          </Tooltip>
        ),
        key: field,
        showSorterTooltip: false,
        className: styles.kpiColumn,
        width: 140,
        hidden: !isColumnVisible(field),
        render: (_: any, record: any) => renderKPI(record, field, type),
        sorter: (a: any, b: any) => {
          const aName = a.insights?.[0]?.[field] || '';
          const bName = b.insights?.[0]?.[field] || '';

          if (aName > bName) {
            return 1;
          } if (bName > aName) {
            return -1;
          }
          return 0;
        },
      };
    });

    return output;
  }, [data, clientColumns]);

  const renderKpisActions = useMemo((): any[] => {
    const kpis = kpisActionsMap();
    const output = map(kpis, (kpi: any) => {
      const {
        type,
        field,
        label,
      } = kpi;

      return {
        title: (
          <Tooltip title={label}>
            <div className={styles.kpiTh}>{label}</div>
          </Tooltip>
        ),
        key: field,
        showSorterTooltip: false,
        className: styles.kpiColumn,
        hidden: !isColumnVisible(field),
        render: (_: any, record: any) => renderKPIAction(record, field, type),
        sorter: (a: any, b: any) => {
          const aName = a.insights?.[0]?.actions?.[field] || '';
          const bName = b.insights?.[0]?.actions?.[field] || '';

          if (aName > bName) {
            return 1;
          } if (bName > aName) {
            return -1;
          }
          return 0;
        },
      };
    });

    return output;
  }, [data, clientColumns]);

  const getRecordProgress = (record: any) => {
    const now = moment();
    const start = moment(record.start_time);
    const end = moment(record.stop_time);

    const totalDays = Math.abs(start.diff(end, 'days'));
    const currentDays = now.diff(start, 'days');

    return Math.min(1, currentDays / totalDays);
  };

  const renderProgressChart = (_:any, record: any) => {
    const progress = getRecordProgress(record);
    const formatted = numeral(progress).format('0.0%');

    return (
      <div className={styles.progress}>
        <div className={styles.progressChart}>
          <div className={styles.track}>
            <div
              className={styles.thumb}
              style={{ width: `${progress * 100}%` }}
            />
          </div>
        </div>
        <div className={styles.progressLabel}>
          {formatted}
        </div>
      </div>
    );
  };

  const lineKey = (() => {
    const intLevel = +level;

    switch (intLevel) {
      case 2:
        return 'ad';
      case 1:
        return 'adset';
      case 0:
      default:
        return 'campaign';
    }
  })();

  const renderActions = (_: any, record: any) => (
    <div className={styles.actions}>
      {
        (isColumnVisible('metrics') && record.source !== 'google') && (
          <Button
            onClick={() => {
              if (lineKey === 'campaign') {
                displayBreakdowns(record.id);
              } else {
                displayNodeBreakdowns(record.id, campaignId);
              }
            }}
            type="primary"
            icon={<PieChartOutlined />}
            shape="circle"
            disabled={record.id === selectedCampaignId || record.id === selectedNodeId}
            className={styles.actionsBtn}
          />
        )
      }
      {
        +level < 2
          && (
            <Button
              onClick={() => {
                handleExpand(record.id);
              }}
              icon={<PlusCircleOutlined />}
              shape="circle"
              className={styles.actionsBtn}
            />
          )
      }

    </div>
  );

  const getSpend = (record: any) => {
    const insights: any = first(record.insights);

    return record.spend
      || insights?.spend
      || 0;
  };

  const columns = useMemo((): any[] => [
    {
      title: t('report.table.creative'),
      key: 'creative',
      fixed: 'left',
      width: 200,
      hidden: +level !== 2,
      render: renderCreativeCell,
    },
    {
      title: t(`report.table.${lineKey}`),
      key: 'name',
      fixed: 'left',
      width: 320,
      render: renderNameCell,
      sorter: (a: any, b: any) => {
        const aName = a.name || '';
        const bName = b.name || '';

        if (aName > bName) {
          return -1;
        } if (bName > aName) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: (<div className={styles.kpiThHighlight}>{t('report.progress')}</div>),
      key: 'progress',
      fixed: 'left',
      hidden: +level >= 1 || !isColumnVisible('progress'),
      className: styles.budgetColumn,
      width: 120,
      render: renderProgressChart,
      sorter: (a: any, b: any) => {
        const aName = getRecordProgress(a);
        const bName = getRecordProgress(b);

        if (aName > bName) {
          return 1;
        } if (bName > aName) {
          return -1;
        }
        return 0;
      },
    },
    {
      title: (<div className={styles.kpiThHighlight}>{t('report.budget')}</div>),
      key: 'budget',
      fixed: 'left',
      width: 120,
      hidden: +level >= 1 || !isColumnVisible('budget'),
      className: styles.budgetColumn,
      render: (_: any, record: any) => {
        const value = record.lifetime_budget;

        return (
          <div className={styles.kpiBudget}>{formatCurrency(value, record.currency || 'USD')}</div>
        );
      },
      sorter: (a: any, b: any) => {
        const aName = a.lifetime_budget || '';
        const bName = b.lifetime_budget || '';

        if (aName > bName) {
          return 1;
        } if (bName > aName) {
          return -1;
        }
        return 0;
      },
    },
    {
      title: (<div className={styles.kpiThHighlight}>{t('report.spend')}</div>),
      key: 'spend',
      fixed: 'left',
      width: 120,
      className: styles.budgetColumn,
      hidden: !isColumnVisible('spend'),
      render: (_: any, record: any) => (
        <div className={styles.kpiBudget}>
          {formatCurrency(getSpend(record), record.currency || 'USD')}
        </div>
      ),
      sorter: (a: any, b: any) => {
        const aName = a.insights?.[0]?.spend || '';
        const bName = b.insights?.[0]?.spend || '';

        if (aName > bName) {
          return 1;
        } if (bName > aName) {
          return -1;
        }
        return 0;
      },
    },
    ...renderKpis,
    ...renderKpisActions,
    {
      title: t('report.notes'),
      key: 'notes',
      width: 200,
      hidden: +level >= 1 || !isColumnVisible('notes'),
      render: (_: any, record: any) => (
        record.notes ? (
          <div className={styles.kpiBudget} style={{ minWidth: 200 }}>
            {record.notes}
          </div>
        )
          : (
            <div className={styles.noContent}>
              N/A
            </div>
          )
      ),
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      render: renderActions,
    },

  ].filter((col) => !col.hidden), [data, level, clientColumns, selectedCampaignId]);

  const handleSearch = debounce((searchQuery: string) => {
    const doSearch = () => {
      const collection = payload;

      if (!searchQuery) {
        setData(collection);
        return;
      }

      const parsedQuery = trim(searchQuery).toLocaleUpperCase();

      const filteredCollection = filter(collection, (v) => {
        const name = trim(v.name).toLocaleUpperCase();
        const id = trim(v.id).toLocaleUpperCase();

        return (
          name.indexOf(parsedQuery) > -1
            || id.indexOf(parsedQuery) > -1
        );
      });

      setData(filteredCollection);
      setIsSearching(false);
    };

    doSearch();
  }, 1000);

  const tableProps = useMemo(() => {
    const fromProps = {};

    const custom: Partial<TableProps<any>> = {
      rowClassName: (record) => (record.id === selectedCampaignId ? styles.selectedRow : ''),
    };

    return ({
      ...fromProps,
      ...custom,
      scroll: {
        scrollToFirstRowOnChange: true,
        x: true as true,
        y: 'max-content',
      },
    } as TableProps<any>);
  }, [selectedCampaignId]);

  return (
    <React.Suspense fallback={<div />}>
      <div className={styles.tableReport}>
        <VeloxTable
          data={data}
          tableProps={tableProps}
          columns={columns}
          isLoading={false}
          total={size(payload)}
          currentPage={1}
          pageSize={size(payload)}
          showSearch
          hidePagination
          lastUpdateAt={lastUpdatedAt}
          isSearching={isSearching}
          tableHeaderActions={renderTableHeaderActions()}
          breadcrumbs={renderBreadcrumbs()}
          onSearch={(value: string) => {
            setIsSearching(true);
            handleSearch(value);
          }}
        />
      </div>
    </React.Suspense>
  );
};

const TableReport = React.memo(TableReportComponent);

export default TableReport;
