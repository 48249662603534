import * as React from 'react';

import GlobalProvider from '../providers/Global';
import MainRouter from './Router';

const MainRouterWrapper = () => (
  <GlobalProvider>
    <MainRouter />
  </GlobalProvider>
);

export default MainRouterWrapper;
