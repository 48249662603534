import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button, Drawer, FormInstance, Modal,
} from 'antd';
import cx from 'classnames';

import { IField } from '../../common/fields';
import LoadingResponse, { TLoadingResponseType } from '../Common/Lottie/LoadingResponse';
import ParsedForm from '../ParsedForm';
import styles from './styles.module.scss';

const { createRef, useMemo, useState } = React;

interface IModuleModal {
  visible: boolean,
  title: string,
  formFields: IField[],
  submitLabel: string,
  onClose: () => void,
  onSubmit: (values: Record<string, unknown>) => Promise<any>,
  replaceTitleMap?: Record<string, string>,
  initialValues?: Record<string, unknown>,
  type?: 'default' | 'wide' | 'drawer'
}

const ModuleModalComponent: React.FC<IModuleModal> = (props) => {
  const {
    visible,
    title,
    replaceTitleMap = {},
    formFields,
    submitLabel,
    onClose,
    onSubmit,
    initialValues = {},
    type = 'default',
  } = props;

  const formRef = createRef<FormInstance>();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [output, setOutput] = useState<TLoadingResponseType>('loading');
  const [submitResponse, setSubmitResponse] = useState(null);

  const { t } = useTranslation();

  const handleSubmit = async (values: any) => {
    setIsSubmitting(true);
    setOutput('loading');
    try {
      const response = await onSubmit(values);
      setSubmitResponse(response);
      setOutput('success');
    } catch (err) {
      setOutput('error');
    }
  };

  const handleClose = () => {
    setIsSubmitting(false);
    setOutput('loading');
    setSubmitResponse(null);
    onClose();
  };

  const renderCallToActions = () => {
    switch (output) {
      case 'success':
        return (
          <Button
            type="primary"
            className={styles.callToAction}
            onClick={handleClose}
          >
            {t('actions.close')}
          </Button>
        );
      case 'error':
        return (
          <>
            <Button
              type="ghost"
              className={styles.callToAction}
              onClick={handleClose}
            >
              {t('actions.close')}
            </Button>
            <Button
              type="primary"
              className={styles.callToAction}
              onClick={() => setIsSubmitting(false)}
            >
              {t('actions.retry')}
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  const modalContent = useMemo(() => (
    <div className={styles.moduleForm}>
      <ParsedForm
        ref={formRef}
        fields={formFields}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        submitLabel={submitLabel}
      />
    </div>
  ), [formFields, initialValues]);

  const renderProgress = () => (
    <div className={styles.modalResponse}>
      <div className={styles.modalResponseLottie}>
        <LoadingResponse status={output} />
      </div>
      <div className={styles.modalResponseTitle}>
        {t(`actions.${output}`)}
      </div>
      <div>
        {submitResponse}
      </div>
      <div className={styles.modalResponseCallToActions}>
        { renderCallToActions() }
      </div>
    </div>
  );

  const isDrawer = type === 'drawer';

  const renderContents = () => {
    if (isDrawer) {
      return (
        <Drawer
          visible={visible}
          placement="right"
          maskClosable
          closable
          onClose={handleClose}
          destroyOnClose
          footer={null}
          size="large"
          title={t(title, replaceTitleMap)}
          className={cx(
            styles.drawer,
          )}
        >
          {
        isSubmitting
          ? renderProgress()
          : modalContent
      }
        </Drawer>
      );
    }

    return (
      <Modal
        visible={visible}
        centered
        maskClosable
        closable
        onCancel={handleClose}
        destroyOnClose
        footer={null}
        title={t(title, replaceTitleMap)}
        className={cx(
          styles.modal,
          styles[type],
        )}
      >
        {
        isSubmitting
          ? renderProgress()
          : modalContent
      }
      </Modal>
    );
  };

  return renderContents();
};

const ModuleModal = React.memo(ModuleModalComponent);

export default ModuleModal;
