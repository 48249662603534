import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker, Select } from 'antd';
import cx from 'classnames';
import {
  isEmpty, isEqual, map,
} from 'lodash';
import moment from 'moment';

import { FB_MAXIMUM_FETCH } from '../../../common/vars';
import { useReport } from '../../../providers/Platform/Reports/Report';
import styles from './styles.module.scss';

const { Option } = Select;

const { useEffect, useState } = React;

const TopbarComponent: React.FC = () => {
  const {
    isPublic,
    onClientSelect,
    clients,
    clientId,
    setDateRange,
    dateRange,
    adAccounts: stateAdAccounts,
    setSelectedAdAccountIds,
    selectedAdAccountIds,
    report = {},
  } = useReport();

  const { t } = useTranslation();
  const [selectedRange, setSelectedRange] = useState(dateRange);
  const [isPickerFocused, setIsPickerFocused] = useState(false);

  useEffect(() => {
    if (!dateRange || isEmpty(dateRange)) {
      setSelectedRange([
        moment().subtract(60, 'days'),
        moment(),
      ]);
    }
  }, [dateRange]);

  useEffect(() => {
    if (isPickerFocused) {
      return;
    }

    if (!isEqual(dateRange, selectedRange)) {
      setDateRange(selectedRange as [moment.Moment, moment.Moment]);
    }
  }, [dateRange, selectedRange, isPickerFocused]);

  const adAccounts = stateAdAccounts || report.adAccounts || [];

  const renderClientPicker = () => (
    <div className={styles.picker}>
      <div className={styles.pickerLabel}>
        {`${t('report.client')}:`}
      </div>
      <Select
        className={styles.pickerSelect}
        value={clientId}
        bordered={false}
        onSelect={(value, options) => onClientSelect(value, options.client)}
      >
        {
            map(clients, (client) => (
              <Option
                key={client.id}
                value={client.id}
                client={client}
              >
                {client.name}
              </Option>
            ))
          }
      </Select>

    </div>
  );

  const renderAdAccountsPicker = () => (
    <div className={cx(styles.picker, styles.pickerMulti)}>
      <div className={styles.pickerLabel}>
        {`${t('report.adAccounts')}:`}
      </div>
      <Select
        mode="multiple"
        className={styles.pickerSelect}
        value={selectedAdAccountIds}
        bordered={false}
        maxTagCount={2}
        maxTagTextLength={35}
        onChange={setSelectedAdAccountIds}
      >
        {
            map(adAccounts, (adAccount) => (
              <Option key={adAccount.accountId} value={adAccount.accountId}>
                { adAccount.name }
              </Option>
            ))
          }
      </Select>

    </div>
  );

  return (
    <div className={styles.topbar} data-dark={1}>
      {
        !isPublic && renderClientPicker()
      }
      { renderAdAccountsPicker() }
      <div className={styles.rangeContainer}>
        <DatePicker.RangePicker
          onOpenChange={(isOpen) => setIsPickerFocused(isOpen)}
          className={styles.range}
          disabledDate={(current: moment.Moment) => (
            current > moment() || current < moment().subtract(FB_MAXIMUM_FETCH, 'months')
          )}
          ranges={{
            [t('date.today')]: [moment(), moment()],
            [t('date.thisMonth')]: [moment().startOf('month'), moment().endOf('month')],
            '30D': [moment().subtract(30, 'days'), moment()],
            '60D': [moment().subtract(60, 'days'), moment()],
            '90D': [moment().subtract(90, 'days'), moment()],
            MAX: [moment().subtract(FB_MAXIMUM_FETCH, 'months'), moment()],
          }}
          onChange={(dates) => setSelectedRange(dates as [moment.Moment, moment.Moment])}
          value={selectedRange as [moment.Moment, moment.Moment]}
        />
      </div>
    </div>
  );
};

const Topbar = TopbarComponent;

export default Topbar;
