import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { message, notification } from 'antd';
import {
  each,
  find,
  first,
  isEmpty,
  last,
  map,
  uniq,
} from 'lodash';
import moment from 'moment';

import useSearchQuery from '../../../common/hooks/utils/useSearchQuery';
import { authorizedHttp, publicHttp } from '../../../common/http';
import logger from '../../../common/logger';
import { CAMPAIGNS_DEFAULT_LOOKBACK_TIME } from '../../../common/vars';
import { IXLSXReport } from '../../../common/xlsxReport';
import { useSocket } from '../Socket';

const {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} = React;

interface IReportProvider {
  children: React.ReactNode;
}

export type TDateRange = [moment.Moment, moment.Moment] | [] | undefined;

interface IReportContext {
  adAccounts: any[] | undefined,
  clientId: string | undefined,
  clients: any[],
  dateRange: TDateRange,
  isLoading: boolean,
  isLoadingClient: boolean,
  isLoadingReport: boolean,
  isPublic: boolean,
  lastUpdatedAt: number | undefined,
  onClientSelect: (clientId: string, client: any) => void,
  report: any,
  level: string,
  content: any,
  selectedAdAccountIds: string[],
  setClientId: (clientId: string) => void,
  setDateRange: (range: [moment.Moment, moment.Moment]) => void,
  setSelectedAdAccountIds: (ids: string[]) => void,
  refetchReport: () => void,
  handleExpand: (recordId: any) => void,
  getLevelLabel: (intLevel: number) => string,
  setLevel: (newLevel: number) => void,
  getReportArgs: () => IXLSXReport,
  displayBreakdowns: (campaignId: string) => void;
  displayNodeBreakdowns: (nodeId: string, campaignId: string) => void;
  hideBreakdowns: () => void;
  selectedCampaignId: string | undefined;
  selectedNodeId: string | undefined;
  creativesURLsRelationship: Record<string, string>,
  campaignId: string;
}

const ReportContext = createContext<IReportContext>({} as IReportContext);

const ReportProvider: React.FC<IReportProvider> = (props) => {
  const {
    children,
  } = props;

  const abortController = useMemo(() => {
    const controller = new AbortController();
    return controller;
  }, []);

  const { signal } = abortController;

  const {
    subscribeToClientStream,
    unsubscribeFromClientStream,
  } = useSocket();

  const [, setDataHistory] = useState([]);
  const [isLoadingClient, setIsLoadingClient] = useState(false);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [clients, setClients] = useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState<number>();
  const [report, setReport] = useState<any>();
  const [adAccounts, setAdAccounts] = useState<any[]>();
  const [content, setContent] = useState<any>();
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | undefined>();
  const [selectedNodeId, setSelectedNodeId] = useState<string | undefined>();
  const [isFetchingCreatives, setIsFetchingCreatives] = useState(false);
  // eslint-disable-next-line max-len
  const [creativesURLsRelationship, setCreativesURLsRelationship] = useState<Record<string, string>>({} as Record<string, string>);

  const { t } = useTranslation();

  const {
    current: currentQueryValues,
    setValues: setQueryValues,
    setValue: setQueryValue,
  } = useSearchQuery();

  const level = currentQueryValues.level as string;

  const params: any = useParams();
  const paramClientId = params?.clientId;

  const isPublic = !!paramClientId;

  const dateRange = useMemo((): [moment.Moment, moment.Moment] | [] => {
    if (currentQueryValues.from && currentQueryValues.to) {
      return [
        moment(currentQueryValues?.from),
        moment(currentQueryValues?.to),
      ];
    }

    return [];
  }, [
    currentQueryValues?.from,
    currentQueryValues?.to,
  ]);

  const setDateRange = (range: [moment.Moment, moment.Moment]) => {
    if (
      isEmpty(range)
        || !first(range)
        || !last(range)
    ) {
      return;
    }

    const newQs = {
      from: first(range)?.format('YYYY-MM-DD'),
      to: last(range)?.format('YYYY-MM-DD'),
    };

    setQueryValues(newQs);
  };

  const getInitialDateRange = () => {
    if (isEmpty(dateRange)) {
      const defaultStart = moment().subtract(CAMPAIGNS_DEFAULT_LOOKBACK_TIME, 'months');
      const defaultEnd = moment();

      return [defaultStart, defaultEnd];
    }

    return dateRange;
  };

  const clientId = currentQueryValues.clientId as string;

  const setClientId = (clientIdArg: string, initialAdAccountsIds?: number[]) => {
    const initialDateRange = getInitialDateRange();

    const qsVals = {
      clientId: clientIdArg,
      adAccountIds: initialAdAccountsIds,
      from: initialDateRange[0].format('YYYY-MM-DD'),
      to: initialDateRange[1].format('YYYY-MM-DD'),
      level: 0,
    };

    setQueryValues(qsVals, true);
  };

  const selectedAdAccountIds = useMemo(() => (
    currentQueryValues.adAccountIds as string[]
  ), [currentQueryValues.adAccountIds]);

  const setSelectedAdAccountIds = (selectedIdsArg: string[]) => {
    setQueryValue('adAccountIds', selectedIdsArg);
  };

  const setLevel = (newLevel: number) => {
    setQueryValue('level', `${newLevel}`);
  };

  const getNextLevelKey = () => {
    switch (level) {
      case '0':
        return 'campaignId';
      case '1':
        return 'adsetId';
      default:
        return 'campaigns';
    }
  };

  const handleExpand = (recordId: any) => {
    const newLevelKeys = {
      [getNextLevelKey()]: recordId,
      level: (+level) + 1,
    };

    setQueryValues(newLevelKeys);
    setSelectedNodeId(undefined);
    setSelectedCampaignId(undefined);
  };

  const defineContent = () => {
    const sourceData = report.campaigns;
    let newContent = sourceData;

    const qCampaignId = currentQueryValues?.campaignId || '';
    const qAdsetId = currentQueryValues?.adsetId || '';
    const campaign = find(sourceData, ['id', qCampaignId]) || {};
    const adset = find(campaign?.adsets, ['id', qAdsetId]) || {};

    if (qCampaignId && level === '1') {
      const { adsets } = campaign;
      newContent = adsets || [];
    }

    if (qAdsetId && level === '2') {
      const { ads } = adset;
      newContent = ads || [];
    }

    setContent(newContent);
  };

  const getLevelLabel = (intLevel: number) => {
    const sourceData = report.campaigns;

    const qCampaignId = currentQueryValues?.campaignId || '';
    const qAdsetId = currentQueryValues?.adsetId || '';
    const campaign = find(sourceData, ['id', qCampaignId]) || {};
    const adset = find(campaign?.adsets, ['id', qAdsetId]) || {};

    if (intLevel === 1) {
      return campaign?.name || t('report.table.campaign');
    } if (intLevel === 2) {
      return adset?.name || t('report.table.adset');
    }
    return t('report.table.campaigns');
  };

  useEffect(() => {
    if (!report || isEmpty(report) || !report.campaigns) {
      return;
    }

    defineContent();
  }, [
    level,
    report,
    currentQueryValues?.campaignId,
    currentQueryValues?.campaignId,
  ]);

  const fetchSetReport = async (forceCache?: boolean) => {
    setIsLoadingReport(true);

    try {
      const from = dateRange?.[0]?.format('YYYY-MM-DD');
      const to = dateRange?.[1]?.format('YYYY-MM-DD');

      const { adAccountIds } = currentQueryValues;

      const requestParams: Record<string, unknown> = {
        dateRange: {
          since: from,
          until: to,
        },
      };

      if (forceCache) {
        requestParams.t = +Date.now();
      }

      if (adAccountIds?.length) {
        requestParams.adAccounts = typeof adAccountIds === 'string' ? [adAccountIds] : adAccountIds;
      }

      const clientsResponse = await publicHttp.get(`/reports/client/${clientId}`, {
        params: requestParams,
        signal,
      });

      const resData = clientsResponse.data;
      const reportData = resData.payload;

      setLastUpdatedAt(resData.timestamp);
      setContent(null);
      setReport(reportData);
    } catch (err) {
      notification.error({
        message: t('feedback.reportError'),
      });
    } finally {
      setIsLoadingReport(false);
    }
  };

  const fetchSetClients = async () => {
    setIsLoadingClient(true);

    try {
      const clientsResponse = await authorizedHttp.get('/clients', {
        params: {
          t: +Date.now(),
        },
        signal,
      });

      const clientsData = clientsResponse.data.payload.clients;
      setClients(clientsData);
    } catch (err) {
      message.error(t('feedback.clientsError'));
    } finally {
      setIsLoadingClient(false);
    }
  };

  const onClientSelect = (clientIdArg: string, clientArg: any) => {
    setContent(null);
    setTimeout(() => {
      const mergedConnections = [
        ...clientArg.connectionFB,
        ...clientArg.connectionGoogle,
      ];

      setAdAccounts(mergedConnections);
      const accountIds = map(mergedConnections, 'accountId');
      setClientId(clientIdArg, accountIds);
    }, 100);
  };

  const fetchSetClient = async (fetchClientId: string) => {
    setIsLoadingClient(true);

    try {
      const clientsResponse = await publicHttp.get(`/public/client/${fetchClientId}`, {
        params: {
          t: +Date.now(),
        },
        signal,
      });

      setContent(null);
      const clientData = clientsResponse.data.payload.client;
      onClientSelect(fetchClientId, clientData);
    } catch (err) {
      message.error(t('feedback.clientsError'));
    } finally {
      setIsLoadingClient(false);
    }
  };

  /** Single Effect for Broadcast */
  useEffect(() => {
    if (clientId) {
      subscribeToClientStream(clientId);
      return () => unsubscribeFromClientStream(clientId);
    }
    return () => {};
  }, [clientId]);

  useEffect(() => {
    if (params && !paramClientId) {
      fetchSetClients();
    } else if (paramClientId) {
      fetchSetClient(paramClientId);
    }
  }, [paramClientId]);

  /** Get ads creatives */
  const fetchAdsCreatives = async () => {
    if (isFetchingCreatives) {
      return;
    }

    setIsFetchingCreatives(true);

    const target = report.campaigns;
    const creativeIds: any[] = [];

    each(target, (campaign) => {
      const adsets = campaign?.adsets || [];

      each(adsets, (adset) => {
        const ads = adset?.ads || [];

        each(ads, (ad) => {
          const id = ad?.creative?.id || ad.id;
          if (id) {
            creativeIds.push({
              id,
              source: campaign.source,
            });
          }
        });
      });
    });

    const uniqCreativeIds = uniq(creativeIds);
    const accessToken = report?.adAccounts?.[0]?.accessToken;

    try {
      const response = await publicHttp.post(`reports/client/${clientId}/creatives`, {
        creativeIds: uniqCreativeIds,
      }, {
        params: {
          accessToken,
        },
      });

      const { data } = response;

      const creatives = data?.payload?.creatives || [];

      const parsedObject: Record<string, string> = {};

      each(creatives, (creative: any) => {
        parsedObject[creative.creativeId.id] = creative.url;
      });

      setCreativesURLsRelationship(parsedObject);
    } catch (err: any) {
      logger.error(`Error while fetching creatives: ${err.message}`);
      setCreativesURLsRelationship({});
    } finally {
      setIsFetchingCreatives(false);
    }
  };

  useEffect(() => {
    if (+level === 2 && report?.campaigns?.[0].adsets?.[0].ads) {
      fetchAdsCreatives();
    }
  }, [level, report]);

  /** Destroy on unmount */
  useEffect(() => () => {
    setDataHistory([]);
    setClients([]);
  }, []);

  const queryFrom = useMemo(() => currentQueryValues?.from, [currentQueryValues?.from]);
  const queryTo = useMemo(() => currentQueryValues?.to, [currentQueryValues?.to]);
  const queryAdAccountIds = useMemo(() => (
    currentQueryValues?.adAccountIds
  ), [currentQueryValues?.adAccountIds]);

  useEffect(() => {
    const getReportAndSet = async () => {
      if (
        clientId
          && queryAdAccountIds
          && queryFrom
          && queryTo
          && !isLoadingReport
      ) {
        await fetchSetReport();
      }
    };

    getReportAndSet();
  }, [
    clientId,
    queryAdAccountIds,
    queryFrom,
    queryTo,
  ]);

  const getReportArgs = (): IXLSXReport => ({
    from: dateRange[0]?.toDate() || new Date(),
    to: dateRange[1]?.toDate() || new Date(),
    rawReport: report,
  });

  const displayBreakdowns = (campaignId: string) => {
    setSelectedCampaignId(campaignId);
  };

  const displayNodeBreakdowns = (nodeId: string, campaignId: string) => {
    setSelectedCampaignId(campaignId);
    setSelectedNodeId(nodeId);
  };

  const hideBreakdowns = () => {
    setSelectedCampaignId(undefined);
    setSelectedNodeId(undefined);
  };

  const isLoading = isLoadingClient;

  useEffect(() => {
    hideBreakdowns();
  }, [level]);

  return (
    <ReportContext.Provider
      value={{
        adAccounts,
        clientId,
        clients,
        dateRange,
        isLoading,
        isLoadingClient,
        isLoadingReport,
        isPublic,
        lastUpdatedAt,
        onClientSelect,
        report,
        content,
        selectedAdAccountIds,
        setClientId,
        setDateRange,
        setSelectedAdAccountIds,
        refetchReport: () => fetchSetReport(true),
        handleExpand,
        level,
        getLevelLabel,
        setLevel,
        getReportArgs,
        selectedCampaignId,
        displayBreakdowns,
        hideBreakdowns,
        creativesURLsRelationship,
        displayNodeBreakdowns,
        selectedNodeId,
        campaignId: currentQueryValues?.campaignId as string || '',
      }}
    >
      { children }
    </ReportContext.Provider>
  );
};

export const useReport = () => useContext(ReportContext);

export default ReportProvider;
