import * as React from 'react';

interface IPublicWrapper {
  children: React.ReactNode;
}

const PublicWrapper: React.FC<IPublicWrapper> = ({ children }) => (
  <div>
    { children }
  </div>
);

export default PublicWrapper;
