/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from 'antd';

import { UsergroupAddOutlined } from '@ant-design/icons';

import { IField } from '../../common/fields';
import ModuleModal from '../ModuleModal';
import styles from './styles.module.scss';

const { useMemo, useState } = React;

interface IAddEntiityUserButton {
  buttonProps?: ButtonProps;
  onSubmit: (users?: string[]) => void;
  excludeIds: string[];
}

const AddEntityUserButtonComponent: React.FC<IAddEntiityUserButton> = (props) => {
  const {
    buttonProps,
    onSubmit,
    excludeIds,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();

  const formContents = useMemo((): IField[] => [
    {
      type: 'user-picker',
      name: 'users',
      multiple: true,
      hideSelfAssign: true,
      excludeIds,
      required: true,
      label: t('fields.pickUsers.placeholder'),
    },
  ], [excludeIds]);

  const handleSubmit = async (values: Record<string, unknown>) => (
    onSubmit((values.users) as string[])
  );

  return (
    <>
      <Button
        icon={<UsergroupAddOutlined />}
        className={styles.addUserBtn}
        type="primary"
        onClick={() => setIsModalOpen(true)}
        {...buttonProps}
      >
        {t('actions.assignUsers.label')}
      </Button>
      <ModuleModal
        title="actions.assignUsers.label"
        formFields={formContents}
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleSubmit}
        submitLabel="actions.assignUsers.label"
      />
    </>
  );
};

const AddEntityUserButton = React.memo(AddEntityUserButtonComponent);

export default AddEntityUserButton;
